//bar

//chart
import chartWithAxis from "assets/images/chart-images/bubble-table.png";
import table from "assets/images/chart-images/table.png";
const timeToInitialDecisionFrEdAss = [
        // performance - none
        { id: "%RETDIAE-BB01s", name: "bubble", chartImage: chartWithAxis, disabled: false },
        { id: "%RETDIAE-BB02s", name: "bubble", chartImage: chartWithAxis, disabled: false },
        { id: "%RETDIAE-BB01e", name: "bubble", chartImage: chartWithAxis, disabled: false, description: "AVERAGE" },
        { id: "%RETDIAE-BB02e", name: "bubble", chartImage: chartWithAxis, disabled: false, description: "AVERAGE" },
        { id: "%RETDIAE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "%RETDIAE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "%RETDIAE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "%RETDIAE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timeToInitialDecisionFrEdAss;
