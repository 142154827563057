//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change-editor.png";
import table from "assets/images/chart-images/table.png";

const editors = [
        { id: "TFRE-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TFRE-BV02e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TFRE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TFRE-BV02s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TFRE-BV03s", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "TFRE-BV04s", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "TFRE-BV03e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TFRE-BV04e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TFRE-T01e", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TFRE-T03e", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TFRE-T01s", name: "simple-bar", chartImage: table, v2: "Column", disabled: false },
        { id: "TFRE-T02e", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TFRE-T03e", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TFRE-T02s", name: "simple-bar", chartImage: table, v2: "Row", disabled: false },
];

export default editors;
