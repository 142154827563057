//bar
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";

//map
import mapUsRegions from "assets/images/chart-images/map/map_us_regions.png";
import mapUsStates from "assets/images/chart-images/map/map_us_states.png";
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";

//chart
import chart4Items from "assets/images/chart-images/donut.png";
import chartChange from "assets/images/chart-images/bubble.png";
import table from "assets/images/chart-images/table.png";

const countriesList = [
        // Countries - None
        {id: "%RIC-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RIC-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RIC-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: true},
        {id: "%RIC-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: true},
        {id: "%RIC-M01s", name: "map", chartImage: mapWorldMap, disabled: false},
        {id: "%RIC-M02s", name: "map", chartImage: mapWorldMap, disabled: false},
        {id: "%RIC-M01e", name: "map", chartImage: mapWorldMap, disabled: false},
        {id: "%RIC-M02e", name: "map", chartImage: mapWorldMap, disabled: false},
        {id: "%RIC-D01s", name: "donut", chartImage: chart4Items, disabled: true},
        {id: "%RIC-D01e", name: "donut", chartImage: chart4Items, disabled: true},
        {id: "%RIC-BB01s", name: "bubble", chartImage: chartChange, disabled: false},
        {id: "%RIC-BB01e", name: "bubble", chartImage: chartChange, disabled: false},
        {id: "%RIC-BB02s", name: "bubble", chartImage: chartChange, disabled: true},
        {id: "%RIC-BB02e", name: "bubble", chartImage: chartChange, disabled: true},
        {id: "%RIC-M02s", name: "map", chartImage: mapUsStates, disabled: true},
        {id: "%RIC-M02e", name: "map", chartImage: mapUsStates, disabled: true},
        {id: "%RIC-M03s", name: "map", chartImage: mapUsRegions, disabled: true},
        {id: "%RIC-M03e", name: "map", chartImage: mapUsRegions, disabled: true},
        {id: "%RIC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RIC-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "%RIC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RIC-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default countriesList;
