import React from 'react';
import './index.css';
import Button from "components/elements/button";

function ErrorPage(props) {
    return (
        <div style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex"
        }} className='errorPage'>
            <h1>
                Something  went wrong. Please refresh the page.
            </h1>
            <Button
                type={3}
                name={"Go To Home Page"}
                icon="arrow-back"
                iconColor="#74BA23"
                style={{
                    border: "2px solid #74BA2355",
                    color: "#74BA23",
                    width: "200px",
                    height: "36px",
                    backgroundColor: "#ffffff",
                    padding: "4px",
                    marginRight: 16,
                    marginTop: "20px"
                }}
                handleButtonClick={() => window.location.pathname = "/"}
            />
        </div>
    )

}

export default ErrorPage;