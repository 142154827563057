import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const associateEditors = [
    { id: "#CESNAE-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
    { id: "#CESNAE-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, },
    { id: "#CESNAE-T01r", name: "table", v2: "Column", chartImage: table, disabled: false, },
    { id: "#CESNAE-T02r", name: "table", v2: "Row", chartImage: table, disabled: false, },
    { id: "#CESNAE-T71r", name: "table", v2: "Column", chartImage: table, disabled: false, },
    { id: "#CESNAE-T20r", name: "table", chartImage: table, disabled: false, },
];

export default associateEditors;