import * as actions from "store/actionTypes";
import {
  variablesList,
  chartTypeList,

} from "store/reducers/charts/chart-data";
import { toast } from "react-toastify";
let notifyCompleteCount = 0

const notify = (text, color) => {
  if (text === 'Upload complete') {
    notifyCompleteCount += 1
    setTimeout(() => { notifyCompleteCount -= 1 }, 1000);
    notifyCompleteCount < 2 && toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      style: {
        backgroundColor: color
      }}
    );
  }
};

const {
  submissions: {
    variablesList1: defaultVariablesList1,
    variablesList2: defaultVariablesList2,
    variablesList1ejp: defaultVariablesList1ejp,
    variablesList2ejp: defaultVariablesList2ejp,
  },
} = variablesList;

// variablesList
const defaultState = {
  generateChartSteps: {
    manuscriptChecked: -1,
    reportType: { key: "Select data type" },
    fileName: "",
    variable1: defaultVariablesList1[0],
    variable2: defaultVariablesList2[0],
    xAxisLimit: null,
    legendLimit: null,
    doneList: [],
    variablesList1: [],
    variablesList2: [],
    singleCountry: false,
    quickChart: {},
    fileNamePrefix: '',
  },
  chartsList: [],
  twoVariablesChartsList: [],
  calculationResult: {},
  calculationResultForMapBarChart: {},
  otherData: {
    changed_colors: [],
    activeContent: 0,
    reviewersGivenAverageDays: '',
    daysBeforeDueDate: [],
    daysAfterInvitationSent: [],
    daysAfterDueDate: []
  },
  statsData: {},
  chartType: "",
  csvUploadPercentage: 0,
  wrongScvMessage: "",
  pageLoading: false,
  showSelectOtherChartModal: false,
  missingColumns: [],
};

function handleDoneList(action, state) {
  const { n, name, item: { name: variableName } = {name:'none'} } = action;
  const {
    generateChartSteps: { doneList, manuscriptChecked },
  } = state;

  if (name === "variable1" && variableName === "none") {
    return doneList;
  }
  const numberList = [1, 2, 3, 4, 5];
  let newDoneList = [];
  let count = numberList.filter((item) => item < n);
// if(manuscriptChecked === 3) {
//   return [1,2 ,3,4 ]
// }
  if (
    !window.isEmpty(doneList) &&
    count.every((item) => doneList.includes(item)) &&
    !doneList.includes(n)
  ) {
    if (n === 1) {
      newDoneList = [...doneList, 1, 2];
    } else {
      newDoneList = [...doneList, n];
    }

    return newDoneList;
  } else if (window.isEmpty(doneList) && n === 1) {
    return [...doneList, 1];
  }
  return doneList;
}

function handleVariableList(variable1, reportType, manuscriptType) {
  const wantedList2 = (+manuscriptType === 2 && reportType.value === 1 && reportType.name === "submissions") ? "variablesList2ejp" : "variablesList2"
  return variable1.value
    ? variablesList[reportType.name][wantedList2]
      .filter((item) => item.name !== variable1.name)
      .filter((_item, index) => {
        if (chartTypeList[reportType.name][variable1.name]) {
          return Object.values(chartTypeList[reportType.name][variable1.name])[index] && Object.values(chartTypeList[reportType.name][variable1.name])[index][manuscriptType]?.length;
        }
      })
    : [];
}


export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.GENERATE_FROM_FAVORITE:
      return {
        ...action.payload.item.charts,
        name: action.payload.item.name,
        generateChartSteps: {
          ...action.payload.item.charts.generateChartSteps,
          manuscriptChecked: state.generateChartSteps.manuscriptChecked,
          fileName: state.generateChartSteps.fileName,
        },
      };
    case actions.UPLOAD_CSV:
      const doneList = !action.wrongCsv
        ? handleDoneList({ n: 3 }, state)
        : handleDoneList({ n: 2 }, state);
      const { journalName, fileName, errorMessage, errorType: { missingColumns = [] } = {}, errorType, fileNamePrefix } = action.payload;
      // console.log('🚀 ~ file: index.js ~ line 113 ~ errorType', errorType);
      // const statsData = getStatsData(action.payload, state);

      return {
        ...state,
        generateChartSteps: {
          ...state.generateChartSteps,
          fileName: fileName,
          doneList,
          fileNamePrefix
        },
        // statsData,
        missingColumns,
        errorMessage,
        errorType,
        journalName: journalName,

      };
    case actions.UPLOAD_CSV_ERROR:
      return {
        ...state,
        generateChartSteps: {
          ...state.generateChartSteps,
          // fileName: "",
          doneList: [1, 2],
        },
        statsData: {},
        csvUploadPercentage: 0,
        csvUploadStatus: "canceled",
      };
    case actions.SET_UPLOAD_PERCENTAGE:
      return {
        ...state,
        csvUploadPercentage: action.percent,
      };
    case actions.SET_UPLOAD_STATUS:
      if (action.reading === true) {
    
        if (action.status === 'reading') {
          notify('Upload started', '#74BA23');
        } else if (action.status === 'canceled') {
          notify('Upload cancelled', '#F21414');
        } else if (action.status === 'completed') {
          notify('Upload complete', '#74BA23');
        }
      }
      return {
        ...state,
        csvUploadStatus: action.status,
        wrongScvMessage: action.errorMessage,
      };
    case actions.WRONG_CSV:
      return {
        ...state,
        wrongScvMessage: action.errorMessage,
      };
    case actions.CHOOSE_CHART_SYSTEM:
      if (action.name === "resetChartSteps") {
        return {
          ...state,
          generateChartSteps: {
            ...state.generateChartSteps,
            fileName: '',
            doneList: [1, 2]
          },
          wrongScvMessage: ''
        };
      }
      let doneList2 = handleDoneList(action, state);

      let chartsList = [],
        twoVariablesChartsList = [],
        reportType = { ...state.generateChartSteps.reportType },
        variable1 = { ...state.generateChartSteps.variable1 },
        variable2 = { ...state.generateChartSteps.variable2 },
        variablesList1 = state.generateChartSteps.variablesList1 ? [...state.generateChartSteps.variablesList1] : [],
        variablesList2 = [...state.generateChartSteps.variablesList2],
        quickChart = { ...state.generateChartSteps.quickChart },
        fileName2 = state.generateChartSteps.fileName,
        statsData2 = { ...state.statsData };
      let repo = {};
      if (action.n === 1 && reportType.key !== "Select data type") {
        doneList2 = doneList2.slice(0, 2);
        // fileName2 = "";
        statsData2 = {};
        if (!action.fromFavorite) {
          // repo = {
          //     reportType: reportTypesList[action.item][0]
          // }
        }
      }

      if (typeof action.item === 'object' && action.item !== null && action.n === 2) {
        
        const wantedList1  = (+localStorage.getItem("manuscriptChecked") === 2 && action.item.value === 1 && action.item.name === "submissions" ) ? "variablesList1ejp" : "variablesList1"
        variablesList1 = variablesList[action.item.name]?.[wantedList1];
        variablesList2 = [];
        variable1 = defaultVariablesList1[0];
        variable2 = defaultVariablesList2[0];
        quickChart = {};
        doneList2 = doneList2.slice(0, 3);
      }

      if (action.n === 4) {
        quickChart = {};
        let value1 = "none";
        let value2 = "none";

        const {
          item: { name },
        } = action;
        const {
          generateChartSteps: { manuscriptChecked },
        } = state;

        if (action.name === "variable1") {
          value1 = name;
          variablesList2 = handleVariableList(
            action.item,
            reportType,
            manuscriptChecked
          );
          if (
            (variable2.name === value1 ||
              !variablesList2.some((el) => el.name === variable2.name)) &&
            !window.isEmpty(variablesList2)
          ) {
            variable2 = variablesList2[0];
          } else {
            variable2 = defaultVariablesList2[0];
          }
          value2 = variable2.name;
        } else if (action.name === "variable2") {
          value2 = name;
          value1 = state.generateChartSteps.variable1.name;
        }

        if (
          chartTypeList[reportType.name] &&
          chartTypeList[reportType.name][value1]
        ) {
          chartsList =
          chartTypeList[reportType.name][value1][value2] && chartTypeList[reportType.name][value1][value2][manuscriptChecked];
          twoVariablesChartsList =
          chartTypeList[reportType.name][value1]["none"] && chartTypeList[reportType.name][value1]["none"][manuscriptChecked];
        } else {
          chartsList = [];
          twoVariablesChartsList = [];
        }
      }

      return {
        ...state,
        generateChartSteps: {
          ...state.generateChartSteps,
          variable1,
          variable2,
          [action.name]: action.item,
          doneList: doneList2,
          variablesList1,
          variablesList2,
          singleCountry: false,
          quickChart,
          fileName: fileName2,
          ...repo,
        },
        chartsList,
        twoVariablesChartsList,
        statsData: statsData2,
        // wrongScvMessage: ''
      };
    case actions.SELECT_COUNTRY_TYPE:
      const {
        generateChartSteps: { manuscriptChecked },
      } = state;
      let countryReportType = { ...state.generateChartSteps.reportType };
      let countryVariablesList2 = handleVariableList(
        action.value,
        countryReportType,
        manuscriptChecked
      );
      const countryChartsList = !window.isEmpty(countryVariablesList2)
        ? chartTypeList[countryReportType.name]["singleCountry"][
        countryVariablesList2[0].name
        ][manuscriptChecked]
        : [];
      let countryVariable2 = { ...state.generateChartSteps.variable2 };
      if (!window.isEmpty(countryVariablesList2)) {
        countryVariable2 = countryVariablesList2[0];
      }

      return {
        ...state,
        generateChartSteps: {
          ...state.generateChartSteps,
          singleCountry: true,
          variable2: countryVariable2,
          variablesList2: countryVariablesList2,
        },
        chartsList: countryChartsList,
      };
    case actions.GENERATE_CHART: {
      const { calculationResult: result } = action.payload;
      const { chartType } = action;
      const {
        generateChartSteps: { manuscriptChecked: manType },
      } = state;
      localStorage.setItem("chartCode", chartType)
      //Check if it will come from quick charts add variable 1 and variable 2
      let variablesFromQuickChart = {};
      let charts = {};
      let quickChartVariable1 = "";
      let quickChartVariable2 = "";
   
      if (!window.isEmpty(state.generateChartSteps.quickChart)) {
        let { reportType, chartCode } = state.generateChartSteps.quickChart;
        let chartsList2 = [];

        Object.entries(chartTypeList[reportType.name]).find((item) => {
          chartsList2 = Object.entries(item[1]).find((el) => {
            if (el[1][manType]?.includes(chartCode)) {
              quickChartVariable1 = item[0];
              quickChartVariable2 = el[0];
            }
            return el[1][manType]?.includes(chartCode);
          });

          return chartsList2;
        });
        variablesFromQuickChart.variable1 = variablesList[reportType.name][
          "variablesList1"
        ].find((elem) => {
          return elem.name === quickChartVariable1;
        });
        variablesFromQuickChart.variable2 = variablesList[reportType.name][
          "variablesList2"
        ].find((elem) => {
          return elem.name === quickChartVariable2;
        });
        variablesFromQuickChart.variablesList2 = handleVariableList(
          variablesFromQuickChart.variable1,
          reportType,
          manType
        );
      } else {
        const { generateChartSteps: { variable1, reportType }, chartsList, twoVariablesChartsList } = state;
        if (twoVariablesChartsList?.includes(chartType) && !chartsList?.includes(chartType)) {
          variablesFromQuickChart.variable2 = handleVariableList(
            variable1,
            reportType,
            manType
          )[0];
          charts.chartsList = twoVariablesChartsList;
          charts.twoVariablesChartsList = twoVariablesChartsList;
        }
      }
      let barLimit = null;
      let legendLimit = null;
      const finalState = {
        ...state,
        calculationResult: result,
        chartType: action.chartType,
        generateChartSteps: {
          ...state.generateChartSteps,
          xAxisLimit: barLimit,
          legendLimit: legendLimit,
          //add variable 1 and variable 2 and variablesList2
          ...variablesFromQuickChart,
        },
        ...charts,
      }
      const var1 = (finalState.generateChartSteps.variable1?.key || '').trim();
      const var2 = (finalState.generateChartSteps.variable2?.key || '').trim();
      if (var2 === "None (optional)") {
        switch (var1) {
          case "Countries": case "Manuscript types": case "Section/Category": barLimit = 30; break;
          case "Continents": barLimit = 25; break;
          default: barLimit = null;
        }
      } else {
        switch (var1) {
          case "Countries": case "Manuscript types": case "Section/Category": barLimit = 30; break;
          case "Continents": barLimit = 25; break;
          default: barLimit = null;
        }
        if (var2 === 'Time Period') {
          legendLimit = 28
        }
        else { legendLimit = 56 }
      }
      finalState.generateChartSteps.xAxisLimit = barLimit;
      finalState.generateChartSteps.legendLimit = legendLimit;
      return finalState;
    }
    case actions.HANDLE_MAP_BAR_CHART:
      return {
        ...state,
        calculationResultForMapBarChart: action.data,
      };
    case actions.RESET_CHART_OPTIONS:
      return {
        ...state,
        chartType: "",
        generateChartSteps: {
          ...state.generateChartSteps,
          // variable1: defaultVariablesList1[0],
          // variable2: defaultVariablesList2[0],
          // variablesList2: [],
          doneList: [1, 2, 3],
          quickChart: {},
        },
        otherData: {
          changed_colors: [],
          activeContent: 0,
          reviewersGivenAverageDays: '',
          daysBeforeDueDate: [],
          daysAfterInvitationSent: [],
          daysAfterDueDate: []
        },
      };
    case actions.RESET_VARIABLES_LIST:
      if (action.n === 1) {
        return {
          ...state,
          chartType: "",
          generateChartSteps: {
            ...state.generateChartSteps,
            variable1: defaultVariablesList1[0],
            variable2: defaultVariablesList2[0],
            variablesList2: [],
          },
        };
      }
      else if (action.n === 2) {
        return {
          ...state,
          chartType: "",
          generateChartSteps: {
            ...state.generateChartSteps,
            variable2: defaultVariablesList2[0],
          },
        };
      };break;
    case actions.SHOW_SELECT_OTHER_CHART_MODAL:
      return {
        ...state,
        showSelectOtherChartModal: action.value,
      };
    case actions.CHANGE_QUICK_CHART:
      const doneList3 = handleDoneList({ n: 4 }, state);
      const {
        quickChart: { chartCode, reportType: { name: reportTypeName } = {} },
      } = action;
      const {
        generateChartSteps: { manuscriptChecked: manuscriptType },
      } = state;
      let chartsList1 = [];
      Object.values(chartTypeList[reportTypeName]).find((item) => {
        const itemsSet = Object.values(item).find((el) => {
          return (el[manuscriptType] || []).includes(chartCode);
        });
        chartsList1 = itemsSet && itemsSet[manuscriptType];

        return chartsList1;
      });

      return {
        ...state,
        generateChartSteps: {
          ...state.generateChartSteps,
          variable1: defaultVariablesList1[0],
          variable2: defaultVariablesList2[0],
          quickChart: action.quickChart,
          doneList: doneList3,
        },
        chartsList: chartsList1,
      };
    case actions.LOGOUT:
      return {
        ...defaultState,
      };
    case actions.SELECT_CHART:
      return {
        ...state,
        pageLoading: true,
        pageLoaderParentName: "body",
      };
    case actions.HIDE_LOADER:
      return {
        ...state,
        pageLoading: false,
      };
    case actions.SHOW_LOADER:
    case actions.SIDE_BAR_DATE_CHANGE_DROPDOWN:
    case actions.SIDE_BAR_INCLUDE_CHANGE:
    case actions.SIDE_BAR_EXCLUDE_CHANGE:
    case actions.HANDLE_SORT_CHANGE:
    case actions.HANDLE_SEPERATE_SORT_CHANGE:
    case actions.CHANGE_PALETTE_COLORS:
    case actions.SIDE_BAR_DATE_CHANGE:
      return {
        ...state,
        pageLoading: true,
        pageLoaderParentName: ".chart-box",
      };
    case actions.TIME_FOR_REVIEWERS_TO_SUBMIT_RECOMMENDATIONS_MARKERS:
      return {
        ...state,
        otherData: {
          ...state.otherData,
          ...action.payload,
        }
      }

    default:
      return state;
  }
};
