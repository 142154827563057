//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
const time = [
        // performance - none
        {id: "#ETP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "#ETP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "#ETP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#ETP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#ETP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "#ETP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default time;
