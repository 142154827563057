import * as actions from "../actionTypes";

const defaultState = {
    isAuth: null,
    authUser: {},
    userData: {},
    userErrorData: {},
    setPasswordError: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuth: action.payload.isAuth,
                authUser: action.payload.user
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                userErrorData: {}
            };
        case actions.LOGIN_ERROR:
            return {
                ...state,
                userData: {},
                userErrorData: action.payload
            };
        case actions.ME:
            return {
                ...state,
                userData: action.payload
            };
        case actions.LOGOUT:
            localStorage.removeItem('user_token')
            sessionStorage.removeItem("user_token")
            return {
                ...state,
                userData: {}
            };
        case actions.LOGIN_AS_THIS_USER:
            return {
                ...state,
                userData: action.payload,
                userErrorData: {}
            };
        case actions.CONFIRM_NEW_EMAIL:
            return {
                ...state,
                userData: action.payload,
                userErrorData: {}
            };
        case actions.SET_PASSWORD_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                userErrorData: {}
            };
        case actions.SET_PASSWORD_ERROR:
            return {
                ...state,
                userData: {},
                setPasswordError: action.payload
            };
        case actions.UPLOAD_USER_AVATAR_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    user: {
                        ...state.userData.user,
                        avatar: action.payload.avatar_full_path
                    }
                },
            };
        case actions.UPDATE_USER_CHARTS_STATS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    user: {
                        ...state.userData.user,
                        chartsStats: action.payload
                    }
                },
            };
        default:
            return state;
    }
};
