//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";

const initialDecisionsNone = [
        // performance - none
        { id: "TDIADI-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TDIADI-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDIADI-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDIADI-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDIADI-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "TDIADI-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
];

export default initialDecisionsNone;
