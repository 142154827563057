//bar
import boxChartImage from "assets/images/chart-images/box-whisker.png";

//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change-editor.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const reviewerTypeList = [
    { id: "TCRRT-BV01r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE"  },
    { id: "TCRRT-BV04r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE"  },
    { id: "TCRRT-L01r", name: "bar", chartImage: lineLineChart, disabled: false, description: "AVERAGE"  },
    { id: "TCRRT-T03r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE * STD DEV"  },
    { id: "TCRRT-T04r", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE * STD DEV"  },
    { id: "TCRRT-BV05r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-BV08r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-L05r", name: "bar", chartImage: lineLineChart, disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-BXV05r", name: "bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-T07r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-T08r", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN"  },
    { id: "TCRRT-T20r", name: "table", chartImage: table, disabled: false, description: ""  },
];

export default reviewerTypeList;
