import lineSimpleBarChartEditor from "assets/images/chart-images/simple-bar-change-editor.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const timePeriodList = [
        // Time period - None
        { id: "TPRTP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-BV02s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-BV03s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-BV04s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-L01s", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TPRTP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TPRTP-T03s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TPRTP-T04s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV" },
        
        { id: "TPRTP-BV05s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BV06s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BV07s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BV08s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-L05s", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BXV05s", name: "box", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-T05s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
        { id: "TPRTP-T06s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
        { id: "TPRTP-T07s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • STD DEV" },
        { id: "TPRTP-T08s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • STD DEV" },

        { id: "TPRTP-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        
        { id: "TPRTP-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-L01r", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TPRTP-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE * STD DEV" },
        { id: "TPRTP-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE * STD DEV" },
        { id: "TPRTP-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-L05r", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN" },
        { id: "TPRTP-BXV05r", name: "box", chartImage: boxChartImage, disabled: false, description: "MEDIAN * IQR" },
        { id: "TPRTP-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN * IQR" },
        { id: "TPRTP-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN * IQR" },
        { id: "TPRTP-T20r", name: "table", chartImage: table, disabled: false, description: "" },
];

export default timePeriodList;
