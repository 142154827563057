import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';

const editorNoneList = [
        // Time period - None
        { id: "#IE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "#IE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "#IE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "#IE-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "#IE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "#IE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "#IE-BV05e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "#IE-BXV05e", name: "simple-bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "#IE-T05e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
        { id: "#IE-T06e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
        { id: "#IE-T07e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "#IE-T08e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR"},
];

export default editorNoneList;