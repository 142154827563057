import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barHorizontalStackedBarChart from "assets/images/chart-images/horizontal-stacked-bar.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
import lineMultiLineChart from "assets/images/chart-images/multi-line.png";
import stackedBar from "assets/images/chart-images/stacked-bar.png";

const timePeriodList = [
        { id: "TDIdTP-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-BV02p", name: "simple-bar", chartImage: barSimpleBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-BV03p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-BV04p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-L01p", name: "line", chartImage: lineLineChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTP-T03p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Column", disabled: false },
        { id: "TDIdTP-T04p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Row", disabled: false },
        { id: "TDIdTP-BV05p", name: "simple-bar", chartImage: barSimpleBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-BV06p", name: "simple-bar", chartImage: barSimpleBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-BV07p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-BV08p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-BH05p", name: "horizontal-bar", chartImage: barHorizontalBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-L05p", name: "line", chartImage: lineLineChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTP-BXV05p", name: "box", chartImage: boxChartImage, description: "MEDIAN * IQR", disabled: false },
        { id: "TDIdTP-T07p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Column", disabled: false },
        { id: "TDIdTP-T08p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Row", disabled: false },

        { id: "TDIdTPDT-BVS01p", name: "simple-bar", chartImage: stackedBar, description: "AVERAGE", disabled: false },
        { id: "TDIdTPDT-BHS01p", name: "horizontal-bar", chartImage: barHorizontalStackedBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTPDT-L01p", name: "line", chartImage: lineMultiLineChart, description: "AVERAGE", disabled: false },
        { id: "TDIdTPDT-T03p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Column", disabled: false },
        { id: "TDIdTPDT-T04p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Row", disabled: false },
        { id: "TDIdTPDT-BVS05p", name: "simple-bar", chartImage: stackedBar, description: "MEDIAN", disabled: false },
        { id: "TDIdTPDT-BHS05p", name: "horizontal-bar", chartImage: barHorizontalStackedBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTPDT-L05p", name: "line", chartImage: lineMultiLineChart, description: "MEDIAN", disabled: false },
        { id: "TDIdTPDT-T07p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Column", disabled: false },
        { id: "TDIdTPDT-T08p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Row", disabled: false },
];

export default timePeriodList;

