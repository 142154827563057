import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
import lineMultiLine from "assets/images/chart-images/multi-line.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";

const timePeriodList = [
        { id: "DIsTPDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DIsTPDT-BVS02p", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "DIsTPDT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DIsTPDT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DIsTPDT-L01p", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "DIsTPDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DIsTPDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "DIsCTDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DIsCTDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DIsCTDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
        
        { id: "DIsCDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DIsCDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DIsCDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "DIsCTCDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DIsCTCDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DIsCTCDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;

