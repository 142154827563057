import axios from "axios";
import store from "store";
import { uploadCSVProgress, uploadCSVStatus } from "store/actions/charts";
import { saveAs } from "file-saver";
const API_URL = process.env.REACT_APP_API_URL;
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.headers.common["Authorization"] =
  "Bearer " + (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
localStorage.getItem("invite") &&
  (axios.defaults.headers.common["Invite"] = localStorage.getItem("invite"));
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_token")
      localStorage.removeItem("role");
      localStorage.removeItem("dont-show-too-much-data-modal");
      let check = localStorage.getItem("dontshow")
      localStorage.clear()
      if (check) {
        localStorage.setItem("dontshow", 'dontShow')
      }
      sessionStorage.clear();
      localStorage.setItem("acceptedCookiePolicyPrompt", true);
      // store.dispatch(logout());
      // history.push("/login");
    }
    return Promise.reject(error);
  }
);
export function changeAxiosToken(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
export function verifyToken() {
  return axios.get(`${API_URL}/api/verify-token`);
}
export function inviteVerifyToken() {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
  axios.defaults.headers.common["Invite"] = localStorage.getItem("invite");
  return axios.get(`${API_URL}/api/invite-verify-token`);
}

// AUTH
export function login(email, password) {
  return axios.post(`${API_URL}/api/login`, { email, password });
}
//stripe
export function getStripeProducts() {
  return axios.get(`${API_URL}/api/stripe/products`);
}
//Free register
export function registrationWithoutSubcription(token, password) {
  return axios.post(`${API_URL}/api/payments/registration-without-subscribe`, {
    token,
    password,
  });
}

export function getInvitedOrganizationStatus(token) {
  return axios.get(`${API_URL}/api/check-user/${token}`);
}
export function getUserCurrentProduct() {
  return axios.get(`${API_URL}/api/stripe/product`);
}
export function loginAdminAcount(email, admin, id) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
  axios.defaults.headers.common["Invite"] = null;
  return axios.post(`${API_URL}/api/admin-login`, { email, admin, id });
}

export function loginInviteAcount(email, id) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
  localStorage.getItem("invite") &&
    (axios.defaults.headers.common["Invite"] = localStorage.getItem("invite"));
  return axios.post(`${API_URL}/api/invite-login-account`, { email, id });
}

export function accounts(email, password, isSavePassword) {
  return axios.post(`${API_URL}/api/accounts`, { email, password, isSavePassword });
}

export function googleAccounts(email) {
  return axios.post(`${API_URL}/api/google-accounts`, { email });
}

export function addTeammate(name, email, role, user_id) {
  return axios.post(`${API_URL}/api/add-teammate`, {
    name,
    email,
    role,
    user_id,
  });
}

export function refreshToken() {
  return axios.post(`${API_URL}/api/refresh-token`);
}

export function userLogoutActivity() {
  let check = localStorage.getItem("dontshow")
  localStorage.clear()
  if (check) {
    localStorage.setItem("dontshow", 'dontShow')
  }
  sessionStorage.clear();
  localStorage.setItem("acceptedCookiePolicyPrompt", true);
  return axios.post(`${API_URL}/api/logout`);
}
export function checkPassword(password) {
  return axios.post(`${API_URL}/api/check-password`, { password });
}
export function me() {
  return axios.post(`${API_URL}/api/users/me`);
}

export function getUserChartsStats(id) {
  return axios.get(`${API_URL}/api/user/${id}/charts-stats`);
}

export function setPassword(data) {
  return axios.post(`${API_URL}/api/set-password`, data);
}

export function isConfirmTokenExistQuery(code) {
  return axios.post(`${API_URL}/api/exist-confirm-token`, { code });
}

export function passwordResetLink(email) {
  return axios.post(`${API_URL}/api/forgot-password`, { email })
      .then(response => response.data);
}

export function confirmNewEmail(code, email) {
  return axios.post(`${API_URL}/api/confirm-new-email`, { code, email });
}

export function forgotInvitePassword(code, password) {
  return axios.post(`${API_URL}/api/forgot-invite-password`, {
    code,
    password,
  });
}
export function inviteLoginConfirm(code, password, newPassword) {
  return axios.post(`${API_URL}/api/invite-login-confirm`, {
    code,
    password,
    newPassword,
  });
}

export function inviteLogin(code, password, newPassword) {
  return axios.post(`${API_URL}/api/invite-login`, {
    code,
    password,
    newPassword,
  });
}

export function deleteTeammate(id) {
  return axios.delete(`${API_URL}/api/teammate/${id}`);
}

export function confirmInviteKey(code) {
  return axios.post(`${API_URL}/api/confirm-Invit-key`, { code });
}

export function changeTeammate(data, id) {
  return axios.put(`${API_URL}/api/teammate/${id}`, { data });
}

// SUPER_ADMIN
export function getDashboardData() {
  return axios.get(`${API_URL}/api/super-admin/dashboard`);
}

export function getIndividuals(sort, status) {
  return axios.get(
    `${API_URL}/api/super-admin/individuals?sort=${sort}&status=${status}`
  );
}

export function getAutomationRegenerateData() {
  return axios.get(
    `${API_URL}/api/super-admin/individuals-active`
  )
}
export function automationRegenerate(userArr) {
  return axios.post(
    `${API_URL}/api/automation-regenerate`, userArr
  )
}

export function getIndividualUserData(id) {
  return axios.get(`${API_URL}/api/super-admin/individuals/${id}`);
}

export function getMyAccount() {
  return axios.get(`${API_URL}/api/user/my-account`);
}

export function addIndividualUser(userData) {
  return axios.post(`${API_URL}/api/super-admin/individuals`, userData);
}

export function addOrganization(organizationData) {
  return axios.post(
    `${API_URL}/api/super-admin/add_organization`,
    organizationData
  );
}
export function automationRepeat(data) {
  return axios.post(`${API_URL}/api/automation-repeat`, data);
}

export function getOrganizations() {
  return axios.get(`${API_URL}/api/super-admin/organizations`);
}

export function searchIndividualUsers(q) {
  source && source.cancel();
  // save the new request for cancellation
  source = axios.CancelToken.source();
  return axios.get(`${API_URL}/api/super-admin/individuals/search?q=${q}`, {
    cancelToken: source.token,
  });
}

export function searchOrganizations(q) {
  source && source.cancel();
  // save the new request for cancellation
  source = axios.CancelToken.source();
  return axios.get(`${API_URL}/api/super-admin/organizations/search?q=${q}`, {
    cancelToken: source.token,
  });
}

export function loginAsThisUser(id) {
  return axios.post(`${API_URL}/api/login-as`, { userId: id });
}

export function loginAsInviteUser(id) {
  return axios.post(`${API_URL}/api/invite-login-as`, { invite_user_id: id });
}

export function getOrganizationData(id) {
  return axios.get(`${API_URL}/api/super-admin/organizations/${id}`);
}

export function onChangeUserData(id, data, invalidUser) {
  if (invalidUser) {
    return axios.put(`${API_URL}/api/teammate/${id}`, { data });
  } else {
    return axios.patch(`${API_URL}/api/super-admin/individuals/${id}`, data);
  }
}

export function updatePassword(id, data) {
  return axios.patch(
    `${API_URL}/api/super-admin/individuals/${id}/password`,
    data
  );
}

export function onChangeOrganizationData(id, data) {
  return axios.put(`${API_URL}/api/super-admin/organization/${id}`, data);
}

export function imageUpload(file) {
  return axios.post(`${API_URL}/api/super-admin/image-upload`, file);
}

export function uploadAvatar(id, avatar) {
  return axios.post(
    `${API_URL}/api/super-admin/individual/upload-avatar/${id}`,
    { avatar }
  );
}

export function uploadOrganizationAvatar(id, avatar) {
  return axios.post(
    `${API_URL}/api/super-admin/organization/upload-avatar/${id}`,
    { avatar }
  );
}

export function onChangeUsersEmail(id, email) {
  return axios.patch(`${API_URL}/api/super-admin/individuals/${id}/email`, {
    email,
  });
}

export function deleteUserAccount(id) {
  return axios.delete(`${API_URL}/api/super-admin/individuals/${id}`);
}

export function changeAccountTypeToFree(id) {
  return axios.put(`${API_URL}/api/super-admin/individuals/to-free/${id}`);
}

export function changeAccountTypeToUnlimited(id) {
  return axios.put(`${API_URL}/api/super-admin/individuals/to-unlimited/${id}`);
}

export function deleteMyAccount() {
  return axios.delete(`${API_URL}/api/user`);
}

export function deleteMyPhoto() {
  return axios.delete(`${API_URL}/api/user-delete-photo`);
}
export function getDeliveryStats(
  page,
  limit,
  search,
  dateRange,
  status,
  title
) {
  const params = {
    page,
    limit,
    search,
    dateRange,
    status,
    title,
  };
  return axios.get(`${API_URL}/api/automation-statistics`, { params });
}

export function blockUserAccount(id) {
  return axios.post(`${API_URL}/api/super-admin/block-individual`, { id });
}
// export function getIntercomUser(id) {
//   return axios.get(`${API_URL}/api/intercom_user_url?user=${id}`);
// }
export function getOrganizationJournals(id) {
  return axios.get(`${API_URL}/api/super-admin/organization/${id}/journals`);
}

export function addOrganizationJournal(data) {
  return axios.post(`${API_URL}/api/super-admin/add_journal`, data);
}

export function deleteOrganizationJournal(data) {
  return axios.delete(`${API_URL}/api/super-admin/journal`, { data });
}

export function updateOrganizationJournal(data) {
  return axios.put(`${API_URL}/api/super-admin/journal/organization`, data);
}

export function getStatsData(id) {
  return axios.get(`${API_URL}/api/super-admin/individuals/${id}/stats`);
}

export function increaseChartDownloadCount() {
  return axios.put(
    `${API_URL}/api/super-admin/individuals/stats/download-count`
  );
}

export function increaseReportGeneratedCount() {
  return axios.put(
    `${API_URL}/api/super-admin/individuals/stats/genetated-count`
  );
}

export function getActivityData(user, type, page, search = "") {
  return axios.get(
    `${API_URL}/api/super-admin/activities?user=${user}&type=${type}&page=${page}&q=${search}`
  );
}


export function updateSelectingChartsModal(selectChartModal) {
  return axios.patch(`${API_URL}/api/users/show-chart-selecting-modal`, { selectChartModal });
}

// CHARTS AND USERS

// For chart progress bar
// const options = {
//     onUploadProgress : progressEvent => {
//         const { loaded, total } = progressEvent;
//         const percent = Math.floor( ( loaded * 100) / total );
//         store.dispatch(uploadCSVProgress(percent));
//     }
// };

export function handleUploading(data, isCanceled, radioChooseDateFormat) {
  if (isCanceled) {
    source && source.cancel("Request canceled!");
    source = axios.CancelToken.source();
    store.dispatch(uploadCSVStatus("canceled"));
    store.dispatch(uploadCSVProgress(0));
  }

  const conf = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      store.dispatch(uploadCSVProgress(percent));
      if (percent === 100) store.dispatch(uploadCSVStatus("processing"));
    },
    cancelToken: source.token,
  };
  if (data) {
    data.radioChooseDateFormat = localStorage.getItem('radioChooseDateFormat')
    data.submissionAndDecisionDate = localStorage.getItem('submissionAndDecisionDate')
    data.editorsAndReviewersDate = localStorage.getItem('editorsAndReviewersDate')
    data.submissionDate = localStorage.getItem('submissionDate')
    data.editorsDate = localStorage.getItem('editorsDate')
    data.radioEditorsReviewersSubmissionsDecisions = localStorage.getItem("radioEditorsReviewersSubmissionsDecisions")
  }
  return axios.post(
    `${API_URL}/api/csv-upload`,
    isCanceled ? null : data,
    conf
  );
}

export function getCSV() {
  return axios.get(
    `${API_URL}/api/get-review-chart-data`
  )
}

export async function handleGenerate(data) {
  let radioChooseDateFormat = localStorage.getItem("radioChooseDateFormat");
  const decisionHeaders = sessionStorage.getItem("decisionHeaders")

  if (decisionHeaders) {
    data.decisionHeaders = JSON.parse(decisionHeaders)
  }
  data.section = "submissions";
  if (
    localStorage.getItem("radioEditorsReviewersSubmissionsDecisions") === "0"
  ) {
    data.section = "submissions";
  }
  if (
    localStorage.getItem("radioEditorsReviewersSubmissionsDecisions") === "1"
  ) {
    data.section = "decisions";
  }
  if (
    localStorage.getItem("radioEditorsReviewersSubmissionsDecisions") === "2"
  ) {
    data.section = "editors";
  }
  if (
    localStorage.getItem("radioEditorsReviewersSubmissionsDecisions") === "3"
  ) {
    data.section = "reviewers";
  }
  if (
    localStorage.getItem("radioEditorsReviewersSubmissionsDecisions") === "4"
  ) {
    data.section = "authors";
  }
  data.system = localStorage.getItem("manuscriptChecked");
  if (!radioChooseDateFormat) radioChooseDateFormat = "MM/DD/YYYY";
  data.radioChooseDateFormat = radioChooseDateFormat;
  data.submissionAndDecisionDate = localStorage.getItem('submissionAndDecisionDate')
  data.editorsAndReviewersDate = localStorage.getItem('editorsAndReviewersDate')
  data.submissionDate = localStorage.getItem('submissionDate')
    data.editorsDate = localStorage.getItem('editorsDate')
  // let reportTypeName = "";
  // if (data.reportType === 2) reportTypeName = "initialDecisions";
  // if (data.reportType === 6) reportTypeName = "timeToInitialDecisions";
  // if (data.reportType === 3) reportTypeName = "rejectInitialDecisions";
  // if (data.reportType === 4) reportTypeName = "acceptInitialDecisions";
  // if (data.reportType === 5) reportTypeName = "finalDecisions";
  // if (data.reportType === 10) reportTypeName = "timeToFinalDecision";
  // if (data.reportType === 22) reportTypeName = "rejectFinalDecisions";
  // if (data.reportType === 24)
  //   reportTypeName = "totalTimeWithPeerReviewersInitialDecision";
  // if (data.reportType === 11) reportTypeName = "acceptFinalDecisions";
  // if (data.reportType === 27)
  //   reportTypeName = "numberOfReviewersInvitedPerManuscript";
  // if (data.reportType === 31 || data.reportType === 17)
  //   reportTypeName = "timeFromEditorAssignmentToInitialDecision";
  // let preferencesResponse = await getPreferences();
  // let preferences = preferencesResponse && preferencesResponse.data && preferencesResponse.data.preferences.data.data;
  // if (preferences && preferences[reportTypeName]) {
  //   if (reportTypeName === "numberOfReviewersInvitedPerManuscript") {
  //     preferences[reportTypeName].saved &&
  //       (data.zeroAcceptCountRadio = preferences[reportTypeName].checked);
  //     preferences[reportTypeName].saved &&
  //       (data.zeroRejectCountRadio = preferences[reportTypeName].checked);
  //   } else {
  //     if (preferences[reportTypeName].accept.saved) {
  //       data.zeroAcceptCountRadio = preferences[reportTypeName].accept.checked;
  //     } else {
  //       data.zeroAcceptCountRadio =
  //         +localStorage.getItem("zeroAcceptCountRadio") || 0;
  //     }
  //     if (preferences[reportTypeName].reject.saved) {
  //       data.zeroRejectCountRadio = preferences[reportTypeName].reject.checked;
  //     } else {
  //       data.zeroRejectCountRadio =
  //         +localStorage.getItem("zeroRejectCountRadio") || 0;
  //     }
  //   }
  // }
  if (data.dateRangeType === -1) {
    data.dateRangeType = 1;
  }
  const wantedReportType = localStorage.getItem("wantedReportType")
  if(wantedReportType && !data.condition) {
    data.reportType = JSON.parse(wantedReportType).value    
  }

  return axios.post(`${API_URL}/api/calculate`, data);
}

export function getDecisonHeaders(data) {
  return axios.post(`${API_URL}/api/decisionHeaders`, data);
}
export function generateForReports(data) {
  let radioChooseDateFormat = localStorage.getItem("radioChooseDateFormat");
  if (!radioChooseDateFormat) radioChooseDateFormat = "MM/DD/YYYY";
  data.radioChooseDateFormat = radioChooseDateFormat;
  data.submissionAndDecisionDate = localStorage.getItem('submissionAndDecisionDate')
  data.editorsAndReviewersDate = localStorage.getItem('editorsAndReviewersDate')
  data.submissionDate = localStorage.getItem('submissionDate')
    data.editorsDate = localStorage.getItem('editorsDate')
  return axios.post(`${API_URL}/api/reports/calculate`, data);
}

export function getDateRange(fileName, manuscriptSystem) {
  return axios.get(`${API_URL}/api/reports/get-date-range`, {
    params: {
      fileName,
      manuscriptSystem,
    },
  });
}

// -------- JournalName -----------------------

export function sendJournalName(data) {
  return axios.post(`${API_URL}/api/send-journal-name`, { data });
}

export function sendAllJournalNames(data) {
  return axios.post(`${API_URL}/api/send-journal-names`, { data });
}

export function getJournalName(id, oldJournalName) {
  return axios.get(`${API_URL}/api/get-journal-name`, {
    params: {
      id,
      oldJournalName,
    },
  });
}
//---------------------------------------------------------

export function downloadAsPdf(data) {
  return axios
    .post(`${API_URL}/api/create-pdf`, data)
    .then(() => axios.get(`${API_URL}/api/fetch-pdf`, { responseType: "blob" }))
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "table.pdf");
    });
}

export function chartToPng(data, responseBlob = false) {

  if (responseBlob) {
    return axios.post(
      `${API_URL}/api/create-png`,
      { data },
      { responseType: "blob" }
    );
  }
  return axios.post(`${API_URL}/api/create-png`, { data });
}

export function getChartDataForPng(id) {
  return axios.get(`${API_URL}/api/get-chart-data-for-png/${id}`);
}

export function addUserChartStats(chartType, chartTitle, section, system) {
  axios.post(`${API_URL}/api/download-action`, {
    chartType,
    chartTitle,
    section,
    system,
  });
}

//favorites
export function addFavorite(data, name) {
  return axios.post(`${API_URL}/api/favorite`, { data, name });
}

export function updateFavorite(data, id, name) {
  return axios.put(`${API_URL}/api/favorite/${id}`, { data, name });
}

export function getFavoriteById(id, report_id) {
  return axios.get(`${API_URL}/api/favorite/${id}/${report_id}`);
}

export function getAllFavorites(reportType, page) {
  return axios.get(`${API_URL}/api/favorite`, {
    params: {
      reportType,
      page,
    },
  });
}
export function getPositiveFavorites(user_id) {
  return axios.get(`${API_URL}/api/positivefavorites`, {
    params: {
      user_id,
    },
  });
}
export function deleteFavorite(id) {
  return axios.delete(`${API_URL}/api/favorite/${id}`);
}

export function checkCsv(fileName, manuscriptType, reportType) {
  return axios.post(`${API_URL}/api/favorite/csv-check`, {
    fileName,
    manuscriptType,
    reportType,
  });
}
export function repeatInvite(data) {
  return axios.post(
    `${API_URL}/api/super-admin/individuals/repeatInvite`,
    data
  );
}
// preferences

export function getPreferences() {
  return axios.get(`${API_URL}/api/get-preferences`);
}

export function getJournalNames() {
  return axios.get(`${API_URL}/api/get-journal-names`);
}

export function sendPreferences(data) {
  return axios.post(`${API_URL}/api/send-preferences`, { data });
}

export function updatePreferences(data) {
  return axios.put(`${API_URL}/api/update-preferences`, { data });
}
export function changeAutomation(data, id) {
  return axios.put(`${API_URL}/api/edit-automation/${id}`, { data });
}
export function addAutomation(data) {
  return axios.post(`${API_URL}/api/send-automation`, { data });
}
export function automationPopUp(value, id) {
  return axios.put(`${API_URL}/api/automation-pop-up/${id}`, { value });
}
export async function getAutomation() {
  const data = await axios.get(`${API_URL}/api/get-automation`);
  return data;
}

export function downloadPersonalInformation() {
  return axios.post(`${API_URL}/api/user/donwload-personal-information`);
}
//admin dummy data add
export function uploadDummyData(data) {
  return axios.post(`${API_URL}/api/dummy-data/csv-upload`, data);
}
//admin coupons
export function getAllCopons() {
  return axios.get(`${API_URL}/api/coupons`);
}

export function createCoupon(data) {
  return axios.post(`${API_URL}/api/coupons`, data);
}
export function deleteCoupon(id) {
  return axios.delete(`${API_URL}/api/coupons/${id}`);
}
//user billing info
export function getBillingInfo() {
  return axios.get(`${API_URL}/api/billing`);
}
//sybscription
export function cancelSubscription() {
  return axios.delete(`${API_URL}/api/subscriptions`);
}
export function getSubscriptionCurrentPeriodEnd() {
  return axios.get(`${API_URL}/api/subscriptions/current-period-end`);
}

export function reactivateSubscription(price, customer) {
  return axios.put(
    `${API_URL}/api/subscriptions/reactivate/${customer}/${price}`
  );
}

export function getMyTeammates() {
  return axios.get(`${API_URL}/api/my-teammates`);
}

export function changeCard(token) {
  return axios.put(`${API_URL}/api/subscriptions/change-card`, {
    cardID: token,
  });
}

export function changeSubscription(price, prod, coupon, id) {
  return axios.put(`${API_URL}/api/subscriptions`, {
    price,
    coupon,
    prod,
    id,
  });
}

//admin subscription infos
export function getBillingInfoForSingleUser(id) {
  return axios.get(`${API_URL}/api/super-admin/billing/${id}`);
}

export function cancelSubscriptionFromAdmin(id, type) {
  return axios.post(`${API_URL}/api/super-admin/subscriptions/${id}`, { type });
}

//check if coupon is valid
export function checkIsCouponValid(coupon, prodId, priceId, id) {
  return axios.post(`${API_URL}/api/coupons/check`, {
    coupon,
    prodId,
    priceId,
    id,
  });
}

export function getInvitePermissions(id) {
  return axios.get(`${API_URL}/api/get-permissions/${id}`);
}
export function getReportPermissions(id) {
  return axios.get(`${API_URL}/api/get-report-permissions/${id}`);
}
export function getStandardReportPermissions(id) {
  return axios.get(`${API_URL}/api/get-standard-report-permissions/${id}`);
}

export function changePermissionsTeammate(
  id,
  value,
  reportType,
  reportName,
  user_id,
  report_id
) {
  return axios.put(`${API_URL}/api/change-permissions/${id}`, {
    value,
    reportType,
    reportName,
    user_id,
    report_id,
  });
}
//reports
export function addToReport(data) {
  return axios.post(`${API_URL}/api/reports`, data);
}

export function getReports() {
  return axios.get(`${API_URL}/api/reports`);
}
export function updateOrderReports(data) {
  return axios.put(`${API_URL}/api/reports`, { data });
}
export function getReportsByID(id) {
  return axios.get(`${API_URL}/api/reports/${id}`);
}
export function getDistinctiveReports(data) {
  return axios.get(`${API_URL}/api/reports/distinctive`, { params: data });
}
export function getStandartReports(id) {
  return axios.get(`${API_URL}/api/standart-reports/${id}`);
}

export function getReportsByIdForPdf(id) {
  return axios.get(`${API_URL}/api/reports-for-pdf/${id}`);
}

export function getReportsImgByIdForPdf(id) {
  return axios.get(`${API_URL}/api/get-pdf-images/${id}`);
}

export function addToReportsCharts(data) {
  return axios.post(`${API_URL}/api/reports/charts`, data);
}

export function deleteReport(id) {
  return axios.delete(`${API_URL}/api/reports/${id}`);
}

export function updateReport(id, data) {
  return axios.put(`${API_URL}/api/reports/${id}`, data);
}

// export function saveDataForGenerate(data, uploadCSVInfo) {
//   return axios.post(`${API_URL}/api/reports/save-data-for-generate`, { data, uploadCSVInfo});
// }


export function deleteReportChart(id) {
  return axios.delete(`${API_URL}/api/reports/charts/${id}`);
}

export function addReportFromFavorite(id, reportId) {
  return axios.post(`${API_URL}/reports/from-favorite`, {
    id,
    reportId,
  });
}
export function searchReport(value) {
  return axios.get(`${API_URL}/api/reports/search/${value}`);
}

export function saveAsNewReport(title, report, manuscriptSystem) {
  return axios.post(`${API_URL}/api/reports/save-as`, {
    title,
    report,
    manuscriptSystem,
  });
}

export function saveDataForGenerate(data, uploadCSVInfo) {
  return axios.post(`${API_URL}/api/reports/generate-pdf-and-send-email`, {
    data,
    uploadCSVInfo,
  });
}

export function updateMyChartName(id, name, title, subtitle, note) {
  return axios.put(`${API_URL}/api/favorite/upadte_name/${id}`, {
    name,
    title,
    subtitle,
    note,
  });
}

export function changeDataSelectionConfigName(
  oldName,
  newName,
  type,
  fileName
) {
  return axios.put(`${API_URL}/api/csv/change-name-config`, {
    oldName,
    newName,
    type,
    fileName,
  });
}

//2FA 
export function getSecretForUserTwoFac(forceNew = false) {
  return axios.get(`${API_URL}/api/2fa/setup`, {
    params: {
      forceNew: forceNew
    }
  });
}

export function get2faStatus() {
  return axios.get(`${API_URL}/api/get2faStatus`);
}

export function generateBackupCodes() {
  return axios.get(`${API_URL}/api/generate-backup-codes`)
}

export function confirmSecret(payload) {
  return axios.post(`${API_URL}/api/confirm-secret`, payload)
}

export function verifyStatus(payload) {
  return axios.post(`${API_URL}/api/2fa/updateStatus`, payload)
}

export function removeStatue(payload) {
  return axios.post(`${API_URL}/api/2fa/removeStatus`, payload)
}

export function getNewSecret(payload) {
  return axios.post(`${API_URL}/api/2fa/newSecret`, payload);
}

export const copyReport = (payload) => {
  return axios.post(`${API_URL}/api/copyReport`, payload)
}

export const getHmac = (id) => {
  return axios.get(`${API_URL}/api/hmac?id=${id}`)
}

export function updateNotificationStatus(payload) {
  return axios.post(`${API_URL}/api/updateNotificationStatus`, payload);
}

export function updateAutomationNotificationStatuss(payload) {
  return axios.post(`${API_URL}/api/updateAutomationNotificationStatus`, payload);
}
