import { COOKIE_POLICY_PROMPT_ACTION } from "store/actionTypes";

const defaultState = {
    result: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case COOKIE_POLICY_PROMPT_ACTION:
            return {
                ...state,
                result: action.payload
            }
        default:
            return state
    }
}

