import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const frequencyList = [
    { id: "#MDTFO-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "#MDTFO-T01r", name: "table", v2: "Column", chartImage: table, disabled: false },
    { id: "#MDTFO-T02r", name: "table", v2: "Row", chartImage: table, disabled: false },
    { id: "#MDTFO-T20r", name: "table", chartImage: table, disabled: false },
];

export default frequencyList;
