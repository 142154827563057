import line_chart  from "assets/images/chart-images/line-chart.png";
import table  from "assets/images/chart-images/table.png";
const agreedToCompleted = [
        {id: "ACCTP-L01s", name: "line", chartImage: line_chart, disabled: false},
        {id: "ACCTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "ACCTP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "ACCTP-L01e", name: "line", chartImage: line_chart, disabled: false},
        {id: "ACCTP-L01r", name: "line", chartImage: line_chart, disabled: false},
        {id: "ACCTP-T01r", name: "line", chartImage: table, v2: "Column", disabled: false},
        {id: "ACCTP-T02r", name: "line", chartImage: table, v2: "Row", disabled: false},
];

export default agreedToCompleted;