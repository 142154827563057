//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";

const manuscriptTypeNone = [
        // performance - none
        { id: "TDIAM-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDIAM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDIAM-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
];

export default manuscriptTypeNone;
