import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const associateEditors = [
    { id: "#AAE-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
    { id: "#AAE-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, },
    { id: "#AAE-T01r", name: "table", chartImage: table, v2: "Column", disabled: false, },
    { id: "#AAE-T02r", name: "table", chartImage: table, v2: "Row", disabled: false, },
    { id: "#AAE-T20r", name: "table", chartImage: table, disabled: false, },
];

export default associateEditors;