import axios from "axios";
import * as api from "api";
import moment from "moment";

// function parseJwt (token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));

//     return JSON.parse(jsonPayload);
// };

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  

setInterval(async () => {
    const token = (localStorage.getItem('user_token') || sessionStorage.getItem("user_token"));
    if (token) {
        const exp = parseJwt(token).exp * 1000;
        const expTime = moment.utc(exp).subtract(10, 'minutes');
        const now = moment.utc();
        if(now.isAfter(expTime)){
            try {
                const { data } = await api.refreshToken();
                axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
                localStorage.setItem('user_token', data.token);
            } catch (error) {
                
            }
        }
    }
}, 1000*60);
