import * as actions from "store/actionTypes";
import { handleGenerate } from "store/actions/charts";
// import Intercom from "utils/intercom";
import store from "store";

// const notify = (text) => {
//   toast.info(text, {
//     position: toast.POSITION.BOTTOM_CENTER,
//     closeButton: false,
//   });
// };

export function changeDataSeriesIDsReset() {
  return { type: actions.DATA_SERIES_COLORS_IDS_CHANGE_RESET };
}
export function changeDataSeriesIDs(name, newName) {
  return {
    type: actions.DATA_SERIES_COLORS_IDS_CHANGE,
    payload: {
      name,
      newName,
    },
  };
}
export function handleThirdAlignment(value) {
  return {
    type: actions.THIRD_ALIGNMENT_VALUE_CHANGE,
    value,
  };
}
export function handleSecondaryAlignment(value) {
  return {
    type: actions.SECONDATY_ALIGNMENT_VALUE_CHANGE,
    value,
  };
}
export function handleAlignmentValueChange(alignmentType, value) {
  const values = {
    chartTitles: store.getState().formatOptions.chartTitles.alignment,
    axisTitles: store.getState().formatOptions.axisTitles.alignment,
  };
  const {
    charts: { chartType },
  } = store.getState();
  if (values[alignmentType] !== value && chartType) {
    // notify("Your changes have been made.");
  }
  return {
    type: actions.ALIGNMENT_VALUE_CHANGE,
    alignmentType,
    value,
  };
}

export function handleManualChange(sectionName, changeType) {
  return {
    type: actions.MANUAL_CHANGE,
    sectionName,
    changeType,
  };
}

export function handleInputChange(sectionName, inputType, value) {
  return {
    type: actions.INPUT_CHANGE,
    sectionName,
    inputType,
    value,
  };
}

export function handleToggleDropdown(sectionName, dropdownType) {
  return {
    type: actions.DROPDOWN_ACION_CHANGE,
    sectionName,
    dropdownType,
  };
}

export function handleDropdownValue(sectionName, dropdownType, value) {
  // notify("Your changes have been made.");
  return {
    type: actions.DROPDOWN_VALUE_CHANGE,
    sectionName,
    dropdownType,
    value,
  };
}

export function checkedOptionsChange(sectionName, optionType, identity) {
  if (identity !== "colorOpen") {
    // notify("Your changes have been made.");
  }
  return {
    type: actions.CHECKED_OPTIONS_CHANGE,
    sectionName,
    optionType,
    identity,
  };
}

export function checkedPickerColor(pickerName, pickerType, color) {
  // notify("Your changes have been made.");
  return {
    type: actions.CHANGE_COLOR,
    pickerName,
    pickerType,
    color,
  };
}

export function handlePickerClose(pickerName, pickerType, colors) {
  return {
    type: actions.PICKER_CLOSE,
    pickerName,
    pickerType,
    colors,
  };
}

export function handleRadioChange(sectionName, radioIdentifier, value) {
  return {
    type: actions.RADIO_VALUE_CHANGE,
    sectionName,
    radioIdentifier,
    value,
  };
}

export function handleSortChangeAction(value) {
  // notify("Your changes have been made.");
  return {
    type: actions.HANDLE_SORT_CHANGE,
    value,
  };
}

export function handleSortChange(value) {
  return (dispatch) => {
    try {
      dispatch(handleSortChangeAction(value));
      dispatch(handleGenerate());
    } catch (error) {
      console.log(error);
    }
  };
}

export function handleSeperateSortChangeAction(value) {
  // notify("Your changes have been made.");
  return {
    type: actions.HANDLE_SEPERATE_SORT_CHANGE,
    value,
  };
}

export function handleSeperateSortChange(value) {
  return async (dispatch) => {
    try {
      dispatch(handleSeperateSortChangeAction(value));
      await dispatch(handleGenerate());
    } catch (error) {
      console.log(error);
    }
  };
}

export function handleDateRangeChange() {
  // notify("Your changes have been made.");
  return {
    type: actions.DATE_RANGE_CHANGE,
  };
}

export function handleDropdownOpen() {
  return {
    type: actions.DATE_DROPDOWN_OPEN,
  };
}

export function handleDropdownChange(value) {
  // notify("Your changes have been made.");
  return {
    type: actions.DATE_DROPDOWN_CHANGE,
    value,
  };
}

export function showActions() {
  
  // Intercom.addEvent("Toggled labels ON/OFF");
  return {
    type: actions.SHOW_TOTALS,
  };
}


export function changeLabelColor(color) {
  return {
    type: actions.CHANGE_LABEL_COLOR,
    payload: { color },
  };
}

export function showActionsEachType(showIdentifier) {
  // Intercom.addEvent("Toggled labels ON/OFF");

  const actionMap = {
    "showSegmentTotalsAs": actions.SHOW_SEGMENT_TOTALS_AS,
    "ItemChecker": actions.ITEM_CHECKER,
    "showpercentage": actions.SHOW_PERCENTAGE,
    "resetselections": actions.RESET_CHECKERS
  };

  return actionMap.hasOwnProperty(showIdentifier) && { 
    type: actionMap[showIdentifier], 
    showIdentifier 
  }
}

export function chekedDatePickerAction(customColorsData) {
  // notify("Your changes have been made.");
  return {
    type: actions.CUSTOM_COLORS_CHANGE,
    customColorsData,
  };
}

export function colorsChange(graphType, color) {
  const state = store.getState();
  const values = {
    backgroundColor: state.formatOptions.colors.backgroundColor,
    gridlineColor: state.formatOptions.colors.gridlineColor,
  };
  if (values[graphType] !== color) {
    // notify("Your changes have been made.");
  }
  return {
    type: actions.GRAPH_COLOR_CHANGE,
    graphType,
    color,
  };
}

export function resetManualChanges() {
  return {
    type: actions.RESET_MANUAL_CHANGES,
  };
}