//chart
import Histogram from "assets/images/chart-images/histogram.png";

const editAssignFirstRevInvited = [
    // Time from editor assignment to first reviewer invited-none
    { id: "#ETFR-H01s", name: "histogram", chartImage: Histogram, disabled: false },
    { id: "#ETFR-H01e", name: "histogram", chartImage: Histogram, disabled: false },

];

export default editAssignFirstRevInvited;
