import * as actions from "store/actionTypes";
import * as api from "api";
import store from "store";
import chartsSidebarConfig from "config/charts/sidebar.json";
import { handleAlignmentValueChange } from "store/actions/charts/advanced-sidebar/format-options";
import timePeriodCharts from "config/charts/timePeriodCharts";
import { getReports } from "../../../api";
import {
  GET_PREFERENCES_DATA,
} from "../../actionTypes";
import {
  centeredChartsList,
  chartAxisCenter,
  changeSeparateSortValueArr,
} from "./chartDefaultSorting.js"
// import {
//   onChangeInclude,
//   onChangeIncludeAction,
//   onChangeIncludeArrAction,
//   onChangeRadio,
//   onChangeRadioAction,
// } from "./advanced-sidebar/data-selection";
export function isEditChart() {
  return !localStorage.getItem("editChart");
}
export function uploadCSVStatus(status, errorMessage, reading) {
  return {
    type: actions.SET_UPLOAD_STATUS,
    status,
    errorMessage,
    reading,
  };
}

function uploadCSVSuccess(data, wrongCsv) {
  return {
    type: actions.UPLOAD_CSV,
    payload: data,
    wrongCsv: wrongCsv,
  };
}

function uploadCSVError() {
  return {
    type: actions.UPLOAD_CSV_ERROR,
  };
}

export function uploadCSV(
  dataCSV,
  isCanceled,
  cb,
  fileNamePrefix,
  radioChooseDateFormat,
  reading
) {
  if (dataCSV && dataCSV.manuscriptSystem == null) {
    dataCSV.manuscriptSystem = +localStorage.getItem("manuscriptChecked");
  }
  return async (dispatch) => {
    let formatForDateModalError = false;
    try {
      dispatch(uploadCSVStatus("uploading", null, reading));

      const { data } =
        (await api.handleUploading(
          dataCSV,
          isCanceled,
          radioChooseDateFormat ? radioChooseDateFormat : "MM/DD/YYYY"
        )) || {};

      formatForDateModalError = data?.errorType?.formatForDateModalError;

      const { fileName } = data;
      data.fileNamePrefix = fileNamePrefix;
      localStorage.setItem("fileName", fileName);
      localStorage.setItem("fileNamePrefix", fileNamePrefix);
      data.isCustomUpload = dataCSV.isCustomUpload || true;
      if (data.errorType.wrongCsv) {
        await dispatch(uploadCSVStatus("completed", data.errorType));
        await dispatch(uploadCSVSuccess(data));
      } else {
        await dispatch(uploadCSVStatus("completed", null, reading));
        await dispatch(uploadCSVSuccess(data));
      }
      if (cb) {
        if (
          fileName === sessionStorage.getItem("fileCsv") &&
          fileNamePrefix.includes("SD")
        ) {
          if (dataCSV?.data) {
            sessionStorage.setItem("WrongCsv1", data.errorType.wrongCsv);
          } else {
            sessionStorage.setItem("WrongCsv1", false);
          }
          sessionStorage.setItem("JournalName", data.journalName)
        } else {
          if (dataCSV?.data) {
            sessionStorage.setItem("WrongCsv2", data.errorType.wrongCsv);
          } else {
            sessionStorage.setItem("WrongCsv2", false);
          }
          sessionStorage.setItem("JournalName2", data.journalName || "PCORI")

        }
        cb(data.errorType.wrongCsv, formatForDateModalError, data.journalName);
      }
    } catch (error) {
      await dispatch(uploadCSVError());
      if (cb) {
        cb(true, formatForDateModalError);
      }
      console.log(error);
    }
  };
}

export function getPreferencesData(preferences) {
  return async (dispatch) => {
    dispatch({ type: GET_PREFERENCES_DATA, preferences });
  };
}

function chooseChartSystemSuccess(name, value, n, fromFavorite) {
  return {
    type: actions.CHOOSE_CHART_SYSTEM,
    name,
    item: value,
    n,
    fromFavorite: fromFavorite,
  };
}
export function chooseVariableList(n) {
  return {
    type: actions.RESET_VARIABLES_LIST,
    n,
  };
}
export function createNewChart() {
  return async (dispatch) => {
    try {
      await dispatch(handleShowSelectOtherChartModal(false));
      const {
        charts: {
          chartsList,
          generateChartSteps: {
            fileName,
            variable1: { key: var1 },
          },
        },
      } = store.getState();
      await dispatch(handlePaletteAccess());
      await dispatch(selectChart(chartsList[0], fileName));
      if (var1 === "Time period") {
        await dispatch(setSortOldestToNewest());
      } else {
        await dispatch(setSortDefault());
      }
      if (changeSeparateSortValueArr.some((el) => chartsList[0]?.includes(el))) {
        await dispatch(setSortSegmentDesc());
      }
      await dispatch(handleGenerate(chartsList[0], false, true));
      await dispatch(hideLoader());
    } catch (error) {
      console.log(error);
    }
  };
}
export function chooseChartSystem(name, value, n, fromFavorite) {
  // this.onHandleChange("reportType",{key: 'Number of editors', value: 14, name: 'numberOfEditors'}, 2, true)
  return async (dispatch) => {
    try {
      await dispatch(chooseChartSystemSuccess(name, value, n, fromFavorite));
    } catch (error) {
      console.log(error);
    }
  };
}


export function handleShowSelectOtherChartModal(show) {
  return {
    type: actions.SHOW_SELECT_OTHER_CHART_MODAL,
    value: show,
  };
}

function handleGenerateSuccess(
  reportType,
  chartType,
  chartDate,
  data,
  paletteGradientColors
) {
  const {
    charts: {
      generateChartSteps: {
        variable2: { key: legendVariableName },
      },
      chartType: currentChartType,
    },
    formatOptions: {
      chartTitles: { mainTitle, secondaryTitle },
      axisTitles: { xAxisTitle, yAxisTitle, manualChangedAxis },
    },
  } = store.getState() || {};

  let titles = {};

  if (currentChartType === chartType) {
    titles["title"] = mainTitle;
    titles["subtitle"] = secondaryTitle;
  }

  if (manualChangedAxis) {
    titles["x_axis"] = xAxisTitle;
    titles["y_axis"] = yAxisTitle;
  }

  // Check if manual change occurred than keep titles
  if (!window.isEmpty(titles)) {
    data.titles = {
      ...data.titles,
      ...titles,
    };
  }

  // Add variable 2 name for legend
  if (legendVariableName !== "None (optional)") {
    data.calculationResult.legendVariableName = legendVariableName;
  }

  return {
    type: actions.GENERATE_CHART,
    payload: data,
    reportType,
    chartType,
    chartDate,
    paletteGradientColors,
  };
}
export function resetGrouping(section) {
  return {
    type: actions.RESET_GROUPING,
    payload: section
  }
}
export function handleChangeChartZoomCoef(value) {
  return {
    type: actions.CHANGE_ZOOM_COEFFICIENT,
    value,
  };
}

export function changeDate(value) {
  return {
    type: actions.CHANGE_GRAPH_DATE_TYPE,
    value,
  };
}
export function changeSeparateSortValue(value) {
  return {
    type: actions.CHANGE_SEPARATE_SORT_VALUE,
    value,
  };
}
export function changeDataOrderSortValue(value) {
  return {
    type: actions.CHANGE_DATA_ORDER_SORT_VALUE,
    value,
  };
}

export function handleGenerate(item, tooMuchDataOption, firstTime, check, dontWontRequest) {
  const radioERSD = localStorage.getItem("radioEditorsReviewersSubmissionsDecisions")
  const manCheck = localStorage.getItem("manuscriptChecked")
  return async (dispatch) => {
    try {
      const {
        charts: {
          generateChartSteps: {
            reportType: { value: reportType },
            variable1,
            variable2,
          },
          chartType,
        },
        dataSelection: {
          data: dataForGraph,
        },
        formatOptions: {
          chartTitles: { manualChangedAlignment: titlesAlignmentChange },
          axisTitles: { manualChangedAlignment: axisAlignmentChange },
          colors: { dataSeriesIDsColors },
        },
      } = store.getState() || {};
      let filteredDateType;

      if (tooMuchDataOption !== undefined)
        dataForGraph.tooMuchDataOption = tooMuchDataOption;

      //Cloned data for sending to back end
      let clonedDataForGraph = { ...dataForGraph };
      clonedDataForGraph.fileNamePrefix = localStorage.getItem("fileNamePrefix");
      clonedDataForGraph.fileName = localStorage.getItem("fileName");
      /* Check that chart can be generated with choosed data or not */
      // eslint-disable-next-line
      switch (dataForGraph.dateType) {
        case 0:
          filteredDateType = "Year on year";
          break;
        case 1:
          filteredDateType = "Previous quarters";
          break;
        case 2:
          filteredDateType = "Previous months";
          break;
        case 3:
          filteredDateType = "Year";
          break;
      }

      let selectedChartConfig = chartsSidebarConfig.find((item) => {
        return (
          item.reportType === dataForGraph.reportType &&
          item.chartType === dataForGraph.chartType
        );
      });
      let datesConfigObject = selectedChartConfig
        ? selectedChartConfig["data-selection-config"]["Dates for Graph"]
        : {};

      if (!datesConfigObject[filteredDateType]) {
        // Charts configs dates for graph placement-value
        const allowedValue = Object.values(datesConfigObject).indexOf(true);
        clonedDataForGraph.dateType = allowedValue;
        dispatch(changeDate(allowedValue));
      }
      clonedDataForGraph.data_order_sort = clonedDataForGraph.data_order_sort.value;
      clonedDataForGraph.separate_sort_value = clonedDataForGraph.separate_sort_value.value;

      // Set default centered for titles and axis if it is not manual changed TODO
      const foundChart =
        (!titlesAlignmentChange || !axisAlignmentChange) &&
        centeredChartsList.find((element) => element === item);

      if (foundChart) {
        !titlesAlignmentChange &&
          dispatch(handleAlignmentValueChange("chartTitles", "center"));
        !axisAlignmentChange &&
          dispatch(handleAlignmentValueChange("axisTitles", "center"));
      }
      clonedDataForGraph.generatedFirstTime = firstTime;

      clonedDataForGraph.calculationColumn = sessionStorage.getItem("calculationColumn");
      if (timePeriodCharts.includes(item)) {
        clonedDataForGraph.var1 = "time";
        clonedDataForGraph.var2 = "time";
      }
      if (variable1.name === "time" || variable2.name === "time") {
        clonedDataForGraph.var1 = variable1.name;
        clonedDataForGraph.var2 = variable2.name;
      }
      if (changeSeparateSortValueArr.some((el) => item?.includes(el)) && isEditChart()) {
        await dispatch(changeSeparateSortValue({ id: 0, text: "Segments (descending)", value: "descSegment", }));
        clonedDataForGraph.separate_sort_value = "descSegment";
      }
      if (chartAxisCenter.some((el) => item?.includes(el)) && isEditChart()) {
        await dispatch(handleAlignmentValueChange("chartTitles", "center"));
        await dispatch(handleAlignmentValueChange("axisTitles", "center"));
      }

      if (!chartType.includes("DIDT-DTV01") && !check && manCheck === "1" && (!radioERSD || radioERSD === "0")) {
        clonedDataForGraph = {
          ...clonedDataForGraph,
          choosenRevisionNumbers: [
            ...clonedDataForGraph.choosenRevisionNumbers,
            "noRevisionNumber",
          ],
          revisionType: 1,
        };
      }
      if (!dontWontRequest) {
        const { data } = (await api.handleGenerate({ ...clonedDataForGraph, dataSeriesIDsColors: dataSeriesIDsColors, })) || {};
        const { calculationResult: { paletteGradientColors = [] } } = data;
        dispatch(handleGenerateSuccess(reportType, item || chartType, dataForGraph.dateType, data, paletteGradientColors));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export async function handleGenerateForReports(
  report,
  fileData,
  csvType,
  fileName,
  type,
  manuscriptSystem
) {
  const reportsByType = report?.report_charts?.filter(
    (item) => (item.type_csv === csvType || +manuscriptSystem === 3)
  );
  const reportsWithRequestData = [];
  for (let i = 0; i < reportsByType?.length; i++) {
    const element = reportsByType[i];
    const {
      charts: { chartType },
      dataSelection: { data: dataForGraph },
    } = element.data;
    let filteredDateType;

    //Cloned data for sending to back end
    let clonedDataForGraph = { ...dataForGraph };

    /* Check that chart can be generated with choosed data or not */
    // eslint-disable-next-line
    switch (dataForGraph.dateType) {
      case 0:
        filteredDateType = "Year on year";
        break;
      case 1:
        filteredDateType = "Previous quarters";
        break;
      case 2:
        filteredDateType = "Previous months";
        break;
      case 3:
        filteredDateType = "Year";
        break;
    }
    let selectedChartConfig = chartsSidebarConfig.find((item, index) => {
      if (
        !(
          item.reportType === dataForGraph.reportType &&
          item.chartType === dataForGraph.chartType
        ) &&
        index === chartsSidebarConfig.length - 1
      )
        return true;
      return (
        item.reportType === dataForGraph.reportType &&
        item.chartType === dataForGraph.chartType
      );
    });
    let datesConfigObject =
      selectedChartConfig["data-selection-config"]["Dates for Graph"];

    if (!datesConfigObject[filteredDateType]) {
      // Charts configs dates for graph placement-value
      const allowedValue = Object.values(datesConfigObject).indexOf(true);
      clonedDataForGraph.dateType = allowedValue;
    }

    // Pick dates for sending back end
    clonedDataForGraph.data_order_sort =
      clonedDataForGraph.data_order_sort.value;
    clonedDataForGraph.separate_sort_value =
      clonedDataForGraph.separate_sort_value.value;
    clonedDataForGraph.chartType = chartType;
    clonedDataForGraph.manuscriptSystem = manuscriptSystem;
    element.requestData = clonedDataForGraph;
    reportsWithRequestData.push(element);
  }
  const { data } =
    (await api.generateForReports({
      reportsWithRequestData,
      fileData,
      fileName,
      type,
      csvType,
      manuscriptSystem,
    })) || {};
  return data;
}

function selectChartSuccess(chartType, fileName) {
  return {
    type: actions.SELECT_CHART,
    item: chartType,
    fileName,
  };
}

export function selectChart(chartType, fileName) {
  return (dispatch) => {
    try {
      dispatch(selectChartSuccess(chartType, fileName));
    } catch (error) {
      console.log(error);
    }
  };
}

export function handlePaletteAccess() {
  return {
    type: actions.HANDLE_PALETTE_ACCESS,
  };
}

export function setDateManually(value) {
  return {
    type: actions.SET_DATE_MANUALLY,
    value,
  };
}

export function selectCountryType(value) {
  return {
    type: actions.SELECT_COUNTRY_TYPE,
    value,
  };
}

function handleChooseCountry(country) {
  return {
    type: actions.HANDLE_CHOOSE_COUNTRY,
    country,
  };
}

export function generateSingleCountry(country) {
  return async (dispatch) => {
    try {
      dispatch(handleChooseCountry(country));
    } catch (error) {
      console.log(error);
    }
  };
}

function handleMapBarChart(data) {
  return {
    type: actions.HANDLE_MAP_BAR_CHART,
    data,
  };
}

export function generateMapBarChart({ name }, chartCode) {
  return async (dispatch) => {
    try {
      let {
        dataSelection: { data: dataForGraphInitial },
        charts: {
          generateChartSteps: { variable1, variable2 },
        },
      } = store.getState() || {};
      let dataForGraph = { ...dataForGraphInitial };
      dataForGraph.reportType = 1;
      dataForGraph.chartType = chartCode;

      if (
        chartCode === "STP-BV01s" ||
        chartCode === "STP-BV01e" ||
        chartCode === "%RITP-BV01s" ||
        chartCode === "%RITP-BV01e" ||
        chartCode === "#ETP-MBV01e" ||
        chartCode === "%RIM-BV01e" ||
        chartCode === "%RIM-BV01s"
      ) {
        dataForGraph.countryType = 1;
        dataForGraph.selectedFirstCountries = [name];
      } else {
        dataForGraph.continents = [name];
      }
      dataForGraph.calculationColumn =
        sessionStorage.getItem("calculationColumn");
      dataForGraph.var1 = variable1.name;
      dataForGraph.var2 = variable2.name;
      dataForGraph.mapChart = true;
      if (
        chartCode === "%RITP-BV01s" ||
        chartCode === "%RITP-BV01e" ||
        chartCode === "%RIM-BV01e"
      ) {
        dataForGraph.reportType = 3;
      }
      if (chartCode === "#ETP-MBV01e") {
        dataForGraph.reportType = 14;
      }
      dataForGraph.condition = true;
      const { data } = (await api.handleGenerate({...dataForGraph})) || {};
      dispatch(handleMapBarChart(data));
    } catch (error) {
      console.log(error);
    }
  };
}

function setCSVUploadingPercentage(percent) {
  return {
    type: actions.SET_UPLOAD_PERCENTAGE,
    percent,
  };
}

export function uploadCSVProgress(percent) {
  return async (dispatch) => {
    try {
      dispatch(setCSVUploadingPercentage(percent));
    } catch (error) {
      console.log(error);
    }
  };
}

export function resetChartOptions() {
  localStorage.getItem("editChart") && localStorage.removeItem("editChart");
  const {
    charts: {
      generateChartSteps: {
        reportType: { value: reportType },
      },
    },
  } = store.getState() || {};
  return {
    type: actions.RESET_CHART_OPTIONS,
    reportType,
  };
}

export function handleAcceptAndRejectCount(accept, reject, reportType) {
  return {
    type: actions.HANDLE_ACCEPT_AND_REJECT_COUNT,
    accept,
    reject,
    reportType,
  };
}

export function changeQuickChart(quickChart) {
  return {
    type: actions.CHANGE_QUICK_CHART,
    quickChart,
  };
}
export function setSortSequential() {
  return {
    type: actions.SET_SORT_SEQUENTIAL,
  };
}
export function setSortOldestToNewest() {
  return {
    type: actions.SET_SORT_OLDEST_TO_NEWEST,
  };
}
export function setSortAlphabetical() {
  return {
    type: actions.SET_SORT_ALPHABETICAL,
  };
}
export function setSortLegendAsc() {
  return {
    type: actions.SET_SORT_LEGEND_ASC,
  };
}
export function setSortSegmentDesc() {
  return {
    type: actions.SET_SORT_SEGMENT_DESC,
  };
}

export function setSortDefault() {
  return {
    type: actions.SET_SORT_DEFAULT,
  };
}

export function showLoader() {
  return {
    type: actions.SHOW_LOADER,
  };
}

export function hideLoader() {
  return {
    type: actions.HIDE_LOADER,
  };
}

export function addMarkers(data) {
  return {
    type: actions.TIME_FOR_REVIEWERS_TO_SUBMIT_RECOMMENDATIONS_MARKERS,
    payload: data,
  };
}

export function getReportsAction() {
  return async (dispatch) => {
    const reports = await getReports()
    dispatch({ type: actions.GET_ALL_REPORTS, payload: { allReports: reports } })
    dispatch({ type: actions.TEAMMATE_REPORT_PERRMISSIONS, payload: { allReports: reports } })
  }
}
