import React, { Component } from "react";
import Routes from "./routes";
import ErrorPage from "views/errors/server-error";
import MailTo from "components/modules/mailto";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { haseError: false };
    }
    
    componentDidMount() {
        localStorage.removeItem("wantedReportType")
        localStorage.removeItem("wantedVariable1")
       localStorage.removeItem("wantedVariable2")
       localStorage.removeItem('wantedChartCode')
    }

    static getDerivedStateFromError() {
        return { haseError: true };
    }
    render() {
        
        const { declineCookiePolicyPrompt } = localStorage;
        if (declineCookiePolicyPrompt === "true") {
            if(!document.__defineGetter__) {
                Object.defineProperty(document, 'cookie', {
                    get: function(){return ''},
                    set: function(){return true},
                });
            } else {
                document.__defineGetter__("cookie", function() { return '';} );
                document.__defineSetter__("cookie", function() {} );
            }
        }
        if (this.state.haseError) {
            return <ErrorPage />;
        }

        return <Routes />;
    }
}

export default App;
