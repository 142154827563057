import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import chart4Items from "assets/images/chart-images/donut.png";

const continentsNoneList = [
        // Time period - None
        { id: "#RCTR-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-D01e", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        // { id: "#RCTR-D02e", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • UNIQUE" },

];

export default continentsNoneList;