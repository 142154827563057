import { updatePreferences } from "../../../api";
import { CHANGE_PREFERENCES_ACCEPT_VALUE, CHANGE_PREFERENCES_REJECT_VALUE, CHANGE_PREFERENCES_SUBMISSIONS_VALUE, GET_PREFERENCES_DATA, CHANGE_PREFERENCES_ACCEPT_VALUE_ACTION, CHANGE_PREFERENCES_REJECT_VALUE_ACTION } from "../../actionTypes";

const defaultState = {
    submissions: {
        saved: false,
        checked: 0
    },
    initialDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    timeToInitialDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    rejectInitialDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    acceptInitialDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    finalDecisions: {
        accept: {
            saved: true,
            checked: 1
        },
        reject: {
            saved: true,
            checked: 1
        }
    },
    timeToFinalDecision: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    rejectFinalDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    acceptFinalDecisions: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    timeFromEditorAssignmentToInitialDecision: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    totalTimeWithPeerReviewersInitialDecision: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    },
    numberOfReviewersInvitedPerManuscript: {
        saved: false,
        checked: 1
    },
    createImmediateDecision: {
        accept: {
            saved: false,
            checked: 0
        },
        reject: {
            saved: false,
            checked: 0
        }
    }
};


export default (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_PREFERENCES_ACCEPT_VALUE_ACTION:
            var newState = {
                ...state,
                [action.reportTypeName]: {
                    ...state[action.reportTypeName],
                    accept: {
                        saved: action.saved,
                        checked: action.accept
                    }
                }
            }
            updatePreferences(newState)
            return newState
        case CHANGE_PREFERENCES_REJECT_VALUE_ACTION:
            newState = {
                ...state,
                [action.reportTypeName]: {
                    ...state[action.reportTypeName],
                    reject: {
                        saved: action.saved,
                        checked: action.reject
                    }
                }
            }
            updatePreferences(newState)
            return newState
        case CHANGE_PREFERENCES_ACCEPT_VALUE:
            var newState = {
                ...state,
                [action.reportTypeName]: {
                    ...state[action.reportTypeName],
                    accept: {
                        saved: true,
                        checked: action.accept
                    }
                }
            }
            updatePreferences(newState)
            return newState
        case CHANGE_PREFERENCES_REJECT_VALUE:
            newState = {
                ...state,
                [action.reportTypeName]: {
                    ...state[action.reportTypeName],
                    reject: {
                        saved: true,
                        checked: action.reject
                    }
                }
            }
            updatePreferences(newState)
            return newState
        case GET_PREFERENCES_DATA:
            return {
                ...action.preferences
            }
        case CHANGE_PREFERENCES_SUBMISSIONS_VALUE:
            newState = {
                ...state,
                submissions: {
                    saved: true,
                    checked: action.checked
                }
            }
            updatePreferences(newState)
            return newState
        default: return state
    }
}