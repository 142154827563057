import * as actions from "store/actionTypes";

const defaultState = {
    singleOrganization: {},
    updateDataError: {},
    organizationJournals:[],
    addOrganizationJournalError:{},
    updateOrganizationJournalError:{}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.ORGANIZATION:
            return {
                ...state,
                singleOrganization:action.payload
            };
        case actions.CHANGE_ORGANIZATION_DATA_SUCCESS:
            return {
                ...state,
                singleOrganization: action.payload,
                updateDataError: {}
            };
        case actions.CHANGE_ORGANIZATION_DATA_ERROR:
            return {
                ...state,
                updateDataError: action.payload
            };
        case actions.UPLOAD_ORGANIZATION_AVATAR_SUCCESS:
            return {
                ...state,
                singleOrganization: action.payload
            };
        case actions.ORGANIZATION_JOURNALS:
            return {
                ...state,
                organizationJournals: action.payload
            };
        case actions.ADD_ORGANIZATION_SINGLE_JOURNAL:
            const newOrganizationJournal = [...state.organizationJournals];
            newOrganizationJournal.push(action.payload);
            return {
                ...state,
                organizationJournals: newOrganizationJournal,
                addOrganizationJournalError:{}
            };
        case actions.ADD_ORGANIZATION_SINGLE_JOURNAL_ERROR:
            return {
                ...state,
                addOrganizationJournalError: action.payload
            };
        case actions.DELELTE_SINGLE_ORGANIZATION:
            let newOrganizationJournalData = [...state.organizationJournals];
            let newOrganizationJournalAfterDeleting =  newOrganizationJournalData.filter(journal => journal.id !== action.payload);
            return {
                ...state,
                organizationJournals: newOrganizationJournalAfterDeleting
            };
        case actions.UPDATE_ORGANIZATION_JOURNAL:
            let newJournalsData = [...state.organizationJournals];
            let changedItem = action.payload;
            let foundIndex = newJournalsData.findIndex(value => value.id === changedItem.id);
            newJournalsData[foundIndex] = changedItem;
            return {
                ...state,
                organizationJournals:newJournalsData,
                updateOrganizationJournalError:{}
            };
        case actions.UPDATE_ORGANIZATION_JOURNAL_ERROR:
            return {
                ...state,
                updateOrganizationJournalError: action.payload
            };
        default:
            return state;
    }
};