//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";

//bar
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";

//map
import mapUsRegions from "assets/images/chart-images/map/map_us_regions.png";
import mapUsStates from "assets/images/chart-images/map/map_us_states.png";

//chart
import chartChange from "assets/images/chart-images/bubble.png";
 
const singleCountriesList = [
    // Single Country - None
    {id: "DICi-L01s", name: "line", chartImage: lineLineChart, disabled: true},
    {id: "DICi-L01e", name: "line", chartImage: lineLineChart, disabled: true},
    {id: "DICi-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: true},
    {id: "DICi-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: true},
    {id: "DICi-BH01s", name: "horizontal-bar", chartImage: barHorizontalStackedBar, disabled: true},
    {id: "DICi-BH01e", name: "horizontal-bar", chartImage: barHorizontalStackedBar, disabled: true},
    {id: "DICi-D01s", name: "donut", chartImage: chartChange, disabled: false},
    {id: "DICi-D01e", name: "donut", chartImage: chartChange, disabled: false},
    {id: "DICi-BB01s", name: "bubble", chartImage: chartChange, disabled: true},
    {id: "DICi-BB01e", name: "bubble", chartImage: chartChange, disabled: true},
    {id: "DICi-M01s", name: "usa-states-chart", chartImage: mapUsStates, disabled: true},
    {id: "DICi-M01e", name: "usa-states-chart", chartImage: mapUsStates, disabled: true},
    {id: "DICi-M02s", name: "usa-regions-chart", chartImage: mapUsRegions, disabled: true},
    {id: "DICi-M02e", name: "usa-regions-chart", chartImage: mapUsRegions, disabled: true},
];

export default singleCountriesList;
