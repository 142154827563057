//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import chartWithAxis from "assets/images/chart-images/bubble-table.png";
import lineSimpleBarChartEditor from "assets/images/chart-images/simple-bar-change-editor.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';

const editor = [
        // performance - none
        { id: "TDIAE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TDIAE-BV02s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TDIAE-BV03s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false },
        { id: "TDIAE-BV04s", name: "simple-bar", chartImage: lineSimpleBarChartEditor, disabled: false },
        { id: "TDIAE-BV01e", name: "bubble", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BV02e", name: "bubble", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BV03e", name: "bubble", chartImage: lineSimpleBarChartEditor, disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BV04e", name: "bubble", chartImage: lineSimpleBarChartEditor, disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BB01e", name: "bubble", chartImage: chartWithAxis, disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BXV01e", name: "bubble", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TDIAE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDIAE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TDIAE-BB01s", name: "bubble", chartImage: chartWithAxis, disabled: false },
        { id: "TDIAE-BXV01s", name: "box", chartImage: boxChartImage, disabled: false },
        { id: "TDIAE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDIAE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default editor;
