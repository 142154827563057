import { CHANGE_APLOADED_FILE_DATA, LOGOUT } from "../../actionTypes";

const defaultState = {
    manuscriptSystem: 0,
    uploadCSVInfo: {
        SD: {
          title: "Submission and Decisions",
          fileName: null,
          loading: false,
          wrongCsv: null,
          dragCsv: false,
        },
        ER: {
          title: "Editors and Reviewers",
          fileName: null,
          loading: false,
          wrongCsv: null,
          dragCsv: false,
        }
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_APLOADED_FILE_DATA: {
            return {
                ...state,
                ...action.payload,
                uploadCSVInfo: {
                    ...state.uploadCSVInfo,
                    ...action.payload.uploadCSVInfo,
                }
            }
        }
        case LOGOUT:{
            return {
                ...defaultState,
            };
        }
        default:
            return state;
    }
};
