import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "components/modules/layout";
import CookiePolicyPrompt from "components/modules/cookie-policy-prompt";
import axios from "axios";
import { toast } from "react-toastify";

/******* Redux actions ********************************************/
import { me, login } from "store/actions/authActions";
import { changeCookiePolicyPromptAction } from "store/actions/cookiePolicyPromptAction";
import { useNotify } from "utils/notify";
import MailTo from "components/modules/mailto";
/******************************************************************/

const menuList = [
  { icon: "chart", path: "/charts-tables", title: "Charts & tables" },
  // { icon: "journal", title: "Journal" },
  // { icon: "members", title: "Users" },
  // { icon: "activity", title: "Activity" },
  { icon: "heart", path: "/mycharts/create-chart", title: "MyCharts" },
  { icon: "report", path: "/reports/empty-page", title: "MyReports" },
];

function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++)
    deleteCookie(cookies[i].split("=")[0]);
}

function setCookie(name, value, expirydays) {
  var d = new Date();
  d.setTime(d.getTime() + (expirydays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + "; " + expires;
}

function deleteCookie(name) {
  setCookie(name, "", -1);
}

class PrivateRoute extends Component {
  state = {
    acceptedCookiePolicyPromptState: false,
    declineCookiePolicyPromptState: localStorage.declineCookiePolicyPrompt,
    notifySuccess: (useNotify()).notifySuccess,
    notifyCancel: (useNotify()).notifyCancel
  };

  async componentDidMount() {

    const token = (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
    const { auth: { userData = {} } = {} } = this.props;
    if (token) {
      if (Object.getOwnPropertyNames(userData).length === 0) {
        this.props.me(token);
      } else {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + userData.token;
        localStorage.setItem("user_token", userData.token);
        localStorage.removeItem("role");
      }
    }
  }

  deleteCookies() {
    deleteAllCookies();
  }

  // notify = (text) => {
  //   toast.info(text, {
  //     position: toast.POSITION.BOTTOM_CENTER,
  //     closeButton: false,
  //   });
  // };

  handleCookieAction = (type) => {
    this.props.changeCookiePolicyPromptAction(false);

    if (type === "allow") {
      localStorage.setItem("acceptedCookiePolicyPrompt", true);
    } else if (type === "decline") {
      this.deleteCookies();
      localStorage.setItem("declineCookiePolicyPrompt", true);
      if (!document.__defineGetter__) {
        Object.defineProperty(document, 'cookie', {
          get: function () { return '' },
          set: function () { return true },
        });
      } else {
        document.__defineGetter__("cookie", function () { return ''; });
        document.__defineSetter__("cookie", function () { });
      }
      this.state.notifyCancel("Cookies have been deleted from your computer");
    }
  };

  showCookiePopup = () => {
    const { acceptedCookiePolicyPrompt = false } = localStorage;

    if (acceptedCookiePolicyPrompt) {
      localStorage.removeItem("acceptedCookiePolicyPrompt");
      this.setState({
        setAcceptedCookiePolicyPrompt: false,
      });
    } else {
      localStorage.removeItem("declineCookiePolicyPrompt");
      this.setState({
        setDeclineCookiePolicyPrompt: false,
      });
    }
    this.props.changeCookiePolicyPromptAction(true);
  };

  render() {
    const {
      component: Component,
      auth,
      title,
      cookiePolicyPrompt,
      changeCookiePolicyPromptAction,
      ...rest
    } = this.props;
    const { auth: { userData = {} } = {} } = this.props;
    const {
      acceptedCookiePolicyPromptState,
      declineCookiePolicyPromptState,
    } = this.state;
    const showCookiePolicyPrompt = cookiePolicyPrompt.result;
    if (!this.props.path.includes("/demo")) {
      if (!(sessionStorage.getItem("user_token") || localStorage.getItem("user_token"))) {
        return <Redirect to="/login" />;
      } else {
        if (Object.getOwnPropertyNames(this.props.auth.userData).length === 0) {
          return null;
        }
      }
    }
    if(userData.user.inviteUser_role === "user" && window.location.pathname === "/myteam"){
      return <Redirect to="/charts-tables" />;
    } 
    return (
      Object.getOwnPropertyNames(this.props.auth.userData).length > 0 && (
        <Route
          {...rest}
          render={(props) => {
            return (
              <Layout
                showMessage
                menuList={menuList}
                title={title}
                showCookiePopup={this.showCookiePopup}
                changeCookiePolicyPromptAction={this.props.changeCookiePolicyPromptAction}
                {...props}
                {...this.props}
              >
                <Component {...rest} {...props} />
                {showCookiePolicyPrompt &&
                  !acceptedCookiePolicyPromptState &&
                  !declineCookiePolicyPromptState && (
                    <CookiePolicyPrompt
                      handleAction={this.handleCookieAction}
                    />
                  )}
                  <MailTo />
              </Layout>
            );
          }}
        />
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cookiePolicyPrompt: state.cookiePolicyPrompt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ me, changeCookiePolicyPromptAction, login }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));