//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table      from "assets/images/chart-images/table.png";

const editor = [
        // performance - none
        {id: "EAE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "EAE-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "EAE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "EAE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "EAE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "EAE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default editor;
