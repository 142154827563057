import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./style.scss";
import Icon from "components/elements/icon";
import Button from "components/elements/button";

const ModalContent = ({
    children,
    styles: {
        width,
        height,
        padding,
        header,
        headerImg,
        footer,
        footerHeignt,
        title,
        lines = false,
        buttonText,
        buttonType = 3,
        closeIcon = true,
        secondButton = false,
        buttonStyles = {}
    },
    onClose,
    handleButtonClick,
    handleSecondButtonClick,
    disabled,
    icon,
    svgIcon,
    two
}) => {
    return (
        <div id="modal-content" className={`${two ? 'modal-content-two' : 'modal-content'}`} style={{ width, height, padding }}>
            {header && (
                <>
                    <div className={cx("modal-header", lines && 'lines')} style={closeIcon ? { top: '56px' } : { top: '24px' }}>
                        {headerImg && (
                            <div className="modal-header-image">
                                <img src={headerImg} alt="modal img" />
                            </div>
                        )}
                        {icon && <div className="modal-header-title"><img src={icon} alt="icon" /> </div>}
                        {svgIcon && svgIcon}
                    </div>
                    {closeIcon && (
                        <div className="modal-header-close" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.3 5.6999C17.9 5.2999 17.3 5.2999 16.9 5.6999L12 10.5999L7.09999 5.6999C6.69999 5.2999 6.09999 5.2999 5.69999 5.6999C5.29999 6.0999 5.29999 6.6999 5.69999 7.0999L10.6 11.9999L5.69999 16.8999C5.29999 17.2999 5.29999 17.8999 5.69999 18.2999C6.09999 18.6999 6.69999 18.6999 7.09999 18.2999L12 13.3999L16.9 18.2999C17.3 18.6999 17.9 18.6999 18.3 18.2999C18.7 17.8999 18.7 17.2999 18.3 16.8999L13.4 11.9999L18.3 7.0999C18.7 6.6999 18.7 6.0999 18.3 5.6999Z" fill="#34515E" />
                            </svg>
                        </div>
                    )}
                </>
            )}
            {!header && closeIcon && (
                <div className="modal-header-close" onClick={onClose}>
                    <Icon name="close" color="#717D88" />
                </div>
            )}
            <div className="modal-content-box">{children}</div>
            {footer && (
                <div
                    className={cx("modal-footer", lines && 'lines')}
                    style={footerHeignt && { height: footerHeignt + "px" }}
                >
                    {secondButton && (
                        <div
                            className="second-button"
                            onClick={handleSecondButtonClick}
                        >
                            Skip
                        </div>
                    )}
                    <div className="modal-footer-button">
                        <Button
                            name={buttonText}
                            type={buttonType}
                            handleButtonClick={handleButtonClick}
                            disabled={disabled}
                            style={{ padding: "0 30px", ...buttonStyles }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

ModalContent.defaultProps = {
    styles: {},
    onClose: () => { },
    handleButtonClick: () => { },
    handleSecondButtonClick: () => { }
};

ModalContent.propTypes = {
    children: PropTypes.element,
    styles: PropTypes.object,
    onClose: PropTypes.func,
    handleButtonClick: PropTypes.func,
    handleSecondButtonClick: PropTypes.func
};

export default memo(ModalContent);