import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";


const timePeriodList = [
        { id: "%RFdTP-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "%RFdTP-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "%RFdTP-L01p", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "%RFdTP-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "%RFdTP-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;

