import React from "react";
import Modal from "components/modules/modal";
import Button from "components/elements/button";
import "./style.scss";

function BlockModal({ onClickSuppor, onClickActivate, mailto }) {
  return (
    <Modal
      styles={{
        width: 500,
        closeIcon: false
      }}
      darkBackground
    >
      <div className="block-modal">
        <div className='header'>
          <p>We’re Sorry But Your Account Was Cancelled</p>
        </div>
        <div>
          <div className='title'>
            <p>If you did not cancel the account yourself, then it’s highly likely that your credit card failed after a few attempts.
              But no worries, you can restore your account below.</p>
          </div>
          <br />
          <div className='title'>
            {/* <p>Your current period end in .</p> */}
          </div>
        </div>
        <div className='footer'>
          <Button
            name="Reactive account"
            style={{
              // background: "#F21414",
              // color: "#ffffff",
              width: 200,
              height: 40,
            }}
            type={3}
            handleButtonClick={() => onClickActivate()}
          />
          {mailto ?
            <a href="mailto:support@originreports.org" style={{ textDecoration: "none" }}>
              <Button
                name="Support"
                style={{
                  // background: "#F21414",
                  // color: "#ffffff",
                  width: 250,
                  height: 40,
                }}
                type={2}
                handleButtonClick={() => {}}
              />
            </a>

            :
            <Button
              name="Support"
              style={{
                // background: "#F21414",
                // color: "#ffffff",
                width: 250,
                height: 40,
              }}
              type={2}
              handleButtonClick={() => onClickSuppor()}
            />}

        </div>
      </div>
    </Modal>
  )
}

export default BlockModal;
