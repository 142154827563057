import timePeriodList from "./time-period";
import countriesList from "./countries";
import singleCountryList from "./single-country";
import continentsList from "./continents";
import manuscriptTypesList from "./manuscript-types";
import sectionCategoryTypesList from './section-category';
import revisionNumberList from "./revision-numbers";
import primaryCategoryList from "./primary-category";

export default [...timePeriodList, ...countriesList, ...singleCountryList, ...continentsList, ...manuscriptTypesList, ...sectionCategoryTypesList, ...revisionNumberList, ...primaryCategoryList];
