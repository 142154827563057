import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const revNumByAssociateEditorList = [
    { id: "TCDLAER#-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE" },
    { id: "TCDLAER#-T01r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TCDLAER#-T04r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TCDLAER#-CC05r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "MEDIAN" },
    { id: "TCDLAER#-T05r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
    { id: "TCDLAER#-T08r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
    { id: "TCDLAER#-T20r", name: "simple-bar", chartImage: table, disabled: false, description: "" },
];

export default revNumByAssociateEditorList;