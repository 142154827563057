import * as actions from "store/actionTypes";
import chartsSidebarConfig from "config/charts/sidebar.json";
import _ from "lodash"

const dropdownValues = [
    { key: "Roboto", value: 1 },
    { key: "Work Sans", value: 2 },
    { key: "IBM Plex Sans", value: 3 },
    { key: "Space Mono", value: 4 },
    { key: "Libre Franklin", value: 5 },
    { key: "Rubik", value: 6 },
    { key: "Cormorant", value: 7 },
    { key: "Fira Sans", value: 8 },
    { key: "Eczar", value: 9 },
    { key: "Alegreya Sans", value: 10 },
    { key: "Alegreya", value: 11 },
];

const defaultState = {
    sidebarConfig: {
        formatOptions: {
            "Chart titles": true,
            "Axis titles": true,
            "Date format": true,
            "Legend font": true,
            "Data series": true,
            "Colors": true
        }
    },
    dropdownValues,
    chartTitles: {
        alignment: "center",
        secondaryAlignment: "center",
        mainTitle: "Submissions",
        titleOptions: {
            dropdownOpen: false,
            dropdownValue: { key: "Roboto", value: 1 },
            bold: false,
            italic: false,
            underline: false,
            colorOpen: false,
            color: "black",
            customColors: {
                1: "transparent",
                2: "transparent",
                3: "transparent",
                4: "transparent",
                5: "transparent",
                6: "transparent"
            }
        },
        secondaryTitle: "Simple bar chart - Total Submissions",
        secondaryTitleOptions: {
            dropdownOpen: false,
            dropdownValue: { key: "Roboto", value: 1 },
            bold: false,
            italic: false,
            underline: false,
            colorOpen: false,
            color: "darkgray",
            customColors: {
                1: "transparent",
                2: "transparent",
                3: "transparent",
                4: "transparent",
                5: "transparent",
                6: "transparent"
            }
        },
        manualChangedAlignment: false,
        manualChangedTitles: false,
        thirdAlignment: "center",
    },
    axisTitles: {
        alignment: "center",
        xAxisTitle: "Total submissions",
        yAxisTitle: "Total submissions",
        options: {
            dropdownOpen: false,
            dropdownValue: { key: "Roboto", value: 1 },
            bold: false,
            italic: false,
            underline: false,
            colorOpen: false,
            color: "black",
            customColors: {
                1: "transparent",
                2: "transparent",
                3: "transparent",
                4: "transparent",
                5: "transparent",
                6: "transparent"
            }
        },
        yAxisValue: 0,
        manualChangedAlignment: false,
        manualChangedAxis: false
    },
    dateFormat: {
        showRange: true,
        dropdownOpen: false,
        isCustomSelected: false,
        date_format: { key: 'MMM DD, YYYY', value: '%b %d, %Y' },
        options: {
            dropdownOpen: false,
            dropdownValue: { key: "Roboto", value: 1 },
            bold: false,
            italic: false,
            underline: false,
            colorOpen: false,
            color: "darkgray",
            customColors: {
                1: "transparent",
                2: "transparent",
                3: "transparent",
                4: "transparent",
                5: "transparent",
                6: "transparent"
            }
        },
    },
    legendFont: {
        placementValue: 0,
        options: {
            dropdownOpen: false,
            dropdownValue: { key: "Roboto", value: 1 },
            bold: false,
            italic: false,
            underline: false,
            colorOpen: false,
            color: "black",
            customColors: {
                1: "transparent",
                2: "transparent",
                3: "transparent",
                4: "transparent",
                5: "transparent",
                6: "transparent"
            }
        },
    },
    labelsAndSorting: {
        showTotals: false,
        showSegmentTotals: false,
        showSingleActualValue: false,
        showSingleTotalPercantage: false,
        labelColor: 'black',
        bgColor: 'lightgray',
    },
    colors: {
        customColorsPush: true,
        customColorsItems: [],
        dataSeriesIDsColors: [],
        dataSeriesColors: [],
        checkedPaletteNumber: 0,
        isCustumColorsChange: false,
        allowChange: false,
        customColors: {
            1: "transparent",
            2: "transparent",
            3: "transparent",
            4: "transparent",
            5: "transparent",
            6: "transparent"
        },
        dataColor: "black",
        backgroundColor: "#FFFFFF",
        gridlineColor: "#EAEDF0"
    },
    chartZoomedCoefficient: 1,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.DATA_SERIES_COLORS_IDS_CHANGE_RESET: {
            const newIdArrReset = []
            return {
                ...state,
                colors: { ...state.colors, dataSeriesIDsColors: newIdArrReset }
            };
        }
        case actions.DATA_SERIES_COLORS_IDS_CHANGE:
            const { name, newName } = action.payload;
            const newIdArr = state.colors.dataSeriesIDsColors.map(item => {
                if (String(item.id) === String(name)) {
                    return {
                        ...item,
                        name: newName
                    }

                }
                return item
            })
            return {
                ...state,
                colors: { ...state.colors, dataSeriesIDsColors: newIdArr }
            };
        case actions.THIRD_ALIGNMENT_VALUE_CHANGE:
            return {
                ...state,
                chartTitles: { ...state.chartTitles, thirdAlignment: action.value }
            };
        case actions.GENERATE_FROM_FAVORITE:
            const colors = { ...action.payload.item.formatOptions.colors };
            if (!colors.dataSeriesIDsColors) {
                colors.dataSeriesIDsColors = []
            }

            return {
                ...action.payload.item.formatOptions,
                colors,
                editChart: true
            };
        case actions.CHANGE_ZOOM_COEFFICIENT:
            return {
                ...state,
                chartZoomedCoefficient: action.value
            };
        case actions.SECONDATY_ALIGNMENT_VALUE_CHANGE:
            return {
                ...state,
                chartTitles: { ...state.chartTitles, secondaryAlignment: action.value }
            };
        case actions.ALIGNMENT_VALUE_CHANGE:
            return {
                ...state,
                [action.alignmentType]: { ...state[action.alignmentType], alignment: action.value }
            };
        case actions.INPUT_CHANGE:
            return {
                ...state,
                [action.sectionName]: { ...state[action.sectionName], [action.inputType]: action.value }
            };
        case actions.MANUAL_CHANGE:
            return {
                ...state,
                [action.sectionName]: { ...state[action.sectionName], [action.changeType]: true }
            };
        case actions.DROPDOWN_ACION_CHANGE:
            const { sectionName, dropdownType } = action;
            return {
                ...state,
                [sectionName]: {
                    ...state[sectionName],
                    [dropdownType]: {
                        ...state[sectionName][dropdownType],
                        dropdownOpen: !state[sectionName][dropdownType]["dropdownOpen"]
                    }
                }
            };
        case actions.DROPDOWN_VALUE_CHANGE:
            return {
                ...state,
                [action.sectionName]: {
                    ...state[action.sectionName],
                    [action.dropdownType]: { ...state[action.sectionName][action.dropdownType], dropdownValue: action.value }
                }
            };
        case actions.CHECKED_OPTIONS_CHANGE:
            return {
                ...state,
                [action.sectionName]: {
                    ...state[action.sectionName],
                    [action.optionType]: {
                        ...state[action.sectionName][action.optionType],
                        [action.identity]: !state[action.sectionName][action.optionType][action.identity]
                    }
                }
            };
        case actions.CHANGE_COLOR:
            return {
                ...state,
                [action.pickerName]: {
                    ...state[action.pickerName],
                    [action.pickerType]: {
                        ...state[action.pickerName][action.pickerType],
                        color: action.color
                    }
                }
            };
        case actions.PICKER_CLOSE:
            return {
                ...state,
                [action.pickerName]: {
                    ...state[action.pickerName],
                    [action.pickerType]: {
                        ...state[action.pickerName][action.pickerType],
                        colorOpen: !state[action.pickerName][action.pickerType]["colorOpen"],
                        customColors: action.colors
                    }
                }
            };
        case actions.RADIO_VALUE_CHANGE:
            return {
                ...state,
                [action.sectionName]: { ...state[action.sectionName], [action.radioIdentifier]: action.value }
            };
        case actions.HANDLE_SORT_CHANGE:
            return {
                ...state,
                colors: {
                    ...state.colors,
                }
            };
        case actions.HANDLE_SEPERATE_SORT_CHANGE:
            return {
                ...state,
                colors: {
                    ...state.colors,
                }
            };
        case actions.DATE_RANGE_CHANGE:
            return {
                ...state,
                dateFormat: { ...state.dateFormat, showRange: !state.dateFormat.showRange }
            };
        case actions.DATE_DROPDOWN_OPEN:
            return {
                ...state,
                dateFormat: { ...state.dateFormat, dropdownOpen: !state.dateFormat.dropdownOpen }
            };
        case actions.DATE_DROPDOWN_CHANGE:
            return {
                ...state,
                dateFormat: {
                    ...state.dateFormat,
                    date_format: action.value,
                    isCustomSelected: true
                }
            };
        case actions.SHOW_TOTALS:
            return {
                ...state,
                labelsAndSorting: {
                    ...state.labelsAndSorting,
                    showTotals: !state.labelsAndSorting.showTotals
                }
            };
        case actions.CHANGE_LABEL_COLOR:
            let bgColor = ''
            switch (action.payload.color) {
                case 'white': bgColor = 'darkgray'; break;
                case 'black': bgColor = 'lightgray'; break;
                case 'darkgray': bgColor = 'lightgray'; break;
                case 'lightgray': bgColor = 'darkgray'; break;
                default: bgColor = 'lightgray';

            }
            return {
                ...state,
                labelsAndSorting: {
                    ...state.labelsAndSorting,
                    labelColor: action.payload.color,
                    bgColor
                }
            };

        case actions.SHOW_PERCENTAGE:
            return {
                ...state,
                labelsAndSorting: {
                    showTotals: state.labelsAndSorting.showTotals,
                    showSegmentTotals: true,
                    showSingleActualValue: false,
                    showSingleTotalPercantage: true,
                    labelColor: 'black',
                    bgColor: 'lightgray'
                }
            }
        case actions.ITEM_CHECKER: {
            return {
                ...state,
                labelsAndSorting: {
                    showTotals: state.labelsAndSorting.showTotals,
                    showSegmentTotals: true,
                    showSingleActualValue: true,
                    showSingleTotalPercantage: true,
                    labelColor: 'black',
                    bgColor: 'lightgray'
                }
            }
        }
        case actions.SHOW_SEGMENT_TOTALS_AS: {
            return {
                ...state,
                labelsAndSorting: {
                    showTotals: state.labelsAndSorting.showTotals,
                    showSegmentTotals: true,
                    showSingleActualValue: true,
                    showSingleTotalPercantage: false,
                    labelColor: 'black',
                    bgColor: 'lightgray'
                }
            }
        }
        case actions.RESET_CHECKERS: {
            return {
                ...state,
                labelsAndSorting: {
                    showTotals: state.labelsAndSorting.showTotals,
                    showSegmentTotals: false,
                    showSingleActualValue: false,
                    showSingleTotalPercantage: false,
                    labelColor: 'black',
                    bgColor: 'lightgray'
                }
            }
        }

        case actions.CUSTOM_COLORS_CHANGE:
            let newCustomColorsItems = state.colors.customColorsItems?.map(item => {
                action.customColorsData.forEach(elem => {
                    if (item.name === elem.name && item.color !== elem.color) {
                        item.color = elem.color
                    }
                })
                return item
            })
            const newDataSeriesIDsColors = state.colors.dataSeriesIDsColors?.map(item => {
                action.customColorsData.forEach(elem => {
                    if (item.name === elem.name && item.color !== elem.color) {
                        item.color = elem.color
                    }
                })
                return item
            })
            return {
                ...state,
                colors: {
                    ...state.colors,
                    dataSeriesColors: action.customColorsData,
                    isCustumColorsChange: true,
                    customColorsItems: newCustomColorsItems,
                    dataSeriesIDsColors: newDataSeriesIDsColors
                }
            };
        case actions.GRAPH_COLOR_CHANGE:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    [action.graphType]: action.color
                }
            };
        case actions.GENERATE_CHART:

            const wantedReportType = localStorage.getItem("wantedReportType")
            let neededReportType = action.reportType
            if (wantedReportType) {
                neededReportType = +JSON.parse(wantedReportType).value
            }

            let configData = chartsSidebarConfig.find(item => +item.reportType === +neededReportType && item.chartType === action.chartType);
            if (action.chartType !== "SC-M01s" && action.chartType !== "SC-M02s" && action.chartType !== "SC-M01e" && action.chartType !== "SC-M02e" && action.chartType !== "SCT-M01s" && action.chartType !== "SCT-M02s" && action.chartType !== "SCT-M01e" && action.chartType !== "SCT-M02e" && action.chartType !== "TDIAE-BB01" && action.chartType !== "SC-BB01s" && action.chartType !== "SC-BB01e" && action.chartType !== "%RIC-BB01s" && action.chartType !== "%RIC-BB01e" && action.chartType !== "%RIC-M01s" && action.chartType !== "%RIC-M02s" && action.chartType !== "%RIC-M01e" && action.chartType !== "%RIC-M02e" && action.chartType !== "SM-BB01s" && action.chartType !== "SM-BB01e" && action.chartType !== "%AFM-BB01s" && action.chartType !== "%AFM-BB01e") {
                action.paletteGradientColors.sort((a, b) => {
                    return a.name.toString().localeCompare(b.name.toString());
                });
            };

            let { colors: { dataSeriesColors, allowChange, customColorsItems, dataSeriesIDsColors } } = state;
            let newDataSeriesColors = dataSeriesColors;
            let generateCustomColorsItems = _.cloneDeep(customColorsItems);

            const newPaletteGradientColors = action.paletteGradientColors;
            newPaletteGradientColors.forEach(item => {
                const findedItem = state.colors.dataSeriesIDsColors.find(el => el.name === item.name);
                if (findedItem) {
                    item.color = findedItem.color
                }
            })


            if (dataSeriesColors.length === 0 || allowChange) newDataSeriesColors = newPaletteGradientColors;
            if (generateCustomColorsItems && generateCustomColorsItems.length === 0) generateCustomColorsItems = _.cloneDeep(action.paletteGradientColors)
            if (customColorsItems && customColorsItems.length < action.paletteGradientColors.length) {
                generateCustomColorsItems = action.paletteGradientColors.map(item => {
                    customColorsItems.forEach(elem => {
                        if (elem.name === item.name) item = { ...elem }
                    })
                    return item
                })
            }
            if (state.colors.isCustumColorsChange) {
                if (state.colors.dataSeriesColors[0] && newDataSeriesColors[0]) {
                    if (state.editChart) {
                        const dataSeriesColors = state.colors.dataSeriesColors;
                        const newColors = newDataSeriesColors.map(
                            (item) => {
                                const { name } = item;
                                const findItem = dataSeriesColors.find(
                                    (element) => element.name === name
                                );
                                if (findItem) {
                                    return findItem;
                                }
                                return item;
                            }
                        );
                        newDataSeriesColors = newColors;
                    } else {
                        if (state.colors.dataSeriesColors[0].name === newDataSeriesColors[0].name) {
                            // newDataSeriesColors = state.colors.dataSeriesColors;
                            const dataSeriesColors = state.colors.dataSeriesColors;
                            const newColors = newDataSeriesColors.map(
                                (item) => {
                                    const { name } = item;
                                    const findItem = dataSeriesColors.find(
                                        (element) => element.name === name
                                    );
                                    if (findItem) {
                                        return findItem;
                                    }
                                    return item;
                                }
                            );
                            newDataSeriesColors = newColors;
                        }
                    }
                }
            }
            newDataSeriesColors = newDataSeriesColors.map(item => {
                generateCustomColorsItems && generateCustomColorsItems.forEach(elem => {
                    if (item.name === elem.name) item = { ...elem }
                })
                return item
            })
            if (action.paletteGradientColors.length > newDataSeriesColors.length) {
                newDataSeriesColors = action.paletteGradientColors.map(item => {
                    newDataSeriesColors.forEach(elem => {
                        if (item.name === elem.name) item = { ...elem }
                    })
                    return item
                })
            }
            if (!state.dateFormat.isCustomSelected) {
                let radioChooseDateFormat = localStorage.getItem('radioChooseDateFormat')
                if (radioChooseDateFormat === 'DD/MM/YYYY') {
                    state.dateFormat.date_format = { key: "dd/mm/YYYY", value: "%d/%m/%Y" }
                }
                if (radioChooseDateFormat === 'MM/DD/YYYY') {
                    state.dateFormat.date_format = { key: "mm/dd/YYYY", value: "%m/%d/%Y" }
                }
            }
            let newdataSeriesIDsColors = []
            if (dataSeriesIDsColors.length === 0) {
                newdataSeriesIDsColors = newDataSeriesColors.map(item => {
                    return {
                        ...item,
                        id: item.name
                    }
                })
            }
            else {
                newdataSeriesIDsColors = [...dataSeriesIDsColors]
            }
            const newGeneratedColors = []
            if (action?.payload?.calculationResult?.chartColors) {
                Object.entries(action?.payload?.calculationResult?.chartColors)
                    .forEach(([key, value]) => {
                        newGeneratedColors.push({ name: key, color: value })
                    })
                newDataSeriesColors = newGeneratedColors
            }
            return {
                ...state,
                sidebarConfig: { formatOptions: configData?.configs },
                chartTitles: {
                    ...state.chartTitles,
                    mainTitle: action.payload.titles.title,
                    secondaryTitle: action.payload.titles.subtitle
                },
                axisTitles: {
                    ...state.axisTitles,
                    xAxisTitle: action.payload.titles.x_axis,
                    yAxisTitle: action.payload.titles.y_axis,
                },
                colors: {
                    ...state.colors,
                    dataSeriesColors: newDataSeriesColors,
                    customColorsItems: generateCustomColorsItems,
                    dataSeriesIDsColors: newdataSeriesIDsColors
                }
            };
        case actions.CHANGE_PALETTE_COLORS:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    checkedPaletteNumber: action.checkedPalette,
                    allowChange: true,
                    isCustumColorsChange: false,
                }
            };
        case actions.HANDLE_PALETTE_ACCESS:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    allowChange: true,
                    customColorsPush: true
                }
            };
        case actions.SIDE_BAR_RADIO_CHANGE:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    allowChange: action.allowChange
                }
            };
        case actions.RESET_CHART_OPTIONS:
            return {
                ...defaultState,
                dateFormat: {
                    ...state.dateFormat,
                }
            };
        case actions.RESET_MANUAL_CHANGES:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    isCustumColorsChange: false,
                }
            };
        default:
            return state;
    }
};
