import * as actions from "store/actionTypes";

const defaultState = {
    userData: {},
    statsData: {},
    updateDataError: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.GET_INDIVIDUAL_USER_DATA:
            return {
                ...state,
                userData: action.payload
            };
        case actions.CHANGE_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                updateDataError: {}
            };
        case actions.CHANGE_USER_DATA_ERROR:
            return {
                ...state,
                updateDataError: action.payload
            };
        case actions.CHANGE_USER_EMAIL_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                updateDataError: {}
            };
        case actions.CHANGE_USER_EMAIL_ERROR:
            return {
                ...state,
                updateDataError: action.payload
            };
        case actions.UPLOAD_USER_AVATAR_SUCCESS:
            return {
                ...state,
                userData: action.payload,
            };
        case actions.BLOCK_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    status: "0"
                },
            };
        case actions.CHANGE_USER_ACCOUNT_TYPE: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    accountType: "unlimited"
                },
            };
        }
        case actions.CHANGE_USER_ACCOUNT_TYPE_TO_FREE: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    accountType: "free"
                },
            };
        }
        case actions.DELETE_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                userData: {},
            };
        case actions.GET_STATS_DATA:
            return {
                ...state,
                statsData: action.payload,
            };
        default:
            return state;
    }
};
