const quartersData = {
    id: 1,
    title: "Most recent",
    list: [
        { key: "1 quarter", value: 1 },
        { key: "2 quarters", value: 2 },
        { key: "3 quarters", value: 3 },
        { key: "4 quarters", value: 4 },
        { key: "5 quarters", value: 5 },
        { key: "6 quarters", value: 6 },
        { key: "7 quarters", value: 7 },
        { key: "8 quarters", value: 8 },
        { key: "9 quarters", value: 9 },
        { key: "10 quarters", value: 10 },
        { key: "11 quarters", value: 11 },
        { key: "12 quarters", value: 12 },
        { key: "13 quarters", value: 13 },
        { key: "14 quarters", value: 14 },
        { key: "15 quarters", value: 15 },
        { key: "16 quarters", value: 16 }
    ]
};
const monthsData = {
    id: 2,
    title: "Most recent",
    list: [
        { key: "1 month", value: 1 },
        { key: "2 months", value: 2 },
        { key: "3 months", value: 3 },
        { key: "4 months", value: 4 },
        { key: "5 months", value: 5 },
        { key: "6 months", value: 6 },
        { key: "7 months", value: 7 },
        { key: "8 months", value: 8 },
        { key: "9 months", value: 9 },
        { key: "10 months", value: 10 },
        { key: "11 months", value: 11 },
        { key: "12 months", value: 12 },
        { key: "13 months", value: 13 },
        { key: "14 months", value: 14 },
        { key: "15 months", value: 15 },
        { key: "16 months", value: 16 },
        { key: "17 months", value: 17 },
        { key: "18 months", value: 18 },
        { key: "19 months", value: 19 },
        { key: "20 months", value: 20 },
        { key: "21 months", value: 21 },
        { key: "22 months", value: 22 },
        { key: "23 months", value: 23 },
        { key: "24 months", value: 24 }
    ]
};

export { quartersData, monthsData };
