//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
        // Time period - None
        { id: "TDFATP-L01s", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "TDFATP-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TDFATP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDFATP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDFATP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "TDFATP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
];

export default timePeriodList;
