import React from "react";
import PropTypes from "prop-types";
import Navbar from "components/modules/navbar";
import Leftbar from "components/modules/leftbar";
// import Footer from "components/modules/main-footer";
import * as api from "api";
// import Intercom from "utils/intercom";
import "./style.scss";
import axios from "axios";
import BlockModal from "./block-modal";
import ShowMessageWhenClickingOnStandardReports from "../../../views/user/reports/elements/show-message-when-clicking-on-standard-reports";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AccountNavbar from "../account-navbar/accountNavbar";

const Layout = ({
  showMessage,
  menuList,
  title,
  children,
  showCookiePopup,
  changeCookiePolicyPromptAction,
  ...rest
}) => {
  const {
    auth: { userData: { user: { accountType, demoUser } = {} } = {} } = {},
    location: { pathname },
  } = rest;
  const location = useLocation()
  const arr = ['my-account', 'myteam', 'myteam-all-reports',  'organization-settings', 'change-organization', 'subscription', 'my-organization', 'my-preferences', 'terms-of-use']
  const logout = () => {
    // rest.history.push("/login");
    // Intercom.logout();
    localStorage.removeItem("user_token");
    sessionStorage.removeItem("user_token")
    localStorage.removeItem("role");
    localStorage.removeItem("manuscriptChecked");
    localStorage.removeItem("dont-show-too-much-data-modal");
    if (!demoUser) {
      api.userLogoutActivity();
    } else {
      axios.defaults.headers.common["Access-For-Demo-User"] = "";
    }
    axios.defaults.headers.common["Authorization"] = "";
  };

  const logoutFromLeftbar = () => {
      rest.history.push("/login");
      // Intercom.logout();
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_token")
      localStorage.removeItem("role");
      localStorage.removeItem("manuscriptChecked");
      localStorage.removeItem("dont-show-too-much-data-modal");
      if (!demoUser) {
        api.userLogoutActivity();
      } else {
        axios.defaults.headers.common["Access-For-Demo-User"] = "";
      }
      axios.defaults.headers.common["Authorization"] = "";
  }

  let hideBlockModal = false;
  if (pathname === "/subscription" || pathname === "/my-account") {
    hideBlockModal = true;
  }
  return (
    <div>
      {title === "MyReports" &&
        !localStorage.getItem("ShowMessageWhenClickingOnStandardReports") && (
          <ShowMessageWhenClickingOnStandardReports />
        )}
      <div
        className="new-design-leftbar-content"
        style={{ height: !demoUser ? "100vh" : "1px" }}
      >
        {demoUser && showMessage && (
          <div
            className="demo-user-message"
            onClick={() => {
              if (demoUser) {
                localStorage.removeItem("user_token");
                sessionStorage.removeItem("user_token")
                localStorage.removeItem("role");
                localStorage.removeItem("dont-show-too-much-data-modal");
                sessionStorage.clear();
                localStorage.removeItem("fileCsv");
                localStorage.removeItem("fileCsv2");
                localStorage.removeItem("manuscriptChecked");
                axios.defaults.headers.common["Access-For-Demo-User"] = "";
                rest.history.push("/registration");
              } else {
                rest.history.push("/subscription");
              }
            }}
          >
            <p>
              Want to work with your own data? Click here to create a
              subscription.
            </p>
          </div>
        )}
        <Navbar
          demoUser={demoUser}
          title={title}
          handleLogOut={logout}
          showCookiePopup={showCookiePopup}
          changeCookiePolicyPromptAction={changeCookiePolicyPromptAction}
          {...rest}
        />
        <Leftbar
          demoUser={demoUser}
          accountType={accountType}
          menuList={menuList}
          handleLogOut={logoutFromLeftbar}
          {...rest}
        />
        {arr.some(element => location.pathname.split('/').includes(element)) && <AccountNavbar
        demoUser={demoUser}
        title={title}
        handleLogOut={logout}
        showCookiePopup={showCookiePopup}
        changeCookiePolicyPromptAction={changeCookiePolicyPromptAction}
        {...rest} />}
      </div>
      <div className={`main-layout-content ${arr.some(element => location.pathname.split('/').includes(element)) && "plus-padding"}`}>
        <div className="new-design-navbar-content-container">
          {/* {(demoUser) && showMessage && (
                        <div className="demo-user-message" onClick={() => {
                            if (demoUser) {
                                localStorage.removeItem("user_token");
                                sessionStorage.removeItem("user_token")
                                localStorage.removeItem("role");
                                localStorage.removeItem("dont-show-too-much-data-modal");
                                axios.defaults.headers.common["Access-For-Demo-User"] = "";
                                rest.history.push("/registration");
                            } else {
                                rest.history.push("/subscription");
                            }
                        }}>
                            <p>
                                Want to work with your own data? Click here to create a subscription.
                            </p>
                        </div>
                    )} */}
          {accountType === "free" && !hideBlockModal && !demoUser && (
            <BlockModal
              onClickActivate={() => {
                rest.history.push("/subscription");
              }}
              onClickSuppor={() => {
                // Intercom.open();
              }}
              mailto={true}
            />
          )}
          {children}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

Layout.defaultProps = {
  menuList: [],
};

Layout.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
  menuList: PropTypes.array,
  showCookiePopup: PropTypes.func,
};

export default Layout;
