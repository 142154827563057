import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";

const stageNameList = [
    { id: "TARR#SN-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE" },
    { id: "TARR#SN-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV" },
    { id: "TARR#SN-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV" },
    { id: "TARR#SN-CC05r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "MEDIAN" },
    { id: "TARR#SN-BXV05r", name: "box", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
    { id: "TARR#SN-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
    { id: "TARR#SN-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
    { id: "TARR#SN-T20r", name: "table", chartImage: table, disabled: false, description: "" },
];

export default stageNameList;