
import lineLineChart from "assets/images/chart-images/line-chart.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";
import chart4Items from "assets/images/chart-images/donut.png";
import DonutChart from "assets/images/chart-images/donut.png";

const timePeriodList = [
    { id: "SQCTP-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SQCTP-BV02p", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
    { id: "SQCTP-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SQCTP-L01p", name: "line", chartImage: lineLineChart, disabled: false },
    { id: "SQCTP-T01p", name: "table", chartImage: table, disabled: false },
    { id: "SQCTP-T02p", name: "table", chartImage: table, disabled: false },

    { id: "SQCTPPC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPPC-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPPC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

    { id: "SQCTPM-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPM-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPM-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPM-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPM-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },


    { id: "SQCTPC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPC-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SQCC-BV01p", name: 'table', chartImage: barSimpleBarChart, disabled: false },
    { id: "SQCC-BH01p", name: 'table', chartImage: barHorizontalBarChart, disabled: false },
    { id: "SQCC-D01p", name: 'table', chartImage: chart4Items, disabled: false },
    { id: "SQCC-D02p", name: 'table', chartImage: chart4Items, disabled: false },
    { id: "SQCC-M01p", name: 'table', chartImage: mapWorldMap, disabled: false },
    { id: "SQCC-M02p", name: 'table', chartImage: mapWorldMap, disabled: false },
    { id: "SQCC-T01p", name: 'table', chartImage: table, v2: "Column", disabled: false },
    { id: "SQCC-T01Bp", name: 'table', chartImage: table, v2: "Column", disabled: false },
    { id: "SQCC-T02p", name: 'table', chartImage: table, v2: "Row", disabled: false },
    { id: "SQCC-T02Bp", name: 'table', chartImage: table, v2: "Row", disabled: false },
    { id: "SQCTPCT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPCT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPCT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPCT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPCT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SQCCTC-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SQCCTC-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SQCCTC-D01p", name: "donut", chartImage: DonutChart, disabled: false },
    { id: "SQCCTC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCCTC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

    { id: "SQCCT-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SQCCT-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SQCCT-D01p", name: "donut", chartImage: DonutChart, disabled: false },
    { id: "SQCCT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCCT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SQCPC-BV01p", name: "stacked-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SQCPC-BH01p", name: "horizontal-stacked-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SQCPC-D01p", name: "donut", chartImage: DonutChart, disabled: false },
    { id: "SQCPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SQCTPR#-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPR#-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPR#-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPR#-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPR#-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

    { id: "SQCTPCTC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SQCTPCTC-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SQCTPCTC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SQCTPCTC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SQCTPCTC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;