import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./style.scss";
import Icon from "components/elements/icon";

const Leftbar = ({ menuList, handleLogOut, accountType, demoUser }) => {
    return (
        <div className='super-admin-leftbar' style={{ height: demoUser ? '87vh' : '95vh' }}>
            <div className='super-admin-leftbar-menu-box'>
                {menuList.map((el, index) => {
                    let spaceForTooltip = '';
                    if (el.title[0] === 'M') {
                        spaceForTooltip = el.title.substring(0, 2) + ' ' + el.title.substring(2);
                    }
                    return (
                        <Fragment key={index}>
                            {el.path || el.path === "/mycharts/submissions" || el.path === "/charts-tables" ? (
                                <NavLink
                                    exact
                                    to={`${demoUser ? "/demo" : ""}${el.path}`}
                                    activeClassName='activeMenuItem'
                                >
                                    <div className="menu-item">
                                        <Icon name={el.icon} />
                                        <span className="tooltip">{spaceForTooltip ? spaceForTooltip : el.title}</span>
                                    </div>
                                </NavLink>
                            ) : !demoUser && (
                                <div className="menu-item disabled">
                                    <Icon name={el.icon} />
                                    <span className="tooltip">{spaceForTooltip}</span>
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </div>
            <div className='super-admin-leftbar-bottom'>
                {/* <div className='super-admin-leftbar-log-out'>
                    <a href='http://help.originreports.org' target="_blank" rel="noopener noreferrer">
                        <Icon name='help-center' color='#717D88' />
                        <span className="tooltip">Help Center</span>
                    </a>
                </div> */}
                {/* <div className='super-admin-leftbar-log-out' onClick={Intercom.showNewMessage}>
                    <Icon name='support' color='#717D88' />
                    <span className="tooltip">Support chat</span>
                </div> */}
<               div className='super-admin-leftbar-log-out'>
                    <Icon 
                        name='log-out' 
                        color='#717D88' 
                        onMouseOver={(e) => e.target.style.color = '#8bc34a'} 
                        onMouseOut={(e) => e.target.style.color = '#717D88'} 
                        onClick={handleLogOut} />
                    <span className="tooltip">Logout</span>
                </div>
            </div>
        </div>
    );
};

Leftbar.defaultProps = {
    menuList: [],
    handleLogOut: () => { }
};

Leftbar.propTypes = {
    menuList: PropTypes.array,
    handleLogOut: PropTypes.func
};

export default Leftbar;
