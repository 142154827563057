class TypeCheck {
    static isArray(input) {
        return Object.prototype.toString.call(input) === "[object Array]";
    }

    static isObject(input) {
        return Object.prototype.toString.call(input) === "[object Object]";
    }

    static isEmpty(input) {
        if (this.isArray(input)) {
            return input.length === 0;
        } else if (this.isObject(input)) {
            return Object.keys(input).length === 0;
        } else {
            console.error("Wrong type");
        }
    }
}

export default TypeCheck;
