//lines
import lineMultiLine from "assets/images/chart-images/multi-line.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";
import lineWedge from "assets/images/chart-images/wedge.png";
//bar
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";
import DonutChart from "assets/images/chart-images/donut.png";
//chart
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
        // time period charts
        { id: "STP-L01s", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "STP-L01e", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "STP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "STP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "STP-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "STP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "STP-BV02s", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "STP-BV02e", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },

        { id: "STP-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "STP-L01p", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "STP-T01p", name: "table", chartImage: table, disabled: false },
        { id: "STP-T02p", name: "table", chartImage: table, disabled: false },
        
        { id: "STP-BV02p", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "STP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STP-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STP-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STP-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "STP-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STP-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STP-T20r", name: "table", chartImage: table, disabled: false },

        // time period - countries charts
        { id: "STPC-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPC-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "SCT-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "SCT-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "SCT-D01p", name: "donut", chartImage: DonutChart, disabled: false },
        { id: "SCT-T01p", name: "table", chartImage: table, disabled: false },
        { id: "SCT-T02p", name: "table", chartImage: table, disabled: false },
        { id: "SCTC-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "SCTC-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "SCTC-D01p", name: "donut", chartImage: DonutChart, disabled: false },
        { id: "SCTC-T01p", name: "table", chartImage: table, disabled: false },
        { id: "SCTC-T02p", name: "table", chartImage: table, disabled: false },

        { id: "STPR#-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPR#-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPR#-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPR#-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPR#-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "STPC-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPC-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPC-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPC-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPC-BH01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPC-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPC-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        // { id: 515, name: "100%-horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: true },
        { id: "STPC-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPC-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPC-W01s", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPC-W01e", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "STPC-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "STPC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPC-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },

        // time period - continents charts
        { id: "STPCT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPCT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPCT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPCT-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPCT-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPCT-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPCT-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPCT-BH01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPCT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },

        { id: "STPCT-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPCT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPCT-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPCT-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPCT-W01s", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPCT-W01e", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPCT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "STPCT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "STPCT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPCT-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPCT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPCT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "STPCTC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPCTC-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPCTC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPCTC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPCTC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        // time period - manuscript types charts
        { id: "STPM-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPM-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPM-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPM-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPM-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "STPM-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPM-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPM-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPM-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPM-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPM-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPM-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPM-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "STPM-W01s", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPM-W01e", name: "wedge", chartImage: lineWedge, disabled: false },
        { id: "STPM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPM-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "STPM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPM-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPM-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPM-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        // time period - revision number
        { id: "STPR#-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPR#-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPR#-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "STPR#-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE"  },
        { id: "STPR#-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPR#-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPR#-BVS01r", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPR#-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPR#-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPR#-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPR#-T20r", name: "table", chartImage: table, disabled: false },

        // time period - section category
        { id: "STPSC-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPSC-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPSC-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPSC-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPSC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPSC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPSC-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "STPPC-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "STPPC-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "STPPC-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "STPPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "STPPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;
