import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";
const associateEditors = [
        { id: "TCSLAE-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        // { id: "TCSLAE-BV02r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        // { id: "TCSLAE-BV03r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TCSLAE-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        // { id: "TCSLAE-T01r", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        // { id: "TCSLAE-T02r", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        { id: "TCSLAE-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TCSLAE-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TCSLAE-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        // { id: "TCSLAE-BV06r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        // { id: "TCSLAE-BV07r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TCSLAE-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TCSLAE-BXV05r", name: "simple-bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
        { id: "TCSLAE-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TCSLAE-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
        { id: "TCSLAE-T20r", name: "table", chartImage: table, disabled: false, description: " " },
];

export default associateEditors;