//chart
import Histogram from "assets/images/chart-images/histogram.png";

const initialDecisionCompleted = [
    // Time from editor assignment to first reviewer invited-none
    { id: "#ETDIL-H01e", name: "histogram", chartImage: Histogram, disabled: false },
    { id: "#ETDIL-H01s", name: "histogram", chartImage: Histogram, disabled: false },
    
];

export default initialDecisionCompleted;
