// AUTH
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const ME = 'ME';
export const LOGOUT = 'LOGOUT';
export const CONFIRM_NEW_EMAIL = 'CONFIRM_NEW_EMAIL';
export const UPDATE_USER_CHARTS_STATS = 'UPDATE_USER_CHARTS_STATS';
export const CHANGE_USER_ACCOUNT_TYPE = "CHANGE_USER_ACCOUNT_TYPE";
export const CHANGE_USER_ACCOUNT_TYPE_TO_FREE = "CHANGE_USER_ACCOUNT_TYPE_TO_FREE";
export const CHANGE_AUTOMATION_DATA = "CHANGE_AUTOMATION_DATA"
export const CHANGE_AUTOMATION_DATA_LOADER = "CHANGE_AUTOMATION_DATA_LOADER"
export const GET_AUTOMATION_DATA = "GET_AUTOMATION_DATA"
export const SITE_ACOUNTS = "SITE_ACOUNTS"
// SUPER_ADMIN
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';

export const GET_INDIVIDUALS_REQUEST = 'GET_INDIVIDUALS_REQUEST';
export const GET_INDIVIDUALS_SUCCESS = 'GET_INDIVIDUALS_SUCCESS';
export const GET_INDIVIDUALS_ERROR = 'GET_INDIVIDUALS_ERROR';

export const ADD_INDIVIDUAL_USER_SUCCESS = 'ADD_INDIVIDUAL_USER_SUCCESS';
export const ADD_INDIVIDUAL_USER_ERROR = 'ADD_INDIVIDUAL_USER_ERROR';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const SEARCH_INDIVIDUAL_USERS_SUCCESS = 'SEARCH_INDIVIDUAL_USERS_SUCCESS';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_ERROR = 'ADD_ORGANIZATION_ERROR';
export const SEARCH_ORGANIZATIONS_SUCCESS = 'SEARCH_ORGANIZATIONS_SUCCESS';
export const LOGIN_AS_THIS_USER = 'LOGIN_AS_THIS_USER';
export const CHANGE_USER_DATA_SUCCESS = 'CHANGE_USER_DATA_SUCCESS';
export const CHANGE_USER_DATA_ERROR = 'CHANGE_USER_DATA_ERROR';
export const ORGANIZATION = 'ORGANIZATION';
export const CHANGE_ORGANIZATION_DATA_SUCCESS = 'CHANGE_ORGANIZATION_DATA_SUCCESS';
export const CHANGE_ORGANIZATION_DATA_ERROR = 'CHANGE_ORGANIZATION_DATA_ERROR';
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS';
export const UPLOAD_ORGANIZATION_AVATAR_SUCCESS = 'UPLOAD_ORGANIZATION_AVATAR_SUCCESS';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';
export const CHANGE_USER_EMAIL_ERROR = 'CHANGE_USER_EMAIL_ERROR';
export const BLOCK_USER_ACCOUNT_SUCCESS = 'BLOCK_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const ORGANIZATION_JOURNALS = 'ORGANIZATION_JOURNALS';
export const ADD_ORGANIZATION_SINGLE_JOURNAL = 'ADD_ORGANIZATION_SINGLE_JOURNAL';
export const ADD_ORGANIZATION_SINGLE_JOURNAL_ERROR = 'ADD_ORGANIZATION_SINGLE_JOURNAL_ERROR';
export const DELELTE_SINGLE_ORGANIZATION = 'DELELTE_SINGLE_ORGANIZATION';
export const UPDATE_ORGANIZATION_JOURNAL = 'UPDATE_ORGANIZATION_JOURNAL';
export const UPDATE_ORGANIZATION_JOURNAL_ERROR = 'UPDATE_ORGANIZATION_JOURNAL_ERROR';
export const GET_STATS_DATA = 'GET_STATS_DATA';
export const GET_INDIVIDUAL_USER_DATA = 'GET_INDIVIDUAL_USER_DATA';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const GET_USER_ACTIVITIES_REQUEST = 'GET_USER_ACTIVITIES_REQUEST';
export const GET_USER_ACTIVITIES_SUCCESS = 'GET_USER_ACTIVITIES_SUCCESS';
export const GET_USER_ACTIVITIES_ERROR = 'GET_USER_ACTIVITIES_ERROR';

export const GET_USER_ACTIVITIES_INFINITY_SCROLL = 'GET_USER_ACTIVITIES_INFINITY_SCROLL';
// CHARTS
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR';
export const SET_UPLOAD_PERCENTAGE = 'SET_UPLOAD_PERCENTAGE';
export const UPLOAD_CSV_CHANGE_DATA = 'UPLOAD_CSV_CHANGE_DATA';
export const CHOOSE_CHART_SYSTEM = 'CHOOSE_CHART_SYSTEM';
export const GENERATE_CHART = 'GENERATE_CHART';
export const SELECT_CHART = 'SELECT_CHART';
export const HANDLE_PALETTE_ACCESS = 'HANDLE_PALETTE_ACCESS';
export const SELECT_COUNTRY_TYPE = 'SELECT_COUNTRY_TYPE';
export const HANDLE_CHOOSE_COUNTRY = 'HANDLE_CHOOSE_COUNTRY';
export const HANDLE_MAP_BAR_CHART = 'HANDLE_MAP_BAR_CHART';
export const RESET_CHART_OPTIONS = 'RESET_CHART_OPTIONS';
export const RESET_VARIABLES_LIST = 'RESET_VARIABLES_LIST';
export const HANDLE_ACCEPT_AND_REJECT_COUNT = 'HANDLE_ACCEPT_AND_REJECT_COUNT';
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const CHANGE_QUICK_CHART = 'CHANGE_QUICK_CHART';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_SELECT_OTHER_CHART_MODAL = 'SHOW_SELECT_OTHER_CHART_MODAL';
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const TEAMMATE_REPORT_PERRMISSIONS ="TEAMMATE_REPORT_PERRMISSIONS"
export const CHANGE_PERMISSIONS_TEAMMATE = "CHANGE_PERMISSIONS_TEAMMATE"
// SIDEBAR DATA-SELECTION
export const HISTOGRAM_EDITORS_FILTER = "HISTOGRAM_EDITORS_FILTER"
export const AUTO_INCLUDE_SIDE_BAR_RADIO_CHANGE = "AUTO_INCLUDE_SIDE_BAR_RADIO_CHANGE";
export const SIDE_BAR_RADIO_CHANGE = 'SIDE_BAR_RADIO_CHANGE';
export const CHANGE_CUSTOM_DATE_RANGE = "CHANGE_CUSTOM_DATE_RANGE";
export const SIDE_BAR_HANDLE_FOCUS = 'SIDE_BAR_HANDLE_FOCUS';
export const SIDE_BAR_HANDLE_SEARCH = 'SIDE_BAR_HANDLE_SEARCH';
export const SIDE_BAR_FILTER_EXCLUDE_LIST = 'SIDE_BAR_FILTER_EXCLUDE_LIST';
export const SIDE_BAR_EXCLUDE_CHANGE = 'SIDE_BAR_EXCLUDE_CHANGE';
export const SIDE_BAR_INCLUDE_CHANGE = 'SIDE_BAR_INCLUDE_CHANGE';
export const SIDE_BAR_INCLUDE_CHANGE_RETDIAE = "SIDE_BAR_INCLUDE_CHANGE_RETDIAE"
export const SIDE_BAR_INCLUDE_CHANGE_SECOND = 'SIDE_BAR_INCLUDE_CHANGE_SECOND';
export const SIDE_BAR_DATE_CHANGE = 'SIDE_BAR_DATE_CHANGE';
export const SIDE_BAR_DATE_CHANGE_DROPDOWN = 'SIDE_BAR_DATE_CHANGE_DROPDOWN';
export const CHANGE_PALETTE_COLORS = 'CHANGE_PALETTE_COLORS';
export const SET_DATE_MANUALLY = 'SET_DATE_MANUALLY';
export const CHANGE_GRAPH_DATE_TYPE = 'CHANGE_GRAPH_DATE_TYPE';
export const CHANGE_SEPARATE_SORT_VALUE = 'CHANGE_SEPARATE_SORT_VALUE';
export const SET_SORT_OLDEST_TO_NEWEST = 'SET_SORT_OLDEST_TO_NEWEST';
export const SET_SORT_ALPHABETICAL = 'SET_SORT_ALPHABETICAL';
export const SET_SORT_SEGMENT_DESC = 'SET_SORT_SEGMENT_DESC';
export const SET_SORT_LEGEND_ASC = 'SET_SORT_LEGEND_DESC';
export const SET_SORT_SEGMENT_ZA = 'SET_SORT_SEGMENT_ZA';
export const SET_SORT_DEFAULT = 'SET_SORT_DEFAULT';
export const SET_SORT_SEQUENTIAL = 'SET_SORT_SEQUENTIAL'
export const UPDATE_SELECTED_ITEMS_PREV_SET = 'UPDATE_SELECTED_ITEMS_PREV_SET';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';

// SIDEBAR FORMAT-OPTIONS
export const ALIGNMENT_VALUE_CHANGE = 'ALIGNMENT_VALUE_CHANGE';
export const SECONDATY_ALIGNMENT_VALUE_CHANGE = 'SECONDATY_ALIGNMENT_VALUE_CHANGE';
export const THIRD_ALIGNMENT_VALUE_CHANGE = 'THIRD_ALIGNMENT_VALUE_CHANGE';
export const MANUAL_CHANGE = 'MANUAL_CHANGE';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const DROPDOWN_ACION_CHANGE = 'DROPDOWN_ACION_CHANGE';
export const DROPDOWN_VALUE_CHANGE = 'DROPDOWN_VALUE_CHANGE';
export const CHECKED_OPTIONS_CHANGE = 'CHECKED_OPTIONS_CHANGE';
export const PICKER_CLOSE = 'PICKER_CLOSE';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const RADIO_VALUE_CHANGE = 'RADIO_VALUE_CHANGE';
export const DATE_RANGE_CHANGE = 'DATE_RANGE_CHANGE';
export const DATE_DROPDOWN_OPEN = 'DATE_DROPDOWN_OPEN';
export const DATE_DROPDOWN_CHANGE = 'DATE_DROPDOWN_CHANGE';
export const SHOW_TOTALS = 'SHOW_TOTALS';
export const CHANGE_LABEL_COLOR = "CHANGE_LABEL_COLOR";

//labels and sorting
export const SHOW_SEGMENT_TOTALS_AS = "SHOW_SEGMENT_TOTALS_AS"
export const ITEM_CHECKER = "ITEM_CHECKER" 
export const SHOW_PERCENTAGE = "SHOW_PERCENTAGE" 
export const RESET_CHECKERS = "RESET_CHECKERS" 

export const CUSTOM_COLORS_CHANGE = 'CUSTOM_COLORS_CHANGE';
export const GRAPH_COLOR_CHANGE = 'GRAPH_COLOR_CHANGE';
export const HANDLE_SORT_CHANGE = 'HANDLE_SORT_CHANGE';
export const HANDLE_SEPERATE_SORT_CHANGE = 'HANDLE_SEPERATE_SORT_CHANGE';
export const RESET_MANUAL_CHANGES = 'RESET_MANUAL_CHANGES';
export const CHANGE_ZOOM_COEFFICIENT = 'CHANGE_ZOOM_COEFFICIENT';
//favorites
export const GET_ALL_FAVORITES = 'GET_ALL_FAVORITES';
export const GENERATE_FROM_FAVORITE = 'GENERATE_FROM_FAVORITE';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const WRONG_CSV = 'WRONG_CSV';
export const CHANGE_FAVORITE_TITLES = 'CHANGE_FAVORITE_TITLES';
export const UPDATE_FAVORITE_TITLES = 'UPDATE_FAVORITE_TITLES';
//cookiePolicy
export const COOKIE_POLICY_PROMPT_ACTION = 'COOKIE_POLICY_PROMPT_ACTION';


//teammates
export const GET_MY_TEAMMATES ="GET_MY_TEAMMATES"
export const DELETE_TEAMMATES = "DELETE_TEAMMATES"
export const CHANGE_TEAMMATE_DATA = "CHANGE_TEAMMATE_DATA"
export const GET_INVITE_PERMISSIONS="GET_INVITE_PERMISSIONS"
export const TEAMMATES_LOADER = "TEAMMATES_LOADER"
export const TEAMMATES_PERMISSION_LOADER = "TEAMMATES_PERMISSION_LOADER"
export const GET_REPORT_PERMISSIONS="GET_REPORT_PERMISSIONS"
export const CHANGE_PERMISSIONS_REPORT="CHANGE_PERMISSIONS_REPORT"
//upload scv 
export const CHANGE_APLOADED_FILE_DATA = 'CHANGE_APLOADED_FILE_DATA';

//chart states
export const TIME_FOR_REVIEWERS_TO_SUBMIT_RECOMMENDATIONS_MARKERS = 'TIME_FOR_REVIEWERS_TO_SUBMIT_RECOMMENDATIONS_MARKERS';

export const TOGGLE_GROUP_SELECTION_MODAL = 'TOGGLE_GROUP_SELECTION_MODAL';
export const TOGGLE_UN_GROUP_SELECTION_MODAL = 'TOGGLE_UN_GROUP_SELECTION_MODAL';
export const DATA_CONFIG_NAME_CHANGE = 'DATA_CONFIG_NAME_CHANGE';
export const RESET_GROUPING="RESET_GROUPING"
export const DATA_SERIES_COLORS_IDS_CHANGE_RESET = 'DATA_SERIES_COLORS_IDS_CHANGE_RESET';
export const DATA_SERIES_COLORS_IDS_CHANGE = 'DATA_SERIES_COLORS_IDS_CHANGE';

export const TOGGLE_CONFIG_LOCK = 'TOGGLE_CONFIG_LOCK';

export const SET_INITIAL_DECISION_MODAL_VALUES = 'SET_INITIAL_DECISION_MODAL_VALUES';

export const SET_ALL_REVISION_VALUES = 'SET_ALL_REVISION_VALUES';

export const HANDLE_CHANGE_TABLE_SORTING = 'HANDLE_CHANGE_TABLE_SORTING';
export const HANDLE_INCLUDE_MANUSCRIPT_REVIEWS = 'HANDLE_INCLUDE_MANUSCRIPT_REVIEWS';

// changes the default configuration for STP-BH01s, STP-BH01e, STP-L01s, STP-L01e, STP-T01s, STP-T01e, STP-T02s, STP-T02e, STP-BV01s, STP-BV01e, STP-BV02s and STP-BV02e charts

export const CHANGE_DEFAULT_CONFIGURATION_STP = 'CHANGE_DEFAULT_CONFIGURATION_STP'
export const CHANGE_DATA_ORDER_SORT_VALUE = 'CHANGE_DATA_ORDER_SORT_VALUE'

// My Preferences actions

export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES'
export const GET_PREFERENCES_DATA = 'GET_PREFERENCES_DATA'
export const CHANGE_PREFERENCES_ACCEPT_VALUE = 'CHANGE_PREFERENCES_ACCEPT_VALUE'
export const CHANGE_PREFERENCES_REJECT_VALUE = 'CHANGE_PREFERENCES_REJECT_VALUE'
export const CHANGE_PREFERENCES_ACCEPT_VALUE_ACTION = 'CHANGE_PREFERENCES_ACCEPT_VALUE_ACTION'
export const CHANGE_PREFERENCES_REJECT_VALUE_ACTION = 'CHANGE_PREFERENCES_REJECT_VALUE_ACTION'
export const CHANGE_PREFERENCES_SUBMISSIONS_VALUE = 'CHANGE_PREFERENCES_SUBMISSIONS_VALUE'

export const GET_JOURNAL_NAMES = 'GET_JOURNAL_NAMES'
export const CHANGE_JOURNAL_NAME = "CHANGE_JOURNAL_NAME"
export const REVSION_NUMBER_ALL = "REVSION_NUMBER_ALL"
export const GET_AUTOMATION_REGENERATE_DATA = 'GET_AUTOMATION_REGENERATE_DATA'
export const START_YEAR_CHANGE_REVIEW = 'START_YEAR_CHANGE_REVIEW'