import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
const associateEditors = [
    { id: "TCDLAELS-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, description: "AVERAGE"},
    { id: "TCDLAELS-T01r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE"},
    { id: "TCDLAELS-T02r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE"},
    { id: "TCDLAELS-T05r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN"},
    { id: "TCDLAELS-T06r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN"},
    { id: "TCDLAELS-T20r", name: "simple-bar", chartImage: table, disabled: false, description: ""},
];

export default associateEditors;