//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
//bar
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";
//chart
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
  // Time period - None
  {
    id: "TDITP-BV01e",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-BV05e",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BXV05s",
    name: "simple-bar",
    chartImage: boxChartImage,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BH01e",
    name: "simple-bar",
    chartImage: barHorizontalBarChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-L01e",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-L01s",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-L05s",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BXV01s",
    name: "simple-bar",
    chartImage: boxChartImage,
    disabled: false,
    description: "MEDIAN",
  },

  {
    id: "TDITP-L01p",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
  },
  {
    id: "TDITP-BV01s",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-BV01r",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-BV04r",
    name: "simple-bar",
    chartImage: lineSimpleBarWithCounts,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-L01r",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-T03r",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "AVERAGE • STD DEV",
  },
  {
    id: "TDITP-T04r",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "AVERAGE • STD DEV",
  },

  {
    id: "TDITP-BV05r",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "MEDIAN",
  },
   {
    id: "TDITP-BV08r",
    name: "simple-bar",
    chartImage: lineSimpleBarWithCounts,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-L05r",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BXV05r",
    name: "box-chart",
    chartImage: boxChartImage,
    disabled: false,
    description: "MEDIAN • IQR",
  },
  {
    id: "TDITP-T07r",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "MEDIAN • IQR",
  },
  {
    id: "TDITP-T08r",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "MEDIAN • IQR",
  },
  {
    id: "TDITP-T20r",
    name: "table",
    chartImage: table,
    disabled: false,
    description: "",
  },
  {
    id: "TDITP-BV05s",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BXV05e",
    name: "simple-bar",
    chartImage: boxChartImage,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-BV01p",
    name: "simple-bar",
    chartImage: barSimpleBarChart,
    disabled: false,
  },
  {
    id: "TDITP-BH01s",
    name: "simple-bar",
    chartImage: barHorizontalBarChart,
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-BH01p",
    name: "simple-bar",
    chartImage: barHorizontalBarChart,
    disabled: false,
  },
  {
    id: "TDITP-T01s",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-T03s",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "AVERAGE • STD DEV",
  },
  {
    id: "TDITP-T04s",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "AVERAGE • STD DEV",
  },
  {
    id: "TDITP-T05s",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-T06s",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-T07s",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "MEDIAN • IQR",
  },
  {
    id: "TDITP-T08s",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "MEDIAN • IQR",
  },

  { id: "TDITP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
  { id: "TDITP-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
  {
    id: "TDITP-T03e",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "AVERAGE • STD DEV ",
  },
  { id: "TDITP-T03p", name: "table", chartImage: table, v2: "Column", disabled: false },
  {
    id: "TDITP-T04e",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "AVERAGE • STD DEV ",
  },
  { id: "TDITP-T04p", name: "table", chartImage: table, v2: "Row", disabled: false },
  {
    id: "TDITP-L05e",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
    description: "MEDIAN",
  },
  {
    id: "TDITP-L05p",
    name: "line",
    chartImage: lineLineChart,
    disabled: false,
  },
  {
    id: "TDITP-T02s",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "AVERAGE",
  },
  {
    id: "TDITP-T02e",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "AVERAGE",
  },
  { id: "TDITP-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
  {
    id: "TDITP-T07e",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "MEDIAN • IQR",
  },
  {
    id: "TDITP-T05e",
    name: "table",
    chartImage: table, v2: "Column",
    disabled: false,
    description: "MEDIAN",
  },  {
    id: "TDITP-T06e",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "MEDIAN",
  },
  { id: "TDITP-T05p", name: "table", chartImage: table, v2: "Column", disabled: false },
  {
    id: "TDITP-T08e",
    name: "table",
    chartImage: table, v2: "Row",
    disabled: false,
    description: "MEDIAN • IQR",
  },
  { id: "TDITP-T06p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;
