//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import table from "assets/images/chart-images/table.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";

const editors = [
        { id: "TFLRAE-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-BV02r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-BV03r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-T01r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-T02r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TFLRAE-T03r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TFLRAE-T04r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TFLRAE-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-BV06r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-BV07r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-BXV05r", name: "simple-bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
        { id: "TFLRAE-T05r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-T06r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
        { id: "TFLRAE-T07r", name: "simple-bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TFLRAE-T08r", name: "simple-bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
        { id: "TFLRAE-T20r", name: "simple-bar", chartImage: table, disabled: false },

];

export default editors;
