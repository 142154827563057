import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const timePeriodList = [
  // Time period - None
  { id: "TDFPTP-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE", },
  { id: "TDFPTP-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE", },
  { id: "TDFPTP-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV", },
  { id: "TDFPTP-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV", },
  { id: "TDFPTP-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN", },
  { id: "TDFPTP-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN", },
  { id: "TDFPTP-BXV05r", name: "box", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR", },
  { id: "TDFPTP-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR", },
  { id: "TDFPTP-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR", },
  { id: "TDFPTP-T20r", name: "table", chartImage: table, disabled: false, description: "", },

];

export default timePeriodList;
