
import lineLineChart from "assets/images/chart-images/line-chart.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
        // Time period - None
        {id: "%AITP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%AITP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%AITP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%AITP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%AITP-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%AITP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%AITP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AITP-T01e", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "%AITP-T02s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AITP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false}
];

export default timePeriodList;
