//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";

//bar
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";

//chart
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
        // Time period - None
        {id: "%RITP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%RITP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%RITP-L02e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%RITP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RITP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RIM-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RIM-BH01e", name: "simple-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%RIM-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RIM-BH01s", name: "simple-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%RITP-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%RITP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
        {id: "%RITP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RITP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RITP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "%RITP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "%RIM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RIM-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "%RIM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RIM-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default timePeriodList;
