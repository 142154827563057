//lines
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";
const timePeriodSectionCategory = [
        // Time period - None
        { id: "TDIATPSC-CC01e", name: "bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDIATPSC-T01e", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDIATPSC-T02e", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },

];

export default timePeriodSectionCategory;
