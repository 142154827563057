import * as actions from "store/actionTypes";

const defaultState = {
    data: {},
    isLoading: false
};

export default (state = defaultState, action) => {
    switch (action.type) {


        case actions.GET_USER_ACTIVITIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actions.GET_USER_ACTIVITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        case actions.GET_USER_ACTIVITIES_ERROR:
            return {
                ...state,
                isLoading: false
            };


        case actions.GET_USER_ACTIVITIES_INFINITY_SCROLL:

            return {
                ...state,
                data: {
                    ...state.data,
                    rows: [...state.data.rows, ...action.payload.rows]
                }
            };
        default:
            return state;
    }
};