//lines
import lineMultiLine from "assets/images/chart-images/multi-line.png";

//bar
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";

//chart
import table from "assets/images/chart-images/table.png";

const timePeriodList = [
        // time period - decision types
        { id: "DITPDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DICTDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DICDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DITPDT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DICTDT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DICTDT-T01e", name: "stacked-bar", chartImage: table, v2: "Column", disabled: false },
        { id: "DICTDT-T02e", name: "stacked-bar", chartImage: table, v2: "Row", disabled: false },
        { id: "DITPDT-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "DITPDT-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "DITPDT-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DITPDT-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        // { id: "DITPDT-BHS02s", name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
        // { id: "DITPDT-BHS02e", name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
        // { id: "DITPDT-BHS02p", name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
        { id: "DITPDT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DITPDT-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DITPDT-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "DITPDT-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        // { id: "DITPDT-W01s", name: "wedge", chartImage: lineWedge, disabled: true },
        // { id: "DITPDT-W01e", name: "wedge", chartImage: lineWedge, disabled: true },
        { id: "DITPDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DITPDT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DICDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DICTDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DICTDT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DICDT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DITPDT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
];

export default timePeriodList;

