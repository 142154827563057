//lines
import lineLineChart from "assets/images/chart-images/line-chart-markers.png";
import table from "assets/images/chart-images/table.png";

const timeNone = [
        // Time - None
        { id: "#CRTP-L03s", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "#CRTP-T03s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "#CRTP-T04s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "#CRTP-L03e", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "#CRTP-T03e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "#CRTP-T04e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
];

export default timeNone;
