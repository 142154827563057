//chart
import chart4Items from "assets/images/chart-images/donut.png";
import lineLineChart from "assets/images/chart-images/line-chart-95.png";
import table from "assets/images/chart-images/table.png";

const outcomeOfInvitationsSbmNone = [
        // Response to invitations - None
        { id: "#RIOIC-D01e", name: "donut", chartImage: chart4Items, disabled: false, description: "COMPLETED-TOTAL" },
        // { id: "#RIOIC-D02e", name: "donut", chartImage: chart4Items, disabled: false, description: "COMPLETED-TOTAL" },
        {id: "#RIOIC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#RIOIC-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
        { id: "#RITP-L02e", name: "line", chartImage: lineLineChart, disabled: false },
        { id: "#RIOIC-D01s", name: "donut", chartImage: chart4Items, disabled: false },
        // { id: "#RIOIC-D02s", name: "donut", chartImage: chart4Items, disabled: false },
        {id: "#RIOIC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#RIOIC-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "#RIOIC-T91r", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#RIOIC-T92r", name: "table", chartImage: table, v2: "Row", disabled: false},

];

export default outcomeOfInvitationsSbmNone;


