//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
const timePeriodList = [
        // Time period - None
        { id: "TDIATP-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TDIATP-T01e", name: "line", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDIATP-T02e", name: "line", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },

];

export default timePeriodList;
