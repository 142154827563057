export const centeredChartsList = [
    "%RETDIAE-BB02e",
    "%RETDIAE-BB02s",
    "#ETFR-H01s",
    "#ETFR-H01e",
    "#ETDIA-H01e",
    "#ETDIA-H01s",
    "#E#UI-H01e",
    "#E#UI-H01s",
    "#E#I-H01e",
    "#E#I-H01s",
    "#ETDIL-H01e",
    "#ETDIL-H01s",
    "STPSC-CC01e",
    "STPSC-BHS01e",
    "STPR#-T01e",
    "STPSC-BVS01e",
    "STPR#-T02e",
    "STPCT-BVS01e",
    "STPCT-BVS02e",
    "STPCT-BHS01e",
    "STPCT-L01e",
    "STPCT-W01e",
    "STPCT-T01e",
    "STPCT-T02e",
    "STPCT-CC01e",
    "STPM-BVS02e",
    "STPM-BHS01e",
    "STPM-CC01e",
    "STPM-BVS01e",
    "STPM-T02e",
    "STPM-T01e",
    "STPM-L01e",
    "STPM-W01e",
    "%AITP-L01s",
    "%AITP-L01e",
    "%RITP-L01s",
    "%RITP-L01e",
    "%RITP-L02e",
    "DITPDT-BVS01s",
    "DICTDT-BVS01s",
    "DICTDT-T01s",
    "DICTDT-T02s",
    "DICDT-BVS01s",
    "TFRE-BV01e",
    "TFRAE-BV01r",
    "TFRAE-BV02r",
    "TFRAE-BV03r",
    "TFRAE-BV04r",
    "TFRAE-BV05r",
    "TFRAE-BV06r",
    "TFRAE-BV07r",
    "TFRAE-BV08r",
    "TFRAE-T01r",
    "TFRAE-T02r",
    "TFRAE-T03r",
    "TFRAE-T04r",
    "TFRAE-T05r",
    "TFRAE-T06r",
    "TFRAE-T07r",
    "TFRAE-T08r",
    "TFRAE-T20r",
    "TFRE-BV02e",
    "TFRE-BV03e",
    "TFRE-BV04e",
    "TFRE-T03e",
    "TFRE-T02e",
    "TFRE-T01e",
    "TDFTP-BXV01e",
    "TDFTP-L01e",
    "TDFTP-L05e",
    "TDFTP-BV01e",
    "TDFTP-BV02e",
    "TDFTP-BV03e",
    "TDFTP-BV04e",
    "TDFTP-BV05e",
    "TDFTP-BV06e",
    "TDFTP-BV07e",
    "TDFTP-BV08e",
    "TDFR#-BV01e",
    "TDFR#-BV03e",
    "TDFR#-BV07e",
    "TDFR#-BV05e",
    "TDFR#-BXV01e",
    "TDFR#-T01e",
    "TDFR#-T02e",
    "TDFR#-T03e",
    "TDFR#-T04e",
    "TDFR#-T06e",
    "TDFR#-BV01s",
    "TDFR#-BV05s",
    "TDFR#-BXV01s",
    "TDFR#-T01s",
    "TDFR#-T02s",
    "TDFR#-T03s",
    "TDFR#-T04s",
    "TDFR#-T06s",

    "TDFTP-T02e",
    "TDFTP-T03e",
    "TDFTP-T07e",
    "TDFTP-T08e",
    "TDFTP-T01e",
    "TDFTP-T05e",
    "TDFTP-T06e",
    "TDFTP-T04e",
    "TDFTP-BH01e",
    "DITPDT-BVS01e",
    "DICTDT-BVS01e",
    "DICTDT-T01e",
    "DICTDT-T02e",
    "TCRTP-T05e",
    "TCRTP-T06e",
    "TCRTP-L01e",
    "TCRTP-BXV01e",
    "TCRTP-L05e",
    "TCRTP-T04e",
    "TCRTP-T01e",
    "TCRTP-T07e",
    "TCRTP-T02e",
    "TCRTP-T03e",
    "TCRTP-T04e",
    "DIdTPDT-L01p",
    "DIdTPDT-BVS01p",
    "DIdTPDT-BVS02p",
    "DIdTPDT-BHS01p",
    "DIdTPDT-CC01p",
    "DIdTPDT-T01p",
    "DIdTPDT-T02p",
    "DIdCDT-BVS01p",
    "DIdCDT-T01p",
    "DIdCDT-T02p",
    "TDIdTP-BV01p",
    "TDIdTP-BV02p",
    "TDIdTP-BV03p",
    "TDIdTP-BV04p",
    "TDIdTP-BH01p",
    "TDIdTP-L01p",
    "TDIdTP-T03p",
    "TDIdTP-T04p",
    "TDIdTP-BV05p",
    "TDIdTP-BV06p",
    "TDIdTP-BV07p",
    "TDIdTP-BV08p",
    "TDIdTP-BH05p",
    "TDIdTP-L05p",
    "TDIdTP-BXV05p",
    "TDIdTP-T07p",
    "TDIdTP-T08p",
    "TDIsTP-BV01p",
    "TDIsTP-BV02p",
    "TDIsTP-BV03p",
    "TDIsTP-BV04p",
    "TDIsTP-BH01p",
    "TDIsTP-L01p",
    "TDIsTP-T03p",
    "TDIsTP-T04p",
    "TDIsTP-BV05p",
    "TDIsTP-BV06p",
    "TDIsTP-BV07p",
    "TDIsTP-BV08p",
    "TDIsTP-BH05p",
    "TDIsTP-L05p",
    "TDIsTP-BXV05p",
    "TDIsTP-T07p",
    "TDIsTP-T08p",
    "TDIdTPDT-BVS01p",
    "TDIdTPDT-BHS01p",
    "TDIdTPDT-L01p",
    "TDIdTPDT-T03p",
    "TDIdTPDT-T04p",
    "TDIdTPDT-BVS05p",
    "TDIdTPDT-BHS05p",
    "TDIdTPDT-L05p",
    "TDIdTPDT-T07p",
    "TDIdTPDT-T08p",
    "TDIsTPDT-BVS01p",
    "TDIsTPDT-BHS01p",
    "TDIsTPDT-L01p",
    "TDIsTPDT-T03p",
    "TDIsTPDT-T04p",
    "TDIsTPDT-BVS05p",
    "TDIsTPDT-BHS05p",
    "TDIsTPDT-L05p",
    "TDIsTPDT-T07p",
    "TDIsTPDT-T08p",
    "DIdCTDT-BVS01p",
    "DIdCTDT-T01p",
    "DIdCTDT-T02p",
    "DIsCDT-BVS01p",
    "DIsCDT-T01p",
    "DIsCDT-T02p",
    "DIdDT-BV01p",
    "DIdDT-D01p",
    "DIdDT-T01p",
    "DIdDT-T02p",
    "DFdDT-BV01p",
    "DFdDT-D01p",
    "DFdDT-T01p",
    "DFdDT-T02p",
    "DFsDT-BV01p",
    "DFsDT-D01p",
    "DFsDT-T01p",
    "DFsDT-T02p",
    "DIsDT-BV01p",
    "DIsDT-D01p",
    "DIsDT-T01p",
    "DIsDT-T02p",
    "DIdCTCDT-BVS01p",
    "DIdCTCDT-T01p",
    "DIdCTCDT-T02p",
    "DIsCTCDT-BVS01p",
    "DIsCTCDT-T01p",
    "DIsCTCDT-T02p",
    "DFsCDT-BVS01p",
    "DFsCDT-BHS01p",
    "DFsCDT-CC01p",
    "DFsCDT-T01p",
    "DFsCDT-T02p",
    "DFdCDT-BVS01p",
    "DFdCDT-BHS01p",
    "DFdCDT-CC01p",
    "DFdCDT-T01p",
    "DFdCDT-T02p",
    "DFdCTDT-BVS01p",
    "DFdCTDT-BHS01p",
    "DFdCTDT-CC01p",
    "DFdCTDT-T01p",
    "DFdCTDT-T02p",
    "DFsCTDT-BVS01p",
    "DFsCTDT-BHS01p",
    "DFsCTDT-CC01p",
    "DFsCTDT-T01p",
    "DFsCTDT-T02p",
    "DFsCTCDT-BVS01p",
    "DFsCTCDT-BHS01p",
    "DFsCTCDT-CC01p",
    "DFsCTCDT-T01p",
    "DFsCTCDT-T02p",
    "DFdCTCDT-BVS01p",
    "DFdCTCDT-BHS01p",
    "DFdCTCDT-CC01p",
    "DFdCTCDT-T01p",
    "DFdCTCDT-T02p",
    "DITPDT-BVS02s",
    "DITPDT-BVS02e",
    "DITPDT-BHS01s",
    "DITPDT-BHS01e",
    "DITPDT-CC01s",
    "DITPDT-CC01e",
    "DITPDT-L01s",
    "DITPDT-L01e",
    "DITPDT-T01s",
    "DITPDT-T02s",
    "DICDT-T01s",
    "DICDT-T02s",
    "DITPDT-T01e",
    "DFTPDT-BVS01s",
    "DFTPC-BVS01s",
    "DFTPC-CC01s",
    "DFTPC-T01s",
    "DFTPC-T02s",
    "DFTPCT-BVS01s",
    "DFTPCT-CC01s",
    "DFTPCT-T01s",
    "DFTPCT-T02s",
    "DFTPDT-BVS01e",
    "DFCTDT-BVS01e",
    "DFCTDT-BVS01s",
    "DFCTDT-CC01s",
    "DFCTDT-T01s",
    "DFCTDT-T02s",
    "DFCTDT-T01e",
    "DFCTDT-T02e",
    "DFCDT-BVS01e",
    "DFCDT-BVS01s",
    "DFCDT-T01e",
    "DFCDT-T02e",
    "DFTPDT-BVS02s",
    "DFTPDT-BVS02e",
    "DFTPDT-CC01s",
    "DFCDT-CC01s",
    "DFTPDT-CC01e",
    "TDITP-L01s",
    "TDITP-L05s",
    "TDITP-BXV05s",
    "TDITP-T05s",
    "TDITP-T06s",
    "TDITP-T07s",
    "TDITP-T08s",
    "TDITP-BV01s",
    "TDITP-BV01r",
    "TDITP-BV04r",
    "TDITP-L01r",
    "TDITP-T03r",
    "TDITP-T04r",
    "TDITP-BV05r",
    "TDITP-BV08r",
    "TDITP-L05r",
    "TDITP-BXV05r",
    "TDITP-T07r",
    "TDITP-T08r",
    "TDITP-T20r",
    "TDITP-BV05s",
    "TSAETP-BXV05r",
    "DFTPDT-T01s",
    "DFCDT-T01s",
    "DFCDT-T02s",
    "DFTPDT-T01e",
    "DFM-BV01s",
    "DFM-BV01e",
    "DFTPM-BVS01e",
    "DFTPM-T01e",
    "DFTPM-CC01e",
    "DFTPM-BHS01e",
    "DFM-D01s",
    "DFM-D01e",
    "DFM-D02e",
    "DFM-BB01s",
    "DFM-BB01e",
    "DFM-T01s",
    "DFM-T01e",
    "TDIATP-L01e",
    "TDIATP-T01e",
    "TDIATP-T02e",
    "TDIATPSC-CC01e",
    "TDIATPSC-T01e",
    "TDIATPSC-T02e",
    "TDIAE-BV01e",
    "TDIAE-BV02e",
    "TDIAE-BV03e",
    "TDIAE-BV04e",
    "TDIAE-BXV01e",
    "TDIAE-BB01e",
    "TDIAE-T01e",
    "TDIAE-T02e",
    "TDIADI-BV01e",
    "TDIADI-T01e",
    "TDIADI-T02e",
    "TDILTP-L01e",
    "TDILTPSC-BVS01e",
    "TDILTP-T01e",
    "TDILTP-T02e",
    "TDFATP-L01e",
    "TDFATP-T01e",
    "TDFATP-T02e",
    "RIM-BV01e",
    "RIM-BH01e",
    "RIM-T02e",
    "RIM-T01e",
    "STPCT-BVS01p",
    "STPCT-BH01p",
    "STPCT-CC01p",
    "STPCT-T01p",
    "STPCT-T02p",
    "STPCTC-BVS01p",
    "STPCTC-BHS01p",
    "STPCTC-CC01p",
    "STPCTC-T01p",
    "STPCTC-T02p",
    "SQCTPCT-BVS01p",
    "SQCTPCT-BHS01p",
    "SQCTPCT-CC01p",
    "SQCTPCT-T01p",
    "SQCTPCT-T02p",
    "SQCTPCTC-BVS01p",
    "SQCTPCTC-BHS01p",
    "SQCTPCTC-CC01p",
    "SQCTPCTC-T01p",
    "SQCTPCTC-T02p",
    "STPC-BVS01p",
    "SC-BV01p",
    "SC-BH01p",
    "SC-D01p",
    "SC-D02p",
    "SC-M01p",
    "SC-M02p",
    "SC-T01p",
    "SC-T01Bp",
    "SC-T02p",
    "SC-T02Bp",
    "SQCC-BV01p",
    "SQCC-BH01p",
    "SQCC-D01p",
    "SQCC-D02p",
    "SQCC-M01p",
    "SQCC-M02p",
    "SQCC-T01p",
    "SQCC-T01Bp",
    "SQCC-T02p",
    "SQCC-T02Bp",
    "#RCR-BV01s",
    "#RCR-D01s",
    "#RCR-D02s",
    "#RCR-M01s",
    "#RCR-M02s",
    "#RCR-T01s",
    "#RCR-T02s",
    "#RCR-BV03s",
    "#RCR-M03s",
    "#RCR-M04s",
    "#RCR-T03s",
    "#RCR-T04s",
    "#RCTR-BV01s",
    "#RCTR-D01s",
    "#RCTR-T01s",
    "#RCTR-T02s",
    "#RCTR-BV03s",
    "#RCTR-D03s",
    "#RCTR-T03s",
    "#RCTR-T04s",
    "STPPC-BVS01p",
    "STPPC-BHS01p",
    "STPPC-CC01p",
    "STPPC-T01p",
    "STPPC-T02p",
    "SQCTPPC-BVS01p",
    "SQCTPPC-BHS01p",
    "SQCTPPC-CC01p",
    "SQCTPPC-T01p",
    "SQCTPPC-T02p",
    "SQCTPC-BVS01p",
    "SQCTPC-BHS01p",
    "SQCTPC-CC01p",
    "SQCTPC-T01p",
    "SQCTPC-T02p",
    "SQCTPR#-BVS01p",
    "SQCTPR#-BHS01p",
    "SQCTPR#-CC01p",
    "SQCTPR#-T01p",
    "SQCTPR#-T02p",
    "SCT-BV01p",
    "SCT-BH01p",
    "SCT-D01p",
    "SCT-T01p",
    "SCT-T02p",

    "SCTC-BV01p",
    "SCTC-BH01p",
    "SCTC-D01p",
    "SCTC-T01p",
    "SCTC-T02p",
    "SQCCTC-BV01p",
    "SQCCTC-BH01p",
    "SQCCTC-D01p",
    "SQCCTC-T01p",
    "SQCCTC-T02p",

    "STPR#-BVS01p",
    "STPR#-BHS01p",
    "STPR#-CC01p",
    "STPR#-T01p",
    "STPR#-T02p",

    "STPC-BVS01e",
    "STPC-T02e",
    "STPC-W01e",
    "STPC-L01e",
    "STPC-BHS01e",
    "STPC-CC01e",
    "STPC-BVS02e",
    "STPC-BH01p",
    "STPC-CC01p",
    "STPC-T01p",
    "STPC-T02p",
    "STP-BV01p",
    "STP-BV02p",
    "STP-BH01p",
    "STP-L01p",
    "STP-T01p",
    "STP-T02p",
    "SQCTP-BV01p",
    "SQCTP-BV02p",
    'SQCTP-BH01p',
    "SQCTP-L01p",
    "SQCTP-T01p",
    "SQCTP-T02p",
    "%RIdTP-BV01p",
    "%RIdTP-BH01p",
    "%RIdTP-L01p",
    "%RIdTP-T01p",
    "%RIdTP-T02p",
    "%RIsTPqc-BV01p",
    "%RIsTPqc-BH01p",
    "%RIsTPqc-L01p",
    "%RIsTPqc-T01p",
    "%RIsTPqc-T02p",
    "%RFdTP-BV01p",
    "%RFdTP-BH01p",
    "%RFdTP-L01p",
    "%RFdTP-T01p",
    "%RFdTP-T02p",
    "%RIdC-BV01p",
    "%RIdC-BH01p",
    "%RIdC-M01p",
    "%RIdC-M02p",
    "%RIdC-T01p",
    "%RIdC-T02p",
    "%RFdC-BV01p",
    "%RFdC-BH01p",
    "%RFdC-M01p",
    "%RFdC-M02p",
    "%RFdC-T01p",
    "%RFdC-T02p",
    "%RIdPC-BV01p",
    "%RIdPC-BH01p",
    "%RIdPC-T01p",
    "%RIdPC-T02p",
    "%RIsPCqc-BV01p",
    "%RIsPCqc-BH01p",
    "%RIsPCqc-T01p",
    "%RIsPCqc-T02p",
    "%RFdPC-BV01p",
    "%RFdPC-BH01p",
    "%RFdPC-T01p",
    "%RFdPC-T02p",
    "STPM-BVS01p",
    "STPM-BHS01p",
    "STPM-CC01p",
    "TDITP-BV01p",
    "STPM-T01p",
    "STPM-T02p",
    "SQCTPM-BVS01p",
    "SQCTPM-BHS01p",
    "SQCTPM-CC01p",
    "SQCTPM-T01p",
    "SQCTPM-T02p",
    "STP-BV01e",
    "#RITPOIA-BVS01r",
    "#RITPOIA-T01r",
    "#RITPOIA-T02r",
    "#RITPOIC-BVS01r",
    "#RITPOIC-T01r",
    "#RITPOIC-T02r",
    "SPC-BV01p",
    "SPC-BH01p",
    "SPC-D01p",
    "SPC-T01p",
    "SPC-T02p",
    "SQCPC-BV01p",
    "SQCPC-BH01p",
    "SQCPC-D01p",
    "SQCPC-T01p",
    "SQCPC-T02p",
];
export const chartAxisCenter = [
    "TDITP-BV01p",
    "RCTR-T01e",
    "RCTR-T02e",
    "RCTR-D01e",
    "RETDIAE-BB01e",
    "RCR-M01e",
    "SC-M01",
    "RETDIAE-BB01e",
    "TDILTP-L01s",
    "TDILTP-T01s",
    "TDILTP-T02s",
    "TDILE-BV01s",
    "TDILE-BV02s",
    "TDILE-BV03s",
    "TDILE-BV04s",
    "%RETDIAE-T01s",
    "%RETDIAE-T02s",
    "TDILE-T01s",
    "TDILE-T02s",
    "TDILEDI-CC01s",
    "TDILEDI-T01s",
    "TDILDIE-CC01s",
    "TDILDIE-T01s",
    "DIDT-DTV01e",
    "RIC",
    "TDITP-L01e",
    "TDITP-L05e",
    "TDITP-L05p",
    "TDITP-BV01e",
    "TDITP-BV05e",
    "STP-T02e",
    "STP-T01e",
    "STP-L01e",
    "STP-BH01e",
    "STP-BV02e",
    "STP-BV01e",
    "TDITP-BXV05e",
    "TFLRAE-BXV05r",
    "TFRAE-BXV05r",
    "TCSLAE-BXV05r",
    "TDITP-BH01p",
    "TDITP-L01p",
    "TDITP-T01p",
    "TDITP-T02p",
    "TDITP-T03p",
    "TDITP-T04p",
    "TDITP-BH01e",
    "TDITP-T04e",
    "TDITP-T03e",
    "TDITP-T08e",
    "TDITP-T06p",
    "TDITP-T07e",
    "TDITP-T05e",
    "TDITP-T06e",
    "TDITP-T08e",
    "TDFR#-T05e",
    "TDFR#-T07e",
    "TDITP-T05p",
    "TDITP-T01e",
    "TDITP-T02e",
    "AFM-BB01e",
    "AFM-T01e",
    "AFM-T02e",
    "RFTP-L01e",
    "RFTP-T01e",
    "RFTP-T02e",
    "CRTP-L03s",
    "CRTP-T03s",
    "CRTP-T04s",
    "CRTP-L03e",
    "CRTP-T03e",
    "CRTP-T04e",
    "RITP",
    "EAE-T02e",
    "STPR#-CC01e",
    "EAE-BV01e",
    "#RCR-BV01e",
    "#RCR-BV03e",
    "#RCR-D01e",
    "#RCR-D02e",
    "#RCR-T01e",
    "#RCR-T02e",
    "#RCR-T03e",
    "#RCR-T04e",
    "RIM-BV01e",
    "RIM-BH01e",
    "RIM-T02e",
    "RIM-T01e",
    "RCR-M03e",
    "RCTR-BV01e",
    "AITP-BV01e",
    "AITP-BH01e",
    "AITP-T01e",
    "AITP-T02e",
    "AFTP",
    "RFC-M01e",
    "IE-",
    "RETDIAE-T01e",
    "RETDIAE-T02e",
    "TDILE-",
    "#RITPOIA-BVS01r",
    "#RITPOIA-T01r",
    "#RITPOIA-T02r",
    "#RITPOIC-BVS01r",
    "#RITPOIC-T01r",
    "#RITPOIC-T02r",
];
export const changeSeparateSortValueArr = [
    "TDILDIE-CC01s",
    "TDILDIE-CC01e",
    "#ECE-D01s",
    "#ECE-D01e",
    "#ECE-D02s",
    "#ECE-D02e",
    "STPC-BHS01e",
    "STPC-CC01e",
    "STPC-BVS02e",
    "STPC-BVS02e",
    "STPC-BVS01e",
    "TDILDIE",
    "TDIATPSC",
    "DFTPM",
    "DITPDT",
    "RCR-D01e",
    "RCTR-D01e",
    "STPC-CC01p",
    "STPCT-BVS01p",
    "STPCT-BH01p",
    "STPCT-CC01p",
    "STPCTC-BVS01p",
    "STPCTC-BHS01p",
    "STPCTC-CC01p",
    "SC-D02s",
    "#RITPOIA-BVS01r",
    "#RITPOIA-T01r",
    "#RITPOIA-T02r",
    "#RITPOIC-BVS01r",
    "#RITPOIC-T01r",
    "#RITPOIC-T02r",
    "DIdTPDT-BVS01p",
    "DIdTPDT-BVS02p",
    "DIdTPDT-BHS01p",
    "DIdTPDT-CC01p",
    "DIdTPDT-L01p",
    "DIdCDT-BVS01p",
    "DIdCTDT-BVS01p",
    "DIsCDT-BVS01p",
    "DIdCTCDT-BVS01p",
    "DIsCTCDT-BVS01p",
    "DFsCDT-BVS01p",
    "DFsCDT-BHS01p",
    "DFdCDT-BVS01p",
    "DFdCDT-BHS01p",
    "DFdCTDT-BVS01p",
    "DFdCTDT-BHS01p",
    "DFsCTDT-BVS01p",
    "DFsCTDT-BHS01p",
    "DFsCTCDT-BVS01p",
    "DFsCTCDT-BHS01p",
    "DIsTPDT-BVS01p",
    "DIsTPDT-BVS02p",
    "DIsTPDT-BHS01p",
    "DIsTPDT-CC01p",
    "DIsTPDT-L01p",
    "DFsTPDT-BVS01p",
    "DFsTPDT-BVS02p",
    "DFsTPDT-BHS01p",
    "DFsTPDT-CC01p",
    "DFsTPDT-L01p",
    "DFdTPDT-BVS01p",
    "DFdTPDT-BVS02p",
    "DFdTPDT-BHS01p",
    "DFdTPDT-CC01p",
    "DFdTPDT-L01p",
    "DIsCTDT-BVS01p",
]