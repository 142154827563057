//bar
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";
import table from "assets/images/chart-images/table.png";
const timeOutcomeOfInvitationsSbm = [
        // Response to invitations - None
        { id: "#RITPOIC-BVS01s", name: "bar", chartImage: barStachedBar, disabled: false, },
        { id: "#RITPOIC-BVS01e", name: "bar", chartImage: barStachedBar, disabled: false, description: "AGREED-TOTAL" },
        { id: "#RITPOIC-BVS02s", name: "bar", chartImage: barStacked100Bar, disabled: false },
        { id: "#RITPOIC-BVS02e", name: "bar", chartImage: barStacked100Bar, disabled: false, description: "AGREED-TOTAL" },
        { id: "#RITPOIC-CC01s", name: "bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "#RITPOIC-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AGREED-TOTAL" },
        { id: "#RITPOIC-T01s", name: "bar", chartImage: table, v2: "Column", disabled: false },
        { id: "#RITPOIC-T02s", name: "bar", chartImage: table, v2: "Row", disabled: false },
        { id: "#RITPOIC-T01e", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AGREED-TOTAL" },
        { id: "#RITPOIC-T02e", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "AGREED-TOTAL" },
        { id: "#RITPOIC-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
        { id: "#RITPOIC-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false },
        { id: "#RITPOIC-T02r", name: "bar", chartImage: table, v2: "Row", disabled: false },
        { id: "#RITPOIA-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
        { id: "#RITPOIA-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false, },
        { id: "#RITPOIA-T02r", name: "bar", chartImage: table, v2: "Row", disabled: false, },
];

export default timeOutcomeOfInvitationsSbm;
