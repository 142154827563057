//chart
import Histogram from "assets/images/chart-images/histogram.png";

const assignmentInitialDecision = [
    // Time from editor assignment to first reviewer invited-none
    { id: "#ETDIA-H01e", name: "histogram", chartImage: Histogram, disabled: false },
    { id: "#ETDIA-H01s", name: "histogram", chartImage: Histogram, disabled: false },
    
];

export default assignmentInitialDecision;
