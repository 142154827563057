
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";
import chart4Items from "assets/images/chart-images/donut.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import chartChange from "assets/images/chart-images/bubble.png";

const timePeriodList = [
        // time period - decision types
        { id: "DFTPDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFTPC-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFTPCT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },         
        { id: "DFTPDT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },

        { id: "DFTPDT-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "DFTPDT-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        // { id: "DFTPDT-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: true },
        // { id: "DFTPDT-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: true },
        // { id: "DFTPDT-BHS02s", name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
        // { id: "DFTPDT-BHS02e", name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
        { id: "DFTPDT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPDT-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPC-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFTPC-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFTPCT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPCT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFTPCT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },           
        // { id: "DFTPDT-L01", name: "multi-line", chartImage: lineMultiLine, disabled: true },
        // { id: "DFTPDT-W01", name: "wedge", chartImage: lineWedge, disabled: true },
        { id: "DFTPDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFTPDT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFM-D01s", name: "donut", chartImage: chart4Items, disabled: false },
        { id: "DFM-D01e", name: "donut", chartImage: chart4Items, disabled: false },
        { id: "DFM-D02e", name: "donut", chartImage: chart4Items, disabled: false },
        { id: "DFM-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "DFM-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "DFTPM-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFTPM-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DFTPM-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFM-BB01s", name: "bubble", chartImage: chartChange, disabled: false },
        { id: "DFM-BB01e", name: "bubble", chartImage: chartChange, disabled: false },
        { id: "DFM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFCDT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFCDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFCDT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFCDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFCDT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFCDT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFCDT-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFCTDT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFCTDT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFCTDT-T02e", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFCTDT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFCTDT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFCTDT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFCTDT-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFTPHR#-BVS01r", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFTPHR#-CC01r", name: "100%-stacked-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFTPHR#-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFTPHR#-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "DFTPHR#-T20r", name: "table", chartImage: table, disabled: false },

];

export default timePeriodList;
