const timePeriodCharts = [
  "#RIOIC-D01e",
  // "#RIOIC-D02e",
  "STP-L01s",
  "#RIOIC-D01s",
  // "#RIOIC-D02s",
  "STP-L01e",
  "STP-BV01s",
  "STP-BV01e",
  "STP-BV01p",
  "STP-BV02p",
  "STP-BH01p",
  "STP-L01p",
  "STP-T01p",
  "STP-T02p",
  "SQCTP-BV01p",
  "SQCTP-BV02p",
  'SQCTP-BH01p',
  "SQCTP-L01p",
  "SQCTP-T01p",
  "SQCTP-T02p",
  "%RIdTP-BV01p",
  "%RIdTP-BH01p",
  "%RIdTP-L01p",
  "%RIdTP-T01p",
  "%RIdTP-T02p",
  "%RIsTPqc-BV01p",
  "%RIsTPqc-BH01p",
  "%RIsTPqc-L01p",
  "%RIsTPqc-T01p",
  "%RIsTPqc-T02p",
  "%RFdTP-BV01p",
  "%RFdTP-BH01p",
  "%RFdTP-L01p",
  "%RFdTP-T01p",
  "%RFdTP-T02p",
  "%RIdC-BV01p",
  "%RIdC-BH01p",
  "%RIdC-M01p",
  "%RIdC-M02p",
  "%RIdC-T01p",
  "%RIdC-T02p",
  "%RIdPC-BV01p",
  "%RIdPC-BH01p",
  "%RIdPC-T01p",
  "%RIdPC-T02p",
  "%RIsPCqc-BV01p",
  "%RIsPCqc-BH01p",
  "%RIsPCqc-T01p",
  "%RIsPCqc-T02p",
  "%RFdPC-BV01p",
  "%RFdPC-BH01p",
  "%RFdPC-T01p",
  "%RFdPC-T02p",
  "SPC-BV01p",
  "SPC-BH01p",
  "SPC-D01p",
  "SPC-T01p",
  "SPC-T02p",
  "SQCPC-BV01p",
  "SQCPC-BH01p",
  "SQCPC-D01p",
  "SQCPC-T01p",
  "SQCPC-T02p",
  "STP-BV02s",
  "STP-BV02e",
  "STP-BH01s",
  "STP-BH01e",
  "STP-T01s",
  "STP-T01e",
  "STP-T02s",
  "STP-T02e",
  "STP-BV01r",
  "STP-T01r",
  "STP-T02r",
  "STP-T20r",
  "STPC-BVS01s",
  "STPC-BVS01e",
  "STPC-BVS01p",
  "STPPC-BVS01p",
  "STPPC-BHS01p",
  "STPPC-CC01p",
  "STPPC-T01p",
  "STPPC-T02p",
  "SQCTPPC-BVS01p",
  "SQCTPPC-BHS01p",
  "SQCTPPC-CC01p",
  "SQCTPPC-T01p",
  "SQCTPPC-T02p",
  "SQCTPC-BVS01p",
  "SQCTPC-BHS01p",
  "SQCTPC-CC01p",
  "SQCTPC-T01p",
  "SQCTPC-T02p",
  "SQCTPR#-BVS01p",
  "SQCTPR#-BHS01p",
  "SQCTPR#-CC01p",
  "SQCTPR#-T01p",
  "SQCTPR#-T02p",
  "SCT-BV01p",
  "SCT-BH01p",
  "SCT-D01p",
  "SCT-T01p",
  "SCT-T02p",
  "SCTC-BV01p",
  "SCTC-BH01p",
  "SCTC-D01p",
  "SCTC-T01p",
  "SCTC-T02p",
  "SQCCTC-BV01p",
  "SQCCTC-BH01p",
  "SQCCTC-D01p",
  "SQCCTC-T01p",
  "SQCCTC-T02p",

  "SQCCT-BV01p",
  "SQCCT-BH01p",
  "SQCCT-D01p",
  "SQCCT-T01p",
  "SQCCT-T02p",
  
  "STPR#-BVS01p",
  "STPR#-BHS01p",
  "STPR#-CC01p",
  "STPR#-T01p",
  "STPR#-T02p",

  "STPC-BH01p",
  "STPC-CC01p",
  "STPC-T01p",
  "STPC-T02p",
  "STPC-BVS02s",
  "STPC-BVS02e",
  "STPC-BHS01s",
  "STPC-BHS01e",
  "STPC-CC01s",
  "STPC-CC01e",
  "STPC-L01s",
  "STPC-L01e",
  "STPC-W01s",
  "STPC-W01e",
  "STPC-T01s",
  "STPC-T02s",
  "STPC-T01e",
  "STPC-T02e",
  "STPCT-BVS01s",
  "STPCT-BVS01e",
  "STPCT-BVS01p",
  "STPCT-BH01p",
  "STPCT-CC01p",
  "STPCT-T01p",
  "STPCT-T02p",
  "STPCTC-BVS01p",
  "STPCTC-BHS01p",
  "STPCTC-CC01p",
  "STPCTC-T01p",
  "STPCTC-T02p",
  "SQCTPCT-BVS01p",
  "SQCTPCT-BHS01p",
  "SQCTPCT-CC01p",
  "SQCTPCT-T01p",
  "SQCTPCT-T02p",
  "SQCTPCTC-BVS01p",
  "SQCTPCTC-BHS01p",
  "SQCTPCTC-CC01p",
  "SQCTPCTC-T01p",
  "SQCTPCTC-T02p",
  "STPCT-BVS02s",
  "STPCT-BVS02e",
  "STPCT-BHS01s",
  "STPCT-BHS01e",
  "STPCT-CC01s",
  "STPCT-CC01e",
  "STPCT-L01s",
  "STPCT-L01e",
  "STPCT-W01s",
  "STPCT-W01e",
  "STPCT-T01s",
  "STPCT-T02s",
  "STPCT-T01e",
  "STPCT-T02e",
  "STPM-BVS01s",
  "STPM-BVS01e",
  "STPM-BVS01p",
  "STPM-BHS01p",
  "STPM-CC01p",
  "STPM-T01p",
  "STPM-T02p",
  "SQCTPM-BVS01p",
  "SQCTPM-BHS01p",
  "SQCTPM-CC01p",
  "SQCTPM-T01p",
  "SQCTPM-T02p",
  "STPM-BVS02s",
  "STPM-BVS02e",
  "STPM-BHS01s",
  "STPM-BHS01e",
  "STPM-CC01s",
  "STPM-CC01e",
  "STPM-L01s",
  "STPM-L01e",
  "STPM-W01s",
  "STPM-W01e",
  "STPM-T01s",
  "STPM-T02s",
  "STPM-T01e",
  "STPM-T02e",
  "STPR#-CC01s",
  "STPR#-CC01e",
  "STPR#-BVS01r",
  "STPR#-CC01r",
  "STPR#-T01r",
  "STPR#-T02r",
  "STPR#-T20r",
  "STPSC-CC01s",
  "STPSC-CC01e",
  "STPSC-BHS01e",
  "STPSC-BVS01e",
  "STPR#-T01s",
  "STPR#-T02s",
  "STPR#-T01e",
  "STPR#-T02e",
  "STPSC-T01s",
  "STPSC-T01e",
  "STPSC-T02e",
  "DITPDT-BVS01s",
  "DITPDT-BVS01e",
  "DIdTPDT-BVS01p",
  "DIdTPDT-BVS02p",
  "DIdTPDT-BHS01p",
  "DIdTPDT-L01p",
  "DIdTPDT-CC01p",
  "DIdTPDT-T01p",
  "DIdTPDT-T02p",
  "DIsTPDT-BVS01p",
  "DIsTPDT-BVS02p",
  "DIsTPDT-BHS01p",
  "DIsTPDT-CC01p",
  "DIsTPDT-L01p",
  "DIsTPDT-T01p",
  "DIsTPDT-T02p",
  "DFsTPDT-BVS01p",
  "DFsTPDT-BVS02p",
  "DFsTPDT-BHS01p",
  "DFsTPDT-CC01p",
  "DFsTPDT-L01p",
  "DFsTPDT-T01p",
  "DFsTPDT-T02p",
  "DFdTPDT-BVS01p",
  "DFdTPDT-BVS02p",
  "DFdTPDT-BHS01p",
  "DFdTPDT-CC01p",
  "DFdTPDT-L01p",
  "DFdTPDT-T01p",
  "DFdTPDT-T02p",
  "DIsCTDT-BVS01p",
  "DIsCTDT-T01p",
  "DIsCTDT-T02p",
  "DIdDT-BV01p",
  "DIdDT-D01p",
  "DIdDT-T01p",
  "DIdDT-T02p",
  "DFdDT-BV01p",
  "DFdDT-D01p",
  "DFdDT-T01p",
  "DFdDT-T02p",
  "DFsDT-BV01p",
  "DFsDT-D01p",
  "DFsDT-T01p",
  "DFsDT-T02p",
  "DIsDT-BV01p",
  "DIsDT-D01p",
  "DIsDT-T01p",
  "DIsDT-T02p",
  "DITPDT-BVS02s",
  "DITPDT-BVS02e",
  "DITPDT-BHS01s",
  "DITPDT-BHS01e",
  "DITPDT-CC01s",
  "DITPDT-CC01e",
  "DITPDT-L01s",
  "DITPDT-L01e",
  "DITPDT-T01s",
  "DITPDT-T02s",
  "DITPDT-T01e",
  "%RITP-L01s",
  "%RITP-L01e",
  "%RITP-L02e",
  "%RITP-BV01s",
  "%RITP-BV01e",
  "%RITP-BH01s",
  "%RITP-BH01e",
  "%RITP-T01s",
  "%RITP-T01e",
  "%RITP-T02s",
  "%RITP-T02e",
  "%AITP-L01s",
  "%AITP-L01e",
  "%AITP-BV01s",
  "%AITP-BV01e",
  "%AITP-BH01s",
  "%AITP-BH01e",
  "%AITP-T01s",
  "%AITP-T01e",
  "%AITP-T02s",
  "%AITP-T02e",
  "DFTPDT-BVS01s",
  'DFTPHR#-BVS01r',
  'DFTPHR#-CC01r',
  'DFTPHR#-T01r',
  'DFTPHR#-T02r',
  'DFTPHR#-T20r',
  'SRAR#SN-BVS01r',
  'SRAR#SN-CC01r',
  'SRAR#SN-T01r',
  'SRAR#SN-T02r',
  'SRAR#SN-T20r',
  'TACR#SN-CC01r',
  'TACR#SN-T03r',
  'TACR#SN-T04r',
  'TACR#SN-CC05r',
  'TACR#SN-T07r',
  'TACR#SN-T08r',
  'TACR#SN-T20r',
  "DFTPC-BVS01s",
  "DFTPC-CC01s",
  "DFTPC-T01s",
  "DFTPC-T02s",
  "DFTPCT-BVS01s",
  "DFTPCT-CC01s",
  "DFTPCT-T01s",
  "DFTPCT-T02s",
  "DFTPDT-BVS01e",
  "DFCDT-T01e",
  "DFCDT-T02e",
  "DFTPDT-BVS02s",
  "DFTPDT-BVS02e",
  "DFTPDT-CC01s",
  "DFTPDT-CC01e",
  "DFTPDT-T01s",
  "DFTPDT-T01e",
  "TDITP-L01s",
  "TDITP-L05s",
  "TDITP-BXV05s",
  "TDITP-L01e",
  "TDITP-L05e",
  "TDITP-T03e",
  "TDITP-T07e",
  "TDITP-T06e",
  "TDITP-T05e",
  "TDITP-T05p",
  "TDITP-T08e",
  "TDITP-T06p",
  "TDITP-T04e",
  "TDFATP-L01s",
  "TDFATP-L01e",
  "TDFATP-T01s",
  "TDFATP-T01e",
  "TDFATP-T02s",
  "TDFATP-T02e",
  "TDIATP-L01e",
  "TDIATPSC-CC01e",
  "TDIATPSC-T01e",
  "TDIATPSC-T02e",
  "TDIATP-L01s",
  "TDIATP-T01s",
  "TDIATP-T02s",
  "TDIATP-T01e",
  "TDIATP-T02e",
  "TDITP-BV01s",
  "TDITP-BV01r",
  "TDITP-BV04r",
  "TDITP-L01r",
  "TDITP-T03r",
  "TDITP-T04r",
  "TDITP-BV05r",
  "TDITP-BV08r",
  "TDITP-L05r",
  "TDITP-BXV05r",
  "TDITP-T07r",
  "TDITP-T08r",
  "TDITP-T20r",
  "TDITP-BV05s",
  "TDITP-BV01e",
  "TDITP-BV05e",
  "TDITP-BXV05e",
  "TSAETP-L01r",
  "TSAETP-T03r",
  "TSAETP-T04r",
  "TSAETP-L05r",
  "TSAETP-BXV05r",
  "TSAETP-T07r",
  "TSAETP-T08r",
  "TSAETP-T20r",
  "TFLRAE-BXV05r",
  "TFRAE-BXV05r",
  "TCSLAE-BXV05r",
  "TDITP-BV01p",
  "TDITP-BH01p",
  "TDITP-L01p",
  "TDITP-T01p",
  "TDITP-T02p",
  "TDITP-T03p",
  "TDITP-T04p",
  "TDITP-BH01s",
  "TDITP-BH01e",
  "TDITP-T01s",
  "TDITP-T03s",
  "TDITP-T04s",
  "TDITP-T05s",
  "TDITP-T06s",
  "TDITP-T07s",
  "TDITP-T08s",
  "TDITP-T01e",
  "TDITP-T02s",
  "TDITP-T02e",
  "%AFTP-L01s",
  "%AFTP-BV01e",
  "%AFTP-BH01e",
  "%AFTP-L01e",
  "%AFTP-L02e",
  "%AFTP-T01s",
  "%AFTP-T01e",
  "%AFTP-T02e",
  "%RFTP-L01s",
  "%RFTP-L01e",
  "%RFTP-T01s",
  "%RFTP-T01e",
  "%RFTP-T02e",
  "TCRTP-L01s",
  "TCRTP-BV01s",
  "TCRTP-BV05s",
  "TCRTP-BV02s",
  "TCRTP-BV06s",
  "TCRTP-BV03s",
  "TCRTP-BV07s",
  "TCRTP-BV04s",
  "TCRTP-BV08s",
  "TCRTP-L05s",
  "TCRTP-L01e",
  "TCRTP-L05e",
  "TPRTP-BV01s",
  "TPRTP-BV02s",
  "TPRTP-BV03s",
  "TPRTP-BV04s",
  "TPRTP-L01s",
  "TPRTP-T01s",
  "TPRTP-T02s",
  "TPRTP-T03s",
  "TPRTP-T04s",
  "TPRTP-BV05s",
  "TPRTP-BV06s",
  "TPRTP-BV07s",
  "TPRTP-BV08s",
  "TPRTP-L05s",
  "TPRTP-BXV05s",
  "TPRTP-L01e",
  "TPRTP-T01e",
  "TPRTP-BV01r",
  "TPRTP-BV04r",
  "TPRTP-L01r",
  "TPRTP-T03r",
  "TPRTP-T04r",
  "TPRTP-BV05r",
  "TPRTP-BV08r",
  "TPRTP-L05r",
  "TPRTP-BXV05r",
  "TPRTP-T07r",
  "TPRTP-T08r",
  "TPRTP-T20r",
  "TCRTP-T01e",
  "TCRTP-T03e",
  "TCRTP-T05e",
  "TCRTP-T06e",
  "TCRTP-T01s",
  "TCRTP-T05s",
  "TCRTP-T02s",
  "TCRTP-T06s",
  "TCRTP-T03s",
  "TCRTP-T04s",
  "TCRTP-T07s",
  "TCRTP-T08s",
  "TCRTP-BXV05s",
  "TCRTP-T02e",
  "TCRTP-T04e",
  "TCRTP-T07e",
  // "TCRTP-BV02r",
  // "TCRTP-BV03r",
  "TCRTP-BV04r",
  "TCRTP-L01r",
  "TCRTPRT-L01r",
  "TCRTPRT-T03r",
  "TCRTPRT-T04r",
  "TCRTPRT-L05r",
  "TCRTPRT-T07r",
  "TCRTPRT-T08r",
  "TCRTPRT-T20r",
  // "TCRTP-T01r",
  // "TCRTP-T02r",
  "TCRTP-T03r",
  "TCRTP-T04r",
  "TCRTP-L05r",
  "TCRTP-BV01r",
  "TCRTP-BXV05r",
  "TCRTP-BV05r",
  // "TCRTP-BV07r",
  "TCRTP-BV08r",
  "TCRTP-T07r",
  "TCRTP-T08r",
  "TCRTP-T20r",
  "#RITPOIC-BVS01e",
  "IACTP-L01s",
  "IACTP-L01e",
  "ICCTP-L01e",
  "ICCTP-L01s",
  "ICCTP-T01s",
  "ICCTP-T02s",
  "ICCTP-L01r",
  "ICCTP-T01r",
  "ICCTP-T02r",
  "ICCTPRT-L01r",
  "ICCTPRT-T01r",
  "ICCTPRT-T02r",
  "ACCTP-L01s",
  "ACCTP-T01s",
  "ACCTP-T02s",
  "ACCTP-L01e",
  "ACCTP-L01r",
  "ACCTP-T01r",
  "ACCTP-T02r",
  "ACCTPRT-L01r",
  "ACCTPRT-T01r",
  "ACCTPRT-T02r",
  "TDILTP-L01s",
  "TDILTP-L01e",
  "TDILTP-T01s",
  "TDILTP-T01e",
  "TDILTP-T02s",
  "TDILTP-T02e",
  "#ETP-BV01e",
  "#ETP-BV01s",
  "#ETP-T01e",
  "%RETDIAE-T01s",
  "%RETDIAE-T01e",
  "%RETDIAE-T02s",
  "%RETDIAE-T02e",
  "#ETP-T01s",
  "#ETP-T02e",
  "#ETP-T02s",
  "TCRTP-BXV01e",
  "TDILTPSC-BVS01e",
  "IACTP-L01r",
  "IACTP-T01r",
  "IACTP-T02r",
  "IACTPRT-L01r",
  "IACTPRT-T01r",
  "IACTPRT-T02r",
  "SPC-BVS01p",
  "SPC-BHS01p",
  "SPC-D01p",
  "SPC-T01p",
  "SPC-T02p",
];

export default timePeriodCharts;