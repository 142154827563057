import React from "react";
import "./index.scss"
import Spinner from "components/elements/spinner/Spinner";

class DemoLoadingPage extends React.Component {
    render() {
        return (
            <div className="loading-demo-container">
                <Spinner size="small"/>
            </div>
        )
    }
}


export default DemoLoadingPage;
