import {
    GET_MY_TEAMMATES,
    DELETE_TEAMMATES,
    CHANGE_TEAMMATE_DATA,
    TEAMMATE_REPORT_PERRMISSIONS,
    CHANGE_PERMISSIONS_TEAMMATE,
    GET_INVITE_PERMISSIONS,
    TEAMMATES_LOADER,
    TEAMMATES_PERMISSION_LOADER,
    GET_REPORT_PERMISSIONS,
    CHANGE_PERMISSIONS_REPORT
} from "../../actionTypes";
import _ from "lodash"
const defaultState = {
    data: [],
    loader:false,
    permissionLoader:false,
    permission:{
        standartReports:[],
        reports:[]
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_MY_TEAMMATES: {
            const { data } = action;
            return {
                ...state,
                data: data,
            };
        }
        case TEAMMATES_LOADER:{
            const { data } = action;
            return {
                ...state,
                loader:data
            }; 
        }
        case TEAMMATES_PERMISSION_LOADER :{
            const { data } = action;
            return {
                ...state,
                permissionLoader:data
            }; 
        }
        case CHANGE_PERMISSIONS_REPORT:{
            const {user_id,  value } = action
            const changeData = state.data.map((i) => {
                    if (i.id === user_id) {
                        i.permission = value.key
                    }
                return i
            })
            return {
                ...state,
                data:changeData
            };
        }
        case GET_REPORT_PERMISSIONS:{
            const { data } = action;
            const newData = state.data.map((i)=>{
              const permission =  data.find((j)=>j.invited_user_id === i.id).permission
              const permissionID =  data.find((j)=>j.invited_user_id === i.id).id
              i.permission = permission
              i.permissionId = permissionID
              return i
            })
            return {
                ...state,
                data:newData
            }; 
        }
        case GET_INVITE_PERMISSIONS:{
            const { data } = action;
            return {
                ...state,
                permission:{
                    ...state.permission,
                    reports:data.reports,
                    standartReports:data.standartReports
                }
            }; 
        }
        case DELETE_TEAMMATES: {
            const { id } = action;
            const newData = state.data.filter((i) => i.id !== id);
            return {
                ...state,
                data: newData,
            };
        }
        case CHANGE_TEAMMATE_DATA: {
            const { id, data } = action;
            const newData = state.data.map((i) => {
                if (String(i.id) === String(id)) {
                    i = { ...i, ...data };
                }
                return i;
            });
            return {
                ...state,
                data: newData,
            };
        }
        case TEAMMATE_REPORT_PERRMISSIONS: {
            const { data } = action.payload.allReports
            const newData = state.data.map((item) => {
                if (Object.keys(item.data).length === 0) {
                    item.data.reports = []
                    item.data.standartReports = []
                    data.reports.forEach(element => {
                        item.data.reports.push({
                            id: element.id,
                            title: element.title,
                            user_id: element.user_id,
                            permission: { key: "disable" }
                        })
                    });
                    data.standartReports.forEach(element => {
                        item.data.standartReports.push({
                            id: element.id,
                            title: element.title,
                            user_id: element.user_id,
                            permission: { key: "disable" },
                        })
                    });
                }
                return item
            })
            return {
                ...state,
                data: _.cloneDeep(newData),
            };
        }
        case CHANGE_PERMISSIONS_TEAMMATE: {
            const { id, reportType, value } = action
            const changeData = state.permission[reportType].map((i) => {
                if (i.id === id) {
                        i.permission = value.key
                }
                return i
            })
            return {
                ...state,
                permission: {
                    ...state.permission,
                    [reportType]:[ ...changeData,]
                },
            };
        }
        default:
            return state;
    }
};
