import { toast } from 'react-toastify';

export const useNotify = () => {
  const toastCommonStyle = {
    backgroundColor: "#74BA23", 
    color: "#FFFFFF",
    borderRadius: "4px",
    marginBottom: "8px", 
  };

  const notifySuccess = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      style: { 
        ...toastCommonStyle,
        backgroundColor: "#74BA23",
        minWidth: "300px",
        marginRight: "24px" 
      },
    });
  };

  const notifyCancel = (text) => {
    toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      style: {
        ...toastCommonStyle,
        backgroundColor: "#F21414",
        minWidth: "300px",
        marginRight: "24px" 
      },
    });
  };

  return { notifySuccess, notifyCancel };
};
