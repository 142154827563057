import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import table from "assets/images/chart-images/table.png";
import boxChartImage from "assets/images/chart-images/box-whisker.png";

const associateEditors = [
    { id: "TFRAE-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
    { id: "TFRAE-BV02r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
    { id: "TFRAE-BV03r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
    { id: "TFRAE-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
    { id: "TFRAE-T01r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TFRAE-T02r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TFRAE-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TFRAE-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TFRAE-BXV05r", name: "simple-bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
    { id: "TFRAE-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
    { id: "TFRAE-BV06r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
    { id: "TFRAE-BV07r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
    { id: "TFRAE-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
    { id: "TFRAE-T05r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
    { id: "TFRAE-T06r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
    { id: "TFRAE-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
    { id: "TFRAE-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
    { id: "TFRAE-T20r", name: "table", chartImage: table, disabled: false },
];

export default associateEditors;