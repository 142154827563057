import lineMultiLine from "assets/images/chart-images/multi-line.png";
import table from "assets/images/chart-images/table.png";

const revTypeByTimePeriod = [
         {id: "IACTPRT-L01r", name: "line", chartImage: lineMultiLine, disabled: false},
         {id: "IACTPRT-T01r", name: "table", chartImage: table, v2: "Column", disabled: false},
         {id: "IACTPRT-T02r", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default revTypeByTimePeriod;
