import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./style.scss";

const Icon = ({
    className,
    color,
    size,
    name,
    disabled,
    rotated,
    style,
    ...rest
}) => {
    let iconStyle = {
        ...style,
        width: "max-content",
        height: "max-content",
        fontSize: `${size}px`,
        color
    };
    if (rotated) {
        iconStyle = {
            ...iconStyle,
            transform: `rotate(${rotated}deg)`
        };
    }

    return (
        <span
            {...rest}
            className={cx(
                `icon icon-${name} ${className}`,
                disabled && "icon-disabled"
            )}
            style={iconStyle}
        />
    );
};

Icon.defaultProps = {
    className: "",
    size: 24,
    color: "#607D8B"
};

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    name: PropTypes.string.isRequired,
    rotated: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object
};

export default Icon;
