
import chartChange          from "assets/images/chart-images/bubble.png";
import table      from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";

const timePeriodList = [
        {id: "%AFM-BB01s", name: "bubble", chartImage: chartChange, disabled: false},
        {id: "%AFM-BB01e", name: "bubble", chartImage: chartChange, disabled: false},
        {id: "%AFM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AFM-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%AFM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AFM-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default timePeriodList;
