import { CHANGE_CALCULATION_LOADING_STATUS } from '../actions/calculationLoadingStatusAction';

const defaultState = {
    result: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_CALCULATION_LOADING_STATUS:
            return {
                ...state,
                result: action.payload
            }
        default:
            return state
    }
}