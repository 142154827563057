import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, logout, me } from "store/actions/authActions";
import "./index.scss"
import Spinner from "components/elements/spinner/Spinner";

class DemoLoadingPage extends React.Component {

    async componentDidMount() {
        axios.defaults.headers.common["Access-For-Demo-User"] = "accept";
        await this.props.login();
        if (this.props.userData.token && (this.props.userData.user.role === "super_admin" || this.props.userData.user.role === "admin")) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.userData.token;
            localStorage.setItem('user_token', this.props.userData.token);
            const { userData: { user: { role } = {} } = {} } = this.props;
            localStorage.setItem('role', role);
            this.props.history.push("/dashboard");
        } else if (this.props.userData.token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.userData.token;
            localStorage.setItem('user_token', this.props.userData.token);
            this.props.history.push("/charts-tables");
        } else {
            let serverErrorMessage = this.props.errorData;
            if (serverErrorMessage) {
                const errorKey = Object.keys(serverErrorMessage)[0] || "";
                const errorValue = serverErrorMessage[errorKey] || "";
                this.setState({
                    ...this.state,
                    errorIdentifier: errorKey,
                    errorText: errorValue
                });
            }
        }
    }

    render() {
        return (
            <div className="loading-demo-container">
                <Spinner size="small"/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.auth.userData,
        errorData: state.auth.userErrorData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ login, logout, me }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoLoadingPage);
