import { CHANGE_AUTOMATION_DATA, GET_AUTOMATION_DATA, CHANGE_AUTOMATION_DATA_LOADER } from "../../actionTypes";

const defaultState = {
    loader: false,
    data: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_AUTOMATION_DATA_LOADER:
            return { ...state, loader: action.loader }
        case CHANGE_AUTOMATION_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.report_id]: { ...action.data }
                }
            }
        case GET_AUTOMATION_DATA:
            const data = {}
            action.data.forEach((i) => {
                data[i.report_id] = i
            })
            return { ...state, data, envEmail: action.envEmail }
        default: return state
    }
}