import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import lineLineChart from "assets/images/chart-images/line-chart.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
import barHorizontalStackedBarChart from "assets/images/chart-images/horizontal-stacked-bar.png";
import lineMultiLineChart from "assets/images/chart-images/multi-line.png";
import stackedBar from "assets/images/chart-images/stacked-bar.png";
const timePeriodList = [
        { id: "TDIsTP-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-BV02p", name: "simple-bar", chartImage: barSimpleBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-BV03p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-BV04p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-L01p", name: "line", chartImage: lineLineChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTP-T03p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Column", disabled: false },
        { id: "TDIsTP-T04p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Row", disabled: false },
        { id: "TDIsTP-BV05p", name: "simple-bar", chartImage: barSimpleBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-BV06p", name: "simple-bar", chartImage: barSimpleBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-BV07p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-BV08p", name: "simple-bar", chartImage: lineSimpleBarWithCounts, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-BH05p", name: "horizontal-bar", chartImage: barHorizontalBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-L05p", name: "line", chartImage: lineLineChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTP-BXV05p", name: "box", chartImage: boxChartImage, description: "MEDIAN * IQR", disabled: false },
        { id: "TDIsTP-T07p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Column", disabled: false },
        { id: "TDIsTP-T08p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Row", disabled: false },

        { id: "TDIsTPDT-BVS01p", name: "simple-bar", chartImage: stackedBar, description: "AVERAGE", disabled: false },
        { id: "TDIsTPDT-BHS01p", name: "horizontal-bar", chartImage: barHorizontalStackedBarChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTPDT-L01p", name: "line", chartImage: lineMultiLineChart, description: "AVERAGE", disabled: false },
        { id: "TDIsTPDT-T03p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Column", disabled: false },
        { id: "TDIsTPDT-T04p", name: "table", chartImage: table, description: "AVERAGE * STD DEV", v2: "Row", disabled: false },
        { id: "TDIsTPDT-BVS05p", name: "simple-bar", chartImage: stackedBar, description: "MEDIAN", disabled: false },
        { id: "TDIsTPDT-BHS05p", name: "horizontal-bar", chartImage: barHorizontalStackedBarChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTPDT-L05p", name: "line", chartImage: lineMultiLineChart, description: "MEDIAN", disabled: false },
        { id: "TDIsTPDT-T07p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Column", disabled: false },
        { id: "TDIsTPDT-T08p", name: "table", chartImage: table, description: "MEDIAN * IQR", v2: "Row", disabled: false },
];

export default timePeriodList;

