import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";

const decisionTypeList = [
    { id: "#DR#DT-BVS01r", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "#DR#DT-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "#DR#DT-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "#DR#DT-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "#DR#DT-T20r", name: "table", chartImage: table, disabled: false },
];

export default decisionTypeList;
