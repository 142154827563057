//bar
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change-editor.png";


const timePeriodList = [
        { id: "TSAETP-L01r", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE"  },
        { id: "TSAETP-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE"  },
        { id: "TSAETP-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE"  },
        { id: "TSAETP-L05r", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN"  },
        { id: "TSAETP-BXV05r", name: "box-chart", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR"  },
        { id: "TSAETP-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR"  },
        { id: "TSAETP-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR"  },
        { id: "TSAETP-T20r", name: "table", chartImage: table, disabled: false, },
];

export default timePeriodList;
