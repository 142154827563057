//lines
import lineMultiLine          from "assets/images/chart-images/multi-line.png";
import lineWedge              from "assets/images/chart-images/wedge.png";

//bar
import barClusteredBarChart            from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontal100StackedBar      from "assets/images/chart-images/100-horizontal-stacked-bar.png";
import barHorizontalBarChart           from "assets/images/chart-images/horizontal-bar-chart.png";
import barHorizontalStackedBar         from "assets/images/chart-images/horizontal-stacked-bar.png";
import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
import barStachedBar                   from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar                from "assets/images/chart-images/100-stacked-bar.png";

//map
import mapContinents     from "assets/images/chart-images/map/map_continents.png";

//chart
import chart4Items          from "assets/images/chart-images/donut.png";
import chartChange           from "assets/images/chart-images/bubble.png";
import chartScatterPlot     from "assets/images/chart-images/scatter-plot.png";
import table      from "assets/images/chart-images/table.png";

const continentsList = [
    // Continents charts
    {id: 704, name: "bubble", chartImage: chartChange, disabled: true},
    {id: "SCT-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SCT-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SCT-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SCT-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SCT-M01s", name: "continents", chartImage: mapContinents, disabled: false},
    // {id: "SCT-M02s", name: "continents", chartImage: mapContinents, disabled: false},
    {id: "SCT-M01e", name: "continents", chartImage: mapContinents, disabled: false},
    {id: "SCT-M02e", name: "continents", chartImage: mapContinents, disabled: false},
    {id: "SCT-D01s", name: "donut", chartImage: chart4Items, disabled: false},
    // {id: "SCT-D02s", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SCT-D01e", name: "donut", chartImage: chart4Items, disabled: false},
    // {id: "SCT-D02e", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SCT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SCT-T03s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SCT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},

    // Continents - time period charts
    {id: 715, name: "100%-horizontal-stacked", chartImage: barHorizontal100StackedBar, disabled: true},
    {id: "SCTTP-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SCTTP-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SCTTP-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SCTTP-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SCTTP-BHS01s", name: "horizontal-stacked", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SCTTP-BHS01e", name: "horizontal-stacked", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SCTTP-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SCTTP-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SCTTP-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false},
    {id: "SCTTP-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false},
    {id: "SCTTP-W01s", name: "wedge", chartImage: lineWedge, disabled: false},
    {id: "SCTTP-W01e", name: "wedge", chartImage: lineWedge, disabled: false},
    {id: "SCTTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SCTTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},

    // Continents - manuscript types charts
    {id: 725, name: "100%-horizontal-stacked", chartImage: barHorizontal100StackedBar, disabled: true},
    {id: "SCTM-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SCTM-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SCTM-BVS02s", name: "100%-stacked", chartImage: barStacked100Bar, disabled: false},
    {id: "SCTM-BVS02e", name: "100%-stacked", chartImage: barStacked100Bar, disabled: false},
    {id: "SCTM-BHS01s", name: "horizontal-stacked", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SCTM-BHS01e", name: "horizontal-stacked", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SCTM-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SCTM-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SCTM-SC01s", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SCTM-SC01e", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SCTM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SCTM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
];

export default continentsList;