import * as actions from "../actionTypes";
import * as api from "api";
import {changeAxiosToken} from "api"
// Login
function loginSuccess(data) {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: data,
  };
}

function loginError(data) {
  return {
    type: actions.LOGIN_ERROR,
    payload: data,
  };
}

export function meData(data) {
  return {
    type: actions.ME,
    payload: data,
  };
}

export function inviteLoginConfirm(code, password, newPassword) {
  return async (dispatch) => {
    try {
      const { data } =
        (await api.inviteLoginConfirm(code, password, newPassword)) || {};
      localStorage.setItem("user_token", data.token);
      dispatch(loginSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}
export function forgotInvitePassword(code, password) {
  return async (dispatch) => {
    try {
      const { data } =
        (await api.forgotInvitePassword(code, password)) || {};
      localStorage.setItem("user_token", data.token);
      dispatch(loginSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function inviteLogin(email) {
  return async (dispatch) => {
    try {
      const { data } = (await api.inviteLogin(email)) || {};
      localStorage.setItem("user_token", data.token);
      dispatch(loginSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function login(email, password) {
  return async (dispatch) => {
    try {
      const { data } = (await api.login(email, password)) || {};
      dispatch(loginSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function loginAcount(email, id, admin, history) {
  return async (dispatch) => {
    try {
      if (admin) {
        const { data } = (await api.loginAdminAcount(email, admin, id)) || {};
        localStorage.setItem("user_token", data.token);
        dispatch(loginSuccess(data));
        if (data.user.role === "super_admin") {
          history.push("/dashboard");
        } else {
          history.push("/charts-tables");
        }
      } else {
        const { data } = (await api.loginInviteAcount(email, id)) || {};
        localStorage.setItem("user_token", data.token);
        changeAxiosToken(data.token)
        dispatch(loginSuccess(data));
        history.push("/charts-tables");
      }

      // if (navigator.userAgent.indexOf("Firefox") !== -1) {
      //   setTimeout(() => {
      //     window.location.reload(true)
      //    }, 100);
      // } else {
      //   window.location.reload(true)
      // }

     localStorage.setItem("acceptedCookiePolicyPrompt",true)
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function accounts(email, password, history, isSavePassword) {
  return async (dispatch) => {
    try {
      const { data } = (await api.accounts(email, password, isSavePassword)) || {};
      dispatch({
        type: actions.SITE_ACOUNTS,
        data: data.accounts,
      });
      localStorage.setItem("user_token", data.token);
      data.accounts.length > 0 && history.push("/my-accounts-page");
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function googleAccounts(email, history) {
  return async (dispatch) => {
    try {
      const { data } = (await api.googleAccounts(email)) || {};
      dispatch({
        type: actions.SITE_ACOUNTS,
        data: data.accounts,
      });
      localStorage.setItem("user_token", data.token);
      data.accounts.length > 0 && history.push("/my-accounts-page");
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

export function me(token) {
  return async (dispatch) => {
    try {
      const { data } = (await api.me()) || {};
      console.log("🚀 ~ file: authActions.js ~ line 44 ~ me ~ data", data);
      if (data["isBlocked"]) {
        dispatch(logout());
      } else {
        const finalData = {
          token,
          user: {
            id: data["id"],
            name: data["name"],
            email: data["email"],
            role: data["role"],
            avatar: data["avatar"],
            avatar_full_path: data["avatar_full_path"],
            chartsStats: data["chartsStats"],
            loginFrequency: data["loginFrequency"],
            accountType: data["accountType"],
            priceId: data["priceId"],
            customer: data["customer"],
            status: data["status"],
            coupon: data["coupon"],
            totalAmount: data["totalAmount"],
            paymentsCount: data["paymentsCount"],
            favoritesCount: data["favoritesCount"],
            demoUser: data.demoUser,
            AutomationPopUp: data.AutomationPopUp,
            organizationName:data.organizationName,
            selectChartModal: data.selectChartModal
          },
        };
        if (data.inviteUser) {
          dispatch(
            meData({
              ...finalData,
              user: {
                ...finalData.user,
                inviteUser: data.inviteUser,
                inviteUser_email: data.inviteUser_email,
                inviteUser_id: data.inviteUser_id,
                inviteUser_name: data.inviteUser_name,
                inviteUser_role: data.inviteUser_role,
                inviteUser_active: data.inviteUser_active,
                inviteUser_data: data.inviteUser_data,
              },
            })
          );
        } else {
          dispatch(meData(finalData));
        }
      }
    } catch (error) {
      console.log(error);
      window.location = '/login'
    }
  };
}

export function logout() {
  return {
    type: actions.LOGOUT,
  };
}

function confirmNewEmailSuccess(data) {
  return {
    type: actions.CONFIRM_NEW_EMAIL,
    payload: data,
  };
}

export function confirmNewEmail(code) {
  return async (dispatch) => {
    try {
      const { data } = (await api.confirmNewEmail(code)) || {};
      dispatch(confirmNewEmailSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(loginError(data));
    }
  };
}

function setPasswordSuccess(data) {
  return {
    type: actions.SET_PASSWORD_SUCCESS,
    payload: data,
  };
}

function setPasswordError(data) {
  return {
    type: actions.SET_PASSWORD_ERROR,
    payload: data,
  };
}

export function setPassword(code) {
  return async (dispatch) => {
    try {
      const { data } = (await api.setPassword(code)) || {};
      dispatch(setPasswordSuccess(data));
    } catch (error) {
      const { response: { data } = {} } = error;
      dispatch(setPasswordError(data));
    }
  };
}