//bar
import boxChartImage from "assets/images/chart-images/box-whisker.png";

//lines
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const revisionNumberList = [
    { id: "TSAER#-BV01r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
    { id: "TSAER#-BV04r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
    { id: "TSAER#-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TSAER#-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TSAER#-BV05r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
    { id: "TSAER#-BV08r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
    { id: "TSAER#-BXV05r", name: "box-chart", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR"  },
    { id: "TSAER#-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
    { id: "TSAER#-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
    { id: "TSAER#-T20r", name: "table", chartImage: table, disabled: false, description: "" },
];

export default revisionNumberList;
