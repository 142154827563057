import React from "react";
import "./styles.scss";

const Spinner = ({ size = "small" }) => {
  const strokeWidth = 6;
  const radius = 30;
  const viewBoxDimension = 2 * (radius + strokeWidth);

  const sizeMap = {
    smallest: "30px",
    small: "48px",
    medium: "54px",
    big: "58px",
  };

  const containerStyle = {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: sizeMap[size] === 'big' ? "100vh" : "auto",
  };

  return (
    <div style={containerStyle}>
      <svg
        className="spinner"
        width={sizeMap[size]}
        height={sizeMap[size]}
        viewBox={`0 0 ${viewBoxDimension} ${viewBoxDimension}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="loading-path"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
        ></circle>
      </svg>
    </div>
  );
};

export default Spinner;
