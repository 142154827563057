import lineLineChart from "assets/images/chart-images/line-chart.png";
import lineMultiLine from "assets/images/chart-images/multi-line.png";
import table from "assets/images/chart-images/table.png";

const invitedToCompleted = [
    {id: "ICCTP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
    
    {id: "ICCTP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
    {id: "ICCTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "ICCTP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
    
    {id: "ICCTP-L01r", name: "line", chartImage: lineLineChart, disabled: false},
    {id: "ICCTP-T01r", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "ICCTP-T02r", name: "table", chartImage: table, v2: "Row", disabled: false},

    {id: "ICCTPRT-L01r", name: "multi-line", chartImage: lineMultiLine, disabled: false},
    {id: "ICCTPRT-T01r", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "ICCTPRT-T02r", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default invitedToCompleted;
