import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import Donut from "assets/images/chart-images/donut.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
const primaryCategoryList = [

        { id: "SPC-BV01p", name: "stacked-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "SPC-BH01p", name: "horizontal-stacked-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "SPC-D01p", name: "donut", chartImage: Donut, disabled: false },
        { id: "SPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "SPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default primaryCategoryList;
