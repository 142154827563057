//chart
import chart4Items from "assets/images/chart-images/donut.png";
import bubble from "assets/images/chart-images/bubble.png";
import chartTree from "assets/images/chart-images/vertical-tree.png";

// Decision types - None

const decisionTypesList = [
        // Decision types - None
        {id: "DIDT-DTV01s", name: "vertical-decision-tree-initial", chartImage: chartTree, disabled: false},
        {id: "DIDT-DTV01e", name: "vertical-decision-tree-initial", chartImage: chartTree, disabled: false},
        {id: "DIDT-DTH01s", name: "horizontal-decision-tree-initial", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTH01e", name: "horizontal-decision-tree-initial", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTV02s", name: "vertical-decision-tree-review", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTV02e", name: "vertical-decision-tree-review", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTH02s", name: "horizontal-decision-tree-review", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTH02e", name: "horizontal-decision-tree-review", chartImage: chartTree, disabled: true},
        {id: "DIDT-D01s", name: "donut", chartImage: chart4Items, disabled: true},
        {id: "DIDT-D01e", name: "donut", chartImage: chart4Items, disabled: true},
        {id: "DIDT-BB01s", name: "bubble", chartImage: bubble, disabled: true},
        {id: "DIDT-BB01e", name: "bubble", chartImage: bubble, disabled: true},
];

export default decisionTypesList;
