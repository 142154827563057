//bar
import barClusteredBarChart            from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontal100StackedBar      from "assets/images/chart-images/100-horizontal-stacked-bar.png";
import barHorizontalBarChart           from "assets/images/chart-images/horizontal-bar-chart.png";
import barHorizontalStackedBar         from "assets/images/chart-images/horizontal-stacked-bar.png";
import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
import barStachedBar                   from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar                from "assets/images/chart-images/100-stacked-bar.png";

//chart
import chart4Items          from "assets/images/chart-images/donut.png";
import chartChange           from "assets/images/chart-images/bubble.png";
import chartScatterPlot     from "assets/images/chart-images/scatter-plot.png";
import table      from "assets/images/chart-images/table.png";



const manuscriptTypesList = [
    // Manuscript types - None
    {id: "SM-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SM-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SM-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SM-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SM-D01s", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SM-D02s", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SM-D01e", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SM-D02e", name: "donut", chartImage: chart4Items, disabled: false},
    {id: "SM-BB01s", name: "bubble", chartImage: chartChange, disabled: false},
    {id: "SM-BB01e", name: "bubble", chartImage: chartChange, disabled: false},
    {id: "SM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},

    // Manuscript types - Time period charts
    {id: 805, name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true},
    {id: "SMTP-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMTP-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMTP-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMTP-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMTP-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMTP-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMTP-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMTP-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMTP-SC01s", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMTP-SC01e", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SMTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},

    // Manuscript types - Countries charts
    {id: 815, name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true},
    {id: "SMC-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMC-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMC-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMC-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMC-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMC-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMC-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMC-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMC-SC01s", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMC-SC01e", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SMC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},

    // Manuscript types - Continents charts
    {id: 825, name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true},
    {id: "SMCT-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMCT-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false},
    {id: "SMCT-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMCT-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false},
    {id: "SMCT-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMCT-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false},
    {id: "SMCT-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMCT-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "SMCT-SC01s", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMCT-SC01e", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false},
    {id: "SMCT-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SMCT-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
];

export default manuscriptTypesList;
