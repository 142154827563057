//lines
import lineSimpleBarChart     from "assets/images/chart-images/simple-bar-change.png";
import lineLineChart          from "assets/images/chart-images/line-chart.png";
//bar
import barHorizontalBarChart           from "assets/images/chart-images/horizontal-bar-chart.png";
import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
//map
import mapUsRegions     from "assets/images/chart-images/map/map_us_regions.png";
import mapUsStates      from "assets/images/chart-images/map/map_us_states.png";

//chart
import table      from "assets/images/chart-images/table.png";

const countriesList = [
    // Single Country - time period charts
    {id: 655, name: "us-map", chartImage: mapUsStates, disabled: true},
    {id: 656, name: "us-map", chartImage: mapUsRegions, disabled: true},
    {id: "SCiTP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
    {id: "SCiTP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
    {id: "SCiTP-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SCiTP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SCiTP-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SCiTP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
    {id: "SCiTP-BV02s", name: "bar-%-change", chartImage: lineSimpleBarChart, disabled: false},
    {id: "SCiTP-BV02e", name: "bar-%-change", chartImage: lineSimpleBarChart, disabled: false},
    {id: "SCiTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SCiTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
];

export default countriesList;
