import {SITE_ACOUNTS } from "../../actionTypes";

const defaultState = {
    data: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SITE_ACOUNTS: {
            const { data } = action;
            return {
                ...state,
                data: data
            }
        }
        default:
            return state;
    }
};
