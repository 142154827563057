//chart
import chart4Items from "assets/images/chart-images/donut.png";
import mapWorldMap       from "assets/images/chart-images/map/map_world_map.png";
import table      from "assets/images/chart-images/table.png";
const countryEditor = [
        // performance - none
        {id: "#ECE-D01e", name: "donut", chartImage: chart4Items, disabled: false},
        {id: "#ECE-D01s", name: "donut", chartImage: chart4Items, disabled: false},

        {id: "#ECE-D02e", name: "donut", chartImage: chart4Items, disabled: false},
        {id: "#ECE-D02s", name: "donut", chartImage: chart4Items, disabled: false},

        {id: "#ECE-M01s", name: "map", chartImage: mapWorldMap, disabled: false}, 
        {id: "#ECE-M02s", name: "map", chartImage: mapWorldMap, disabled: false}, 

        {id: "#ECE-M01e", name: "map", chartImage: mapWorldMap, disabled: false},
        {id: "#ECE-M02e", name: "map", chartImage: mapWorldMap, disabled: false}, 

        {id: "#ECE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "#ECE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default countryEditor;
