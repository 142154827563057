import React from "react";
import { Route } from "react-router-dom";
import Footer from "components/modules/main-footer";

const HelpCenterRoute = ({ component: Component, hideFooter, ...rest }) => {

    const landing = rest.path === "/"
    const updatedDesign = ["/login", "/forgot-password", "/change-password/:code"].includes(rest.path);
    const pdfpreview = rest.path.split("/").slice(1,2).join("/")==='pdfpreviewcontainer'

    return (
        <div className="public-page">
            <div className="public-main">
            <Route
                {...rest}
                render={props => {
                    return <Component {...rest} {...props}/>;
                }}
                />
            </div>
            {!pdfpreview && !hideFooter && <div className="public-footer">
                <Footer landing={landing} updatedDesign={updatedDesign} {...rest}/>
            </div>}
        </div>
    );
};



export default HelpCenterRoute;