// Reviewer Response Time (days) - Invitation Outcome
import lineLineChart from "assets/images/chart-images/line-chart.png";

const performanceList = [
        // Reviewer Response Time (days) - Invitation Outcome
        { id: "#RITPOIA-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AGREED-DECLINE" },
        { id: "#RITPOIA-L01s", name: "line", chartImage: lineLineChart, disabled: false },
];

export default performanceList;
