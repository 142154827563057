import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";


const countriesList = [
        { id: "DFdCDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFdCDT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DFdCDT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFdCDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFdCDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default countriesList;
