import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";

const countriesList = [
        { id: "%RFdC-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "%RFdC-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "%RFdC-M01p", name: "line", chartImage: mapWorldMap, disabled: false },
        { id: "%RFdC-M02p", name: "line", chartImage: mapWorldMap, disabled: false },
        { id: "%RFdC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "%RFdC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "%RFdPC-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "%RFdPC-BH01p", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
        { id: "%RFdPC-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "%RFdPC-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default countriesList;

