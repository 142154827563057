/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CookiePolicyPrompt from "components/modules/cookie-policy-prompt";
import { toast } from "react-toastify";
import cx from "classnames";
import "./style.scss";
import { useNotify } from "utils/notify";
 
 function setCookie(name, value, expirydays) {
  var d = new Date();
  d.setTime(d.getTime() + (expirydays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = name + "=" + value + "; " + expires;
 }
 
 function deleteCookie(name){
   setCookie(name,"",-1);
 }

 function deleteAllCookies(){
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++){
        deleteCookie(cookies[i].split("=")[0]);
    }
 }

const MainFooter = ({landing, updatedDesign, ...rest}) => {
    const [showCookiePolicyPrompt, setCookiePolicyPrompt] = useState(true);
    const [acceptedCookiePolicyPromptState, setAcceptedCookiePolicyPrompt] = useState(localStorage.acceptedCookiePolicyPrompt);
    const [declineCookiePolicyPromptState, setDeclineCookiePolicyPrompt] = useState(localStorage.declineCookiePolicyPrompt);

    const {notifySuccess } = useNotify()

    useEffect(() => {
        setCookiePolicyPrompt(true);
    }, [rest.cookiePolicyPrompt?.result])

    useEffect(() => {
        setAcceptedCookiePolicyPrompt(localStorage.acceptedCookiePolicyPrompt);
    }, [localStorage.acceptedCookiePolicyPrompt])

    useEffect(() => {
        setDeclineCookiePolicyPrompt(localStorage.declineCookiePolicyPrompt);
    }, [localStorage.declineCookiePolicyPrompt])

    const year = new Date().getFullYear();

    function deleteCookies() {
        deleteAllCookies();
    }

    const { acceptedCookiePolicyPrompt = false } = localStorage;

    const handleCookieAction = (type) => {
        setCookiePolicyPrompt(false);

        if (type === "allow") {
            localStorage.setItem("acceptedCookiePolicyPrompt", true);
        } else if (type === "decline") {
            deleteCookies();
            localStorage.setItem("declineCookiePolicyPrompt", true);
            if(!document.__defineGetter__) {
                Object.defineProperty(document, 'cookie', {
                    get: function(){return ''},
                    set: function(){return true},
                });
            } else {
                document.__defineGetter__("cookie", function() { return '';} );
                document.__defineSetter__("cookie", function() {} );
            }
            notifySuccess("Cookies have been deleted from your computer");
        }
    };

    const showCookiePopup = () => {
        if (acceptedCookiePolicyPrompt) {
            localStorage.removeItem("acceptedCookiePolicyPrompt");
            setAcceptedCookiePolicyPrompt(false);
        } else {
            localStorage.removeItem("declineCookiePolicyPrompt");
            setDeclineCookiePolicyPrompt(false);
        }

        setCookiePolicyPrompt(true);
    };

    return showCookiePolicyPrompt &&
        !acceptedCookiePolicyPromptState &&
        !declineCookiePolicyPromptState ? (
        <CookiePolicyPrompt handleAction={handleCookieAction} />
    ) : (
        <div className={`${updatedDesign && "main-footer-container-updated"} main-footer-container`} style={landing ? { backgroundColor: "#FFFFFF" } : null}>
            {/* <div className={cx("links-body", landing && "links-body-landing")}> */}
            <div className={cx(landing ? "links-body-landing" : "links-body")}>
                <a
                    href="/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms of Use
                </a>
                <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Policy
                </a>
                <a
                    href="/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Cookie Policy
                </a>
                <span onClick={showCookiePopup}>Cookie Settings</span>
                {/* {!landing && <a
                    href="https://help.originreports.org/en/articles/4597238-gdpr"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    GDPR
                </a>} */}
            </div>
            {/* {landing && (
                //   <div className='landing-text'>
                //      <span>Origin Editorial, LLC</span>
                //      <span>14274 W. 88th Drive, Unit A</span>
                //      <span>Arvada, CO 80005</span>
                //   </div>
                <div
                style={{
                  height: "34.01px",
                  width: "88px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${logo}")`,
                }}
                />
            )} */}
            <p className="info-text">© Origin Reports, LLC {year}</p>
        </div>
    );
};

export default MainFooter;

