import React, { useEffect, useState } from 'react'
import "./style.scss";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const AccountNavbar = ({
    title,
    subtitle,
    auth: {
        userData: { user, user: { id, avatar, accountType, avatar_full_path } = {} } = {},
    } = {},
    showCookiePopup,
    demoUser,
    changeCookiePolicyPromptAction,
    history
}) => {
    const [superAdmin, setSuperAdmin] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        setSuperAdmin(changeCookiePolicyPromptAction === undefined)
    }, [changeCookiePolicyPromptAction])
    useEffect(() => {
        if (!(changeCookiePolicyPromptAction === undefined)) {
            dispatch(changeCookiePolicyPromptAction(false))
        }
    }, [changeCookiePolicyPromptAction])
    const toRedirect = (path) => {
        history.push("/" + path)
    }

    return (
        <div className="account-navbar">
            {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id && id !== 1 && (
                <div className={`account-navbar-item ${location.pathname.split('/').includes('my-account') && "account-navbar-active"} `} onClick={() => toRedirect("my-account")}>
                    My Account
                </div>
            )}
            {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id &&
                id !== 1 &&
                (!user.inviteUser_role ||
                    user.inviteUser_role === "admin") && (
                    <div className={`account-navbar-item ${(location.pathname.split('/').includes('myteam') || location.pathname.split('/').includes('myteam-all-reports')  || location.pathname.split('/').includes('organization-settings')) && "account-navbar-active"} `} onClick={() => toRedirect("myteam")}>
                        <span> My Team 
                              {/* <p style={{
                            fontSize: "16px",
                            fontWeight: "400",
                        }}> {user.organizationName} </p> */}
                        </span>
                    </div>
                )}
            {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id &&
                id !== 1 &&
                (
                    <div className={`account-navbar-item ${location.pathname.split('/').includes('change-organization') && "account-navbar-active"} `} onClick={() => toRedirect("change-organization")}>
                        Change Organization
                    </div>
                )}
            {id && id !== 1 && accountType !== "unlimited" && (
                <div className={`account-navbar-item ${location.pathname.split('/').includes('subscription') && "account-navbar-active"} `} onClick={() => toRedirect("subscription")}>
                    Subscription & Billing 
                </div>
            )}
            {!localStorage.getItem("invite") && <div className={`account-navbar-item ${location.pathname.split('/').includes('my-organization') && "account-navbar-active"} `} onClick={() => toRedirect("my-organization")}>
                My Organization 
            </div>}
            {id && id !== 1 && (
                <div className={`account-navbar-item ${location.pathname.split('/').includes('my-preferences') && "account-navbar-active"} `} onClick={() => toRedirect("my-preferences")}>
                    My Preferences 
                </div>
            )}
            <div
            className={`account-navbar-item`}
                onClick={() => {
                    window.open("/terms-of-use", "_blank");
                }}
            >
                {/* <a href="#no-data" rel="noopener noreferrer" >
                    <div>Terms and policies</div>
                </a> */}
                Terms and policies
            </div>
            {!superAdmin && (
                <div className={`account-navbar-item`} onClick={() => showCookiePopup()}>
                    <span> Cookie preferences</span>
                </div>
            )}
        </div>
    )
}

export default AccountNavbar;