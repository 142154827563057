import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./style.scss";
import Content from "./content";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement("div");
        this.el.className = `modal-container ${props.bottom ? "modal-container-bottom" : ""}`;
        const body = document.querySelector("body");
        this.modalRoot = document.querySelector(".modals-root");
        body.style.overflow = "hidden";
        this.modalRoot = document.createElement("div");
        this.modalRoot.className = `${props.className} modals-root ${props.darkBackground ? "modals-root-dark" : ""}`;
        body.appendChild(this.modalRoot);
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
        window.addEventListener("click", this.onClick, true);
        document.addEventListener("keydown", this.handleEscape);
        const firstInput = this.el.querySelectorAll("input")[0];
        if (firstInput) {
            firstInput.focus();
        }
    }

    componentWillUnmount() {
        const body = document.querySelector("body");
        body.removeChild(this.modalRoot);
        body.style.overflow = "auto";
        window.removeEventListener("click", this.onClick, true);
        document.addEventListener("keydown", this.handleEscape);
    }

    handleEscape = e => {
        if (e.keyCode === 27) {
            this.onClose();
        } else if (e.keyCode === 13) {
            this.props.handleButtonClick(this.props.buttonType);
        }
    }

    onClick = e => {
        const { onClose, closeOnClickOutside } = this.props;
        // Close modal on click outside
        if (
            e.target.closest(".modal-container") === null &&
            typeof onClose === "function" &&
            closeOnClickOutside
        ) {
            let modalsList = document.querySelectorAll(".modals-root");
            onClose(modalsList.length);
        }
    };

    onClose() {
        const { onClose } = this.props;
        let modalsList = document.querySelectorAll(".modals-root");
        onClose(modalsList.length);
    }

    render() {
        const {
            children,
            styles,
            handleButtonClick,
            handleSecondButtonClick,
            disabledBtn,
            icon,
            svgIcon,
            two
        } = this.props;

        return ReactDOM.createPortal(
            <Content
                styles={styles}
                onClose={() => this.onClose()}
                handleButtonClick={handleButtonClick}
                handleSecondButtonClick={handleSecondButtonClick}
                disabled={disabledBtn}
                icon={icon}
                svgIcon={svgIcon}
                two={two}
            >
                {children}
            </Content>,
            this.el
        );
    }
}

Modal.defaultProps = {
    disabledBtn: false,
    closeOnClickOutside: false,
    darkBackground: false,
    styles: {},
    onClose: () => { },
    handleButtonClick: () => { },
    handleSecondButtonClick: () => { }
};

Modal.propTypes = {
    disabledBtn: PropTypes.bool,
    children: PropTypes.node,
    styles: PropTypes.object,
    closeOnClickOutside: PropTypes.bool,
    darkBackground: PropTypes.bool,
    onClose: PropTypes.func,
    handleButtonClick: PropTypes.func,
    handleSecondButtonClick: PropTypes.func
};

export default Modal;
