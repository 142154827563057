
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";


const revisionNumberList = [
        { id: "SRAR#SN-BVS01r", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "SRAR#SN-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "SRAR#SN-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "SRAR#SN-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "SRAR#SN-T20r", name: "table", chartImage: table, disabled: false },

];

export default revisionNumberList;
