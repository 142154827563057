import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route } from "react-router-dom";
import Footer from "components/modules/main-footer";
import { changeCookiePolicyPromptAction } from "store/actions/cookiePolicyPromptAction";
import MailTo from "components/modules/mailto";

const PublicRoute = ({ component: Component, hideFooter, ...rest }) => {
    const showCookiePopup = () => {
        const { acceptedCookiePolicyPrompt = false } = localStorage;

        if (acceptedCookiePolicyPrompt) {
            localStorage.removeItem("acceptedCookiePolicyPrompt");
        } else {
            localStorage.removeItem("declineCookiePolicyPrompt");
        }
        rest.changeCookiePolicyPromptAction(!rest.cookiePolicyPrompt.result);
    };

    const landing = rest.path === "/"
    const updatedDesign = ["/login", "/forgot-password", "/change-password/:code"].includes(rest.path);
    const pdfpreview = rest.path.split("/").slice(1, 2).join("/") === 'pdfpreviewcontainer'

    return (
        <div className="public-page">
            <div className="public-main">
                <Route
                    {...rest}
                    render={props => {
                        return <Component {...rest} {...props} showCookiePopup={showCookiePopup} />;
                    }}
                />
            </div>
            {!pdfpreview && !hideFooter && <div className="public-footer">
                <Footer landing={landing} updatedDesign={updatedDesign} {...rest} />
            </div>}
            {!pdfpreview && !hideFooter && <MailTo />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cookiePolicyPrompt: state.cookiePolicyPrompt,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ changeCookiePolicyPromptAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);