import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
const invitedToAgreed = [
         {id: "IACTP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
         {id: "IACTP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
         {id: "IACTP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
         {id: "IACTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
         {id: "IACTP-L01r", name: "line", chartImage: lineLineChart, disabled: false},
         {id: "IACTP-T01r", name: "table", chartImage: table, v2: "Column", disabled: false},
         {id: "IACTP-T02r", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default invitedToAgreed;
