import React from "react";
import PropTypes from "prop-types";
import Button from "components/elements/button";
import "./style.scss";

const CookiePolicyPrompt = ({ handleAction, style = {} }) => {
    return (
        <div style={style} className="cookie-main-container">
            <div className="cookie-container">
                <div className="info-text">
                    <span>
                        By clicking “Allow”, you agree to the storing of cookies
                        on your device to enhance site navigation, analyze site
                        usage, <br />
                        and assist in our marketing efforts{" "}
                        <a
                            href="/cookie-policy"                                
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Cookie Policy
                        </a>
                        .{" "}
                    </span>
                    <span className="reject-cookies" onClick={() => handleAction("decline")}>
                        Reject Cookies
                    </span>
                </div>
                <div className="allow-side">
                    <Button
                        name="ALLOW"
                        handleButtonClick={() => handleAction("allow")}
                    />
                </div>
            </div>
        </div>
    );
};

CookiePolicyPrompt.defaultProps = {
    handleAction: () => {},
};

CookiePolicyPrompt.propTypes = {
    handleAction: PropTypes.func,
};

export default CookiePolicyPrompt;
