import { CHANGE_REPORTTYPE } from '../actions/reportTypeActions';

const defaultState = {
    result: null,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_REPORTTYPE:
            return {
                ...state,
                result: action.payload
            }
        default:
            return state
    }
}