import React, { useRef } from "react";
import Button from "components/elements/button";
import "./index.scss";

const ShowMessageWhenClickingOnStandardReports = () => {
  const ref = useRef('')
  const modalToggle = () => {
    ref.current && ref.current.classList.toggle('standart-reports-wrapper-none')
    localStorage.setItem("ShowMessageWhenClickingOnStandardReports", true)
  }
  return (
    <div className='standart-reports-wrapper' ref={ref}>
      <div className="standart-reports-alert-message-container">
        <span className="standart-reports-description">
          Note: Standard Reports cannot be edited.
          If you would like to make changes to charts in this report, plase click the Save As New Report button to make an editable copy of the report.
        </span>
        <div className="standart-reports-footer">
          <div className="standart-reports-button">
            <Button
              name="Okay"
              type={1}
              handleButtonClick={() => modalToggle()}
              style={{ height: "36px", width: "86px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowMessageWhenClickingOnStandardReports