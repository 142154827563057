import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
import lineMultiLine from "assets/images/chart-images/multi-line.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";

const timePeriodList = [
        { id: "DFsTPDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFsTPDT-BVS02p", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
        { id: "DFsTPDT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DFsTPDT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFsTPDT-L01p", name: "multi-line", chartImage: lineMultiLine, disabled: false },
        { id: "DFsTPDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFsTPDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default timePeriodList;

