import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "components/modules/layout";

/******* Redux actions ********************************************/
import { me } from "store/actions/authActions";
/******************************************************************/

const menuList = [
    { icon: "home", path: "/dashboard", title: "Dashboard" },
    { icon: "members", path: "/subscribers", title: "Organizations & users" },
    { icon: "chart", path: "/charts-tables", title: "Charts" },
    { icon: "activity", path: "/activity", title: "Activity" },
    { icon: "empty", path: "/dummy-datas", title: "Dummy datas" },
    { icon: "direct-delivery", path: "/direct-delivery", title: "Direct delivery" },

    // { icon: "radio-off", path: "/coupons", title: "Coupons" }
];

class SuperAdminRoute extends Component {
    componentDidMount() {
        const token = (sessionStorage.getItem("user_token") || localStorage.getItem("user_token"));
        if (token) {
            if (Object.getOwnPropertyNames(this.props.auth.userData).length === 0) {
                this.props.me(token);
            }
        }
    }

    render() {
        const { component: Component, auth, ...rest } = this.props;

        if (!(sessionStorage.getItem("user_token") || localStorage.getItem("user_token"))) {
            return <Redirect to="/login" />;
        } else {
            if (Object.getOwnPropertyNames(this.props.auth.userData).length > 0) {
                if (auth.userData.user.role === "user") {
                    return <Redirect to="/" />;
                }
            } else {
                return null;
            }
        }

        return (
            <Route
                {...rest}
                render={props => {
                    return (
                        <Layout
                            menuList={menuList}
                            title={rest.path === "/direct-delivery" ? "Direct Delivery" : "Super Admin"}
                            {...props}
                        >
                            <Component {...rest} {...props} />
                        </Layout>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ me }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminRoute);
