import * as actions from "store/actionTypes";

const defaultState = {
    individualUsers: [],
    organizations: [],
    organizationsErrorData: "",
    addIndividualUserErrors: {},
    addOrganizationErrors:{},
    isLoading: false,
    regenerateAutomation: []
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case actions.GET_INDIVIDUALS_REQUEST:
            return {
                ...state,
                isLoading: true,
                organizations:[]
            };
        case actions.GET_INDIVIDUALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                individualUsers: action.payload,
                organizations:[]
            };    
        case actions.GET_INDIVIDUALS_ERROR:
            return {
                ...state,
                isLoading: false,
                organizations:[]
            };

        case actions.ADD_INDIVIDUAL_USER_SUCCESS:
            const newIndividualUsers = [...state.individualUsers];
            newIndividualUsers.push(action.payload);

            return {
                ...state,
                individualUsers: newIndividualUsers,
                addIndividualUserErrors: {},
            };
        case actions.ADD_INDIVIDUAL_USER_ERROR:
            return {
                ...state,
                addIndividualUserErrors: action.payload
            };
        case actions.GET_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload,
                organizationsErrorData: "",
                individualUsers:[]
            };
        case actions.ORGANIZATIONS_ERROR:
            return {
                ...state,
                organizations: [],
                organizationsErrorData: action.payload
            };
        case actions.SEARCH_INDIVIDUAL_USERS_SUCCESS:
            return {
                ...state,
                individualUsers: action.payload
            };
        case actions.ADD_ORGANIZATION_SUCCESS:
                const newOrganization = [...state.organizations];
                newOrganization.push(action.payload);
            return {
                ...state,
                organizations: newOrganization,
                addOrganizationErrors:{},
            };
        case actions.ADD_ORGANIZATION_ERROR:
            return {
                ...state,
                addOrganizationErrors: action.payload,
            };
        case actions.SEARCH_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: action.payload,
            };
        case actions.GET_AUTOMATION_REGENERATE_DATA: 
            return {
                ...state,
                regenerateAutomation: action.payload
            }
        default:
            return state;
    }
};
