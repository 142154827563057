import React, { useEffect, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "components/modules/main-footer";
import Spinner from "components/elements/spinner/Spinner";
import * as api from "api";

const AccountsRoute = ({ component: Component, ...rest }) => {
  const accounts_fromStore = useSelector((state) => state.accounts.data);
  const [fetchedAccounts, setFetchedAccounts] = useState(null);

  useEffect(() => {
    if (accounts_fromStore.length === 0 && fetchedAccounts === null) {
      async function fetchData() {
        try {
          const { data } = await api.me();
          setFetchedAccounts(data.accounts || []);
        } catch (error) {
          console.error(error);
          setFetchedAccounts([]);
        }
      }
      fetchData();
    }
  }, [accounts_fromStore, fetchedAccounts]);

  let content;
  if (accounts_fromStore.length > 0) {
    content = (
      <Route
        {...rest}
        render={(props) => (
          <Component accounts={accounts_fromStore} {...rest} {...props} />
        )}
      />
    );
  } else if (fetchedAccounts) {
    content =
      fetchedAccounts.length > 0 ? (
        <Route
          {...rest}
          render={(props) => (
            <Component accounts={fetchedAccounts} {...rest} {...props} />
          )}
        />
      ) : (
        <Redirect to="/login" />
      );
  } else {
    content = (
      <div className="loading-demo-container">
        <Spinner size="big" />
      </div>
    );
  }

  return (
    <div className="accounts-page">
      <div className="accounts-main">{content}</div>
      <Footer {...rest} />
    </div>
  );
};

export default withRouter(AccountsRoute);
