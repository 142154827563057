//lines
import lineLineChart          from "assets/images/chart-images/line-chart.png";
import table      from "assets/images/chart-images/table.png";
const timePeriodList = [
        // Time period - None
        {id: "TDIATP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "TDIATP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "TDIATP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},

];

export default timePeriodList;
