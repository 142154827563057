import lineMultiLine from "assets/images/chart-images/multi-line.png";
import table from "assets/images/chart-images/table.png";


const reviewTypeByTimePeriodList = [
        { id: "TCRTPRT-L01r", name: "line", chartImage: lineMultiLine, disabled: false, description: "AVERAGE"  },
        { id: "TCRTPRT-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE"  },
        { id: "TCRTPRT-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE"  },
        { id: "TCRTPRT-L05r", name: "line", chartImage: lineMultiLine, disabled: false, description: "MEDIAN"  },
        { id: "TCRTPRT-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN"  },
        { id: "TCRTPRT-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN"  },
        { id: "TCRTPRT-T20r", name: "table", chartImage: table, disabled: false, description: ""  },
];

export default reviewTypeByTimePeriodList;
