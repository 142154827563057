import table from "assets/images/chart-images/table.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";

const associateEditorsReviewType = [
        // Associate Editor - Review Type
        { id: "#IAERT-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "#IAERT-T01r", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "#IAERT-T02r", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "#IAERT-T91r", name: "table", chartImage: table, v2: "Column", disabled: false },
];

export default associateEditorsReviewType;