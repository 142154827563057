import { GET_JOURNAL_NAMES, CHANGE_JOURNAL_NAME } from "../../actionTypes";

const defaultState = {
    JournalNames: {
        EM: [
            //  { id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 1 }
        ],
        S1: [
            //{ id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 0 }
        ]
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_JOURNAL_NAMES:
            const EM = []
            const S1 = []
            action.data.journalNames.forEach((item) => {
                if (item.manuscriptSystem === 1) {
                    EM.push(item)
                }
                else {
                    S1.push(item)
                }
            })
            // if (EM.length === 0) {
            //     EM.push({ id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 1 })
            // }
            // if (S1.length === 0) {
            //     S1.push({ id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 0 })
            // }
            return { ...state, JournalNames: { EM: EM, S1: S1 } }
        case CHANGE_JOURNAL_NAME:
            // if (action.data.EM.length === 0) {
            //     action.data.EM.push({ id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 1 })
            // }
            // if (action.data.S1.length === 0) {
            //     action.data.S1.push({ id: 1, oldJurnalName: "", newJurnalName: "", manuscriptSystem: 0 })
            // }
            return { ...state, JournalNames: { EM: action.data.EM, S1: action.data.S1 } }


        default: return state
    }
}