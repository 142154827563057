import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";


const continentsList = [
        { id: "DFsCTDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFsCTDT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DFsCTDT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFsCTDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFsCTDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },

        { id: "DFsCTCDT-BVS01p", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
        { id: "DFsCTCDT-BHS01p", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
        { id: "DFsCTCDT-CC01p", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "DFsCTCDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "DFsCTCDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default continentsList;
