import table  from "assets/images/chart-images/table.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";

const reviewerTypeList = [
    {id: "#RTPRT-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false},
    {id: "#RTPRT-T01r", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "#RTPRT-T02r", name: "table", chartImage: table, v2: "Row", disabled: false},
    {id: "#RTPRT-T20r", name: "table", chartImage: table, disabled: false},
];

export default reviewerTypeList;