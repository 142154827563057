import clustered from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const timeContinentsList = [
        { id: "#RTPCTR-CC01e", name: "simple-bar", chartImage: clustered, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RTPCTR-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • UNIQUE" },

];

export default timeContinentsList;
