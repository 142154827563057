import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
import table      from "assets/images/chart-images/table.png";

const sectionCategory = [
    // Manuscript types - None
    {id: "SSC-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SSC-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
    {id: "SSC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SSC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
    {id: "SSC-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
    {id: "SSC-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default sectionCategory;
