import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";

const revTypeOutcomeOfInvitations = [
    { id: "#RIRTOIC-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
    { id: "#RIRTOIC-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false, },
    { id: "#RIRTOIC-T02r", name: "bar", chartImage: table, v2: "Row", disabled: false, },
    { id: "#RIRTOIC-T94r", name: "bar", chartImage: table, v2: "Row", disabled: false, },
];

export default revTypeOutcomeOfInvitations;
