import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const revisionNumber = [
    { id: "TDFR#-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
    { id: "TDFR#-BV03e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
    { id: "TDFR#-BV07e", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
    { id: "TDFR#-BV05e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TDFR#-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TDFR#-T03e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV " },
    { id: "TDFR#-T04e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV " },
    { id: "TDFR#-BXV05e", name: "Box Chart", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T06e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T05e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T07e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
    { id: "TDFR#-T08e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },

    { id: "TDFR#-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
    { id: "TDFR#-BV05s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
    { id: "TDFR#-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
    { id: "TDFR#-T03s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV " },
    { id: "TDFR#-T04s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV " },
    { id: "TDFR#-BXV05s", name: "Box Chart", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T06s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T05s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
    { id: "TDFR#-T07s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
    { id: "TDFR#-T08s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
];

export default revisionNumber;