//lines
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change-editor.png";
import table from "assets/images/chart-images/table.png";

const initialRecommendationDecisionCompleted = [
        // Editors- None
        { id: "TDILE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TDILE-BV02s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
        { id: "TDILE-BV03s", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "TDILE-BV04s", name: "simple-bar", chartImage: lineSimpleBarChart, disabled: false },
        { id: "TDILE-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE", },
        { id: "TDILE-BV02e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE", },
        { id: "TDILE-BV03e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE", },
        { id: "TDILE-BV04e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE", },
        { id: "TDILE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDILE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TDILE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
        { id: "TDILE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        //Editors-Initial Decisions
        { id: "TDILEDI-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "TDILEDI-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDILEDI-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDILEDI-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
];

export default initialRecommendationDecisionCompleted;
