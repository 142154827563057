//chart
import chartTree from "assets/images/chart-images/vertical-tree.png";
import table from "assets/images/chart-images/table.png";
import stacked from "assets/images/chart-images/stacked-bar.png"

const countriesList = [
        // Decision types - None
        {id: "DIDT-DTV011s", name: "vertical-decision-tree-initial", chartImage: chartTree, disabled: true},
        {id: "DIDT-DTV011e", name: "vertical-decision-tree-initial", chartImage: chartTree, disabled: true},
        {id: "DICDT-BVS01e", name: "100%-stacked-bar", chartImage: stacked, disabled: false},
        {id: "DICDT-T01e", name: "Table", chartImage: table, v2: "Column", disabled: false},
        {id: "DICDT-T02e", name: "Table", chartImage: table, v2: "Column", disabled: false},



];

export default countriesList;
