import { GET_ALL_FAVORITES, DELETE_FAVORITE, CHANGE_FAVORITE_TITLES, UPDATE_FAVORITE_TITLES } from "../../../actionTypes";

const defaultState = {
    data: [],
    hasNext: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_FAVORITE_TITLES: {
            const { id, titles } = action;
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === id){
                        return {
                            ...item,
                            reportTitles: titles
                        }
                    }
                    return {
                        ...item
                    }
                })
            }
        }
        case GET_ALL_FAVORITES : 
        const { page, hasNext } = action;
        return {
            data: page === 0 ? action.payload : [...state.data, ...action.payload],
            hasNext: hasNext
        };
        case DELETE_FAVORITE:
            return {
                data: state.data.filter(item => item.id !== action.payload),
            }
        case CHANGE_FAVORITE_TITLES: {
            const { data: favorites } = state;
            const { id,
                title,
                description,
                myChartName,
                note } = action.payload;
            const newFavorites = favorites.map(item => {
                if(item.id === id){
                    return {
                        ...item,
                        name: myChartName,
                        note:note,
                        data: {
                            ...item.data,
                            formatOptions: {
                                ...item.data.formatOptions,
                                chartTitles:{
                                    ...item.data.formatOptions.chartTitles,
                                    mainTitle: title,
                                    secondaryTitle: description
                                }
                            }
                        }
                    }
                }
                return {
                    ...item
                }
            });
            return {
                data: newFavorites,
            }
        }
        default:
            return state;
    }
};
