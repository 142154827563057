import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, logout, me } from "store/actions/authActions";
import "./index.scss"
import Spinner from "components/elements/spinner/Spinner";

class DemoLoadingPage extends React.Component {

    async componentDidMount() {
        const {
            match: {
                params: {
                    token,
                    loginAsInviteUserValue
                } = {}
            } = {}
        } = this.props;
        setTimeout(() => {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            axios.defaults.headers.common["Invite"] = true;
            axios.defaults.headers.common["loginAsInviteUserValue"] = loginAsInviteUserValue;
            localStorage.setItem('user_token', token);
            localStorage.setItem("invite", true);
            localStorage.setItem("loginAsInviteUserValue", loginAsInviteUserValue);
            this.props.history.push("/charts-tables");
        }, 5000);
    }

    render() {
        return (
            <div className="loading-demo-container">
                <Spinner size="small"/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.auth.userData,
        errorData: state.auth.userErrorData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ login, logout, me }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoLoadingPage);
