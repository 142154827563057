import * as actions from "store/actionTypes";

export default (state = { allReports: {} }, action) => {
    switch (action.type) {

        case actions.GET_ALL_REPORTS:
            return {
                ...state, allReports: action.payload.allReports
            }
        default:
            return state;
    }
}