import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
import table      from "assets/images/chart-images/table.png";
import chart4Items from "assets/images/chart-images/donut.png";
import chartChange          from "assets/images/chart-images/bubble.png";
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";
const timePeriodList = [
        // Time period - None
        {id: "%RFC-BV01s", name: "line", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RFC-BV01e", name: "line", chartImage: barSimpleBarChart, disabled: false},
        {id: "%RFC-T01s", name: "line", chartImage: table, v2: "Column", disabled: false},
        {id: "%RFC-T01e", name: "line", chartImage: table, v2: "Column", disabled: false},
        {id: "%RFC-BB01s", name: "line", chartImage: chartChange, disabled: false},
        {id: "%RFC-BB01e", name: "line", chartImage: chartChange, disabled: false},
        {id: "%RFC-M01s", name: "line", chartImage: mapWorldMap, disabled: false},
        {id: "%RFC-M02s", name: "line", chartImage: mapWorldMap, disabled: false},
        {id: "%RFC-M01e", name: "line", chartImage: mapWorldMap, disabled: false},
        {id: "%RFC-M02e", name: "line", chartImage: mapWorldMap, disabled: false},
        {id: "%RFC-D01s", name: "line", chartImage: chart4Items, disabled: false},
        {id: "%RFC-D02s", name: "line", chartImage: chart4Items, disabled: false},
        {id: "%RFC-D01e", name: "line", chartImage: chart4Items, disabled: false},
        {id: "%RFC-D02e", name: "line", chartImage: chart4Items, disabled: false},
];

export default timePeriodList;
