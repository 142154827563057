import React from 'react';
import './mailto.css';
import MailIcon from "assets/icons/mail-icon.png"
const MailTo = () => {
    return (
        <a href="mailto:support@originreports.org">
            <div className="custom-button-mailto">
                <img src={MailIcon} width={35} height={35} alt="mail" />
            </div>
        </a>
    );
};

export default MailTo;