//lines
import lineMultiLine from "assets/images/chart-images/multi-line.png";
import lineWedge from "assets/images/chart-images/wedge.png";

//bar
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import barHorizontal100StackedBar from "assets/images/chart-images/100-horizontal-stacked-bar.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import barHorizontalStackedBar from "assets/images/chart-images/horizontal-stacked-bar.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import barStacked100Bar from "assets/images/chart-images/100-stacked-bar.png";

//map
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";

//chart
import chart4Items from "assets/images/chart-images/donut.png";
import chartChange from "assets/images/chart-images/bubble.png";
import chartScatterPlot from "assets/images/chart-images/scatter-plot.png";
import table from "assets/images/chart-images/table.png";

const countriesList = [
    // Countries charts
    { id: "SC-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "STP-BV01p", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SC-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "SC-BH01s", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SC-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false },
    { id: "SC-M01s", name: "map", chartImage: mapWorldMap, disabled: false },
    { id: "SC-M02s", name: "map", chartImage: mapWorldMap, disabled: false },
    { id: "SC-M01e", name: "map", chartImage: mapWorldMap, disabled: false },
    { id: "SC-M02e", name: "map", chartImage: mapWorldMap, disabled: false },
    { id: "SC-D01s", name: "donut", chartImage: chart4Items, disabled: false },
    { id: "SC-D02s", name: "donut", chartImage: chart4Items, disabled: false },
    { id: "SC-D01e", name: "donut", chartImage: chart4Items, disabled: false },
    { id: "SC-D02e", name: "donut", chartImage: chart4Items, disabled: false },
    { id: "SC-BB01s", name: "bubble", chartImage: chartChange, disabled: false },
    { id: "SC-BB01e", name: "bubble", chartImage: chartChange, disabled: false },
    { id: "SC-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SC-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },

    // Countries - time period charts
    { id: 615, name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
    { id: "SCTP-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SCTP-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SCTP-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
    { id: "SCTP-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
    { id: "SCTP-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SCTP-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SCTP-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SCTP-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SCTP-L01s", name: "multi-line", chartImage: lineMultiLine, disabled: false },
    { id: "SCTP-L01e", name: "multi-line", chartImage: lineMultiLine, disabled: false },
    { id: "SCTP-W01s", name: "wedge", chartImage: lineWedge, disabled: false },
    { id: "SCTP-W01e", name: "wedge", chartImage: lineWedge, disabled: false },
    { id: "SCTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SCTP-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SCTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },

    // Countries - manuscript types charts
    { id: 624, name: "100%-horizontal-stacked-bar", chartImage: barHorizontal100StackedBar, disabled: true },
    { id: "SCM-BVS01s", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SCM-BVS01e", name: "stacked-bar", chartImage: barStachedBar, disabled: false },
    { id: "SCM-BVS02s", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
    { id: "SCM-BVS02e", name: "100%-stacked-bar", chartImage: barStacked100Bar, disabled: false },
    { id: "SCM-BHS01s", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SCM-BHS01e", name: "horizontal-stacked-bar", chartImage: barHorizontalStackedBar, disabled: false },
    { id: "SCM-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SCM-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
    { id: "SCM-SC01s", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false },
    { id: "SCM-SC01e", name: "scatter-plot", chartImage: chartScatterPlot, disabled: false },
    { id: "SCM-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "SCM-T02s", name: "table", chartImage: table, v2: "Row", disabled: false },
    { id: "SCM-T01e", name: "table", chartImage: table, v2: "Column", disabled: false },
    
    {id: "SC-BV01p", name: 'table', chartImage: barSimpleBarChart, disabled: false},
    {id: "SC-BH01p", name: 'table', chartImage: barHorizontalBarChart, disabled: false},
    {id: "SC-D01p", name: 'table', chartImage: chart4Items, disabled: false},
    {id: "SC-D02p", name: 'table', chartImage: chart4Items, disabled: false},
    {id: "SC-M01p", name: 'table', chartImage: mapWorldMap, disabled: false},
    {id: "SC-M02p", name: 'table', chartImage: mapWorldMap, disabled: false},
    {id: "SC-T01p", name: 'table', chartImage: table,  v2: "Column", disabled: false},
    {id: "SC-T01Bp", name: 'table', chartImage: table,  v2: "Column", disabled: false},
    {id: "SC-T02p", name: 'table', chartImage: table, v2: "Row", disabled: false},
    {id: "SC-T02Bp", name: 'table', chartImage: table, v2: "Row", disabled: false},
];

export default countriesList;