import { combineReducers } from 'redux';
import reportType from './reducers/reportTypeReducer';
import calculationLoadingStatus from './reducers/calculationLoadingStatusReducer';
import auth from './reducers/authReducer';
import dashboard from './reducers/super-admin/dashboardReducer';
import subscribers from './reducers/super-admin/subscribers';
import individual from './reducers/super-admin/subscribers/individual';
import adminCharts from './reducers/super-admin/chartsReducer';
import activity from './reducers/super-admin/activityReducer';
import organization from './reducers/super-admin/subscribers/organization';
import charts from './reducers/charts';
import chartReport from './reducers/chartReport';
import dataSelection from './reducers/charts/advanced-sidebar/data-selection';
import formatOptions from './reducers/charts/advanced-sidebar/format-options';
import favorites from './reducers/user/favorites';
import cookiePolicyPrompt from './reducers/cookiePolicyPromptReducer';
import files from './reducers/files';
import myPreferences from './reducers/my-preferences';
import automation from './reducers/automation';
import journalNames from './reducers/journalNames';
import teammates from "./reducers/teammates"
import accounts from './reducers/acounts'
export default combineReducers({
    reportType,
    calculationLoadingStatus,
    auth,
    dashboard,
    subscribers,
    individual,
    adminCharts,
    activity,
    organization,
    charts,
    chartReport,
    dataSelection,
    formatOptions,
    favorites,
    cookiePolicyPrompt,
    files,
    myPreferences,
    automation,
    journalNames,
    teammates,
    accounts

});