
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";


const revisionNumberList = [
        { id: "TACR#SN-CC01r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE"  },
        { id: "TACR#SN-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE * STD DEV"  },
        { id: "TACR#SN-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE * STD DEV"  },
        { id: "TACR#SN-CC05r", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "MEDIAN"  },
        { id: "TACR#SN-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN * IQR" },
        { id: "TACR#SN-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN * IQR" },
        { id: "TACR#SN-T20r", name: "table", chartImage: table, disabled: false },

];

export default revisionNumberList;
