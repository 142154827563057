import * as actions from "../../actionTypes";

const defaultState = {
    data: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case actions.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                data: action.payload
            };
        case actions.LOGOUT:
            return {
                ...defaultState
            };
        default:
            return state;
    }
};