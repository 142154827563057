import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import logo from "assets/images/final-logo.svg";
import Avatar from 'react-avatar';
import "./style.scss";

class NavBar extends Component {
  state = {
    openOption: false,
    superAdmin: this.props.changeCookiePolicyPromptAction === undefined,
    backArrow:
      this.props.location.pathname.split("/")[1] === "report_preview" ||
      this.props.location.pathname.split("/")[2],
  };
  dropdownEl = createRef();

  componentDidMount() {
    window.addEventListener("click", this.handleCloseDropdown);
    !this.state.superAdmin && this.props.changeCookiePolicyPromptAction(false);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleCloseDropdown);
  }

  toggleOption() {
    this.setState({
      openOption: !this.state.openOption,
    });
  }

  toRedirect(path) {
    this.toggleOption();
    this.props.history.push("/" + path);
  }

  logout() {
    const { handleLogOut } = this.props;

    this.toggleOption();
    localStorage.removeItem("fileName");
    localStorage.removeItem("arrow");
    sessionStorage.clear();
    localStorage.removeItem("fileCsv");
    localStorage.removeItem("fileCsv2");
    window.location.reload(true);
    localStorage.setItem("acceptedCookiePolicyPrompt",true)
    handleLogOut();
  }

  handleCloseDropdown = (e) => {
    const { current } = this.dropdownEl;
    if (current && !current.contains(e.target)) {
      this.setState({ openOption: false });
    }
  };

  render() {
    const { openOption, superAdmin } = this.state;
    const {
      title,
      subtitle,
      auth: {
        userData: { user, user: { id, avatar, accountType, avatar_full_path, inviteUser_email } = {} } = {},
      } = {},
      showCookiePopup,
      demoUser,
    } = this.props;
    let newTitle = '';
    if (title === 'MyCharts' || title === 'MyReports' || title === 'MyTeam all reports') {
      newTitle = title.substring(0, 2) + ' ' + title.substring(2)
    }
    return (
      <Fragment>
        <div className="super-admin-navbar">
          <div className="super-admin-navbar-title">
            {/* { (this.props.location.pathname.split("/")[1] === "report_preview" || this.props.location.pathname.split("/")[2] ==="edit_report") && <img
        { (this.props.location.pathname.split("/")[1] === "report_preview" || this.props.location.pathname.includes("edit_report")) && <img
              src={goBackArrow}
              height="15.2px"
              width="15.6px"
              alt="go back arrow"
              className="super-admin-navbar-arrow"
              // onClick={() => this.props.history.goBack()}
              // onClick={() =>this.props.location.pathname.split("/")[1] === "report_preview" ? this.props.history.push(`/reports/edit_report/${this.props.location.pathname.split("/")[3]}`) : (this.props.history.push(`/reports`))}
              onClick={() => {
                this.props.history.goBack();
              }}
            />} */}
            <div>
              {/* <p>{title}</p> */}
              <div className="super-admin-navbar-left-side">
                {<img src={logo} height="32px" width="80px" alt="logo" />}
                <div className="super-admin-navbar-titles-column">
                  <span className="super-admin-navbar-titles-column-title">
                    {newTitle || title}
                  </span>
                  <span className="super-admin-navbar-titles-column-subtitle">
                    {subtitle}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* {!reports && <img src={logo} height="32px" width="80px" alt="logo" />} */}
          { !demoUser && (
            <div className="super-admin-navbar-right-block">
              <p> {user && user.organizationName} </p>
              <div
                className="super-admin-navbar-avatar"
                ref={this.dropdownEl}
                onClick={() => this.toggleOption()}
              >
                {user &&!user.inviteUser && avatar ? (
                  <img src={((avatar.startsWith('https') && avatar) || avatar_full_path)} alt="avatar" />
                ) : (
                  user && user.inviteUser? 
                      <Avatar
                      className="mr-2"
                      name={inviteUser_email}
                      size="40"
                      round={true}
                    />
                    :
                  <div className="navbar-avatar-icon">
                    <Avatar
                      className="mr-2"
                      name={(user && user.name )|| "Super Admin"}
                      size="40"
                      round={true}
                    />
                  </div>
                )}
              </div>
              {openOption && (
                <>
                  <div className="rectangle" />
                  <div className="onRectangle" />
                  <div className="super-admin-navbar-options">
                    {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id && id !== 1 && (
                      <div onClick={() => this.toRedirect("my-account")}>
                        <span> My Account </span>
                      </div>
                    )}
                    {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id &&
                      id !== 1 &&
                      (!user.inviteUser_role ||
                        user.inviteUser_role === "admin") && (
                        <div onClick={() => this.toRedirect("myteam")}>
                          <span> My Team   <p  style={{
                                fontSize: "16px",
                                fontWeight: "400",
                          }}> {user.organizationName} </p></span>
                        </div>
                      )}
                    {!(localStorage.getItem("loginAsInviteUserValue") === "true") && id &&
                    id !== 1 &&
                    (
                      <div onClick={() => this.toRedirect("change-organization")}>
                        <span> Change Organization</span>
                      </div>
                    )}
                    {id && id !== 1 && accountType !== "unlimited" && (
                      <div onClick={() => this.toRedirect("subscription")}>
                        <span> Subscription & Billing </span>
                      </div>
                    )}
                    {/* <div onClick={() => this.toRedirect("my-organization")}>
                                <span> My Organization </span>
                                </div> */}
                    <hr className="line-top" />
                    {id && id !== 1 && (
                      <div onClick={() => this.toRedirect("my-preferences")}>
                        <span> My Preferences </span>
                      </div>
                    )}
                    <div
                      onClick={() => {
                        window.open("/terms-of-use", "_blank");
                      }}
                    >
                      <a href="#no-data"   rel="noopener noreferrer" >
                        <div>Terms and policies</div>
                      </a>
                    </div>
                    {!superAdmin && (
                      <div onClick={() => showCookiePopup()}>
                        <span> Cookie preferences</span>
                      </div>
                    )}
                    <hr className="line-bottom" />
                    <div onClick={() => this.logout()}>
                      <span>Logout</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="super-admin-navbar-empty-box"></div>
      </Fragment>
    );
  }
}

NavBar.defaultProps = {
  title: "",
};

NavBar.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
};

export default NavBar;
