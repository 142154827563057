import barStachedBar from "assets/images/chart-images/stacked-bar.png";
import table from "assets/images/chart-images/table.png";

const revTypeOutcomeAgreedDeclined = [
    { id: "#RIRTOIC-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
    { id: "#RIRTOIC-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false, },
    { id: "#RIRTOIC-T02r", name: "bar", chartImage: table, v2: "Row", disabled: false, },
    { id: "#RIRTOIC-T94r", name: "bar", chartImage: table, v2: "Row", disabled: false, },
    { id: "#RIRTOIA-BVS01r", name: "bar", chartImage: barStachedBar, disabled: false, },
    { id: "#RIRTOIA-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false, },
    { id: "#RIRTOIA-T02r", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
];

export default revTypeOutcomeAgreedDeclined;