import barSimpleBarChart               from "assets/images/chart-images/simple-chart.png";
import table      from "assets/images/chart-images/table.png";

const countryNoneList = [
        // Time period - None
        {id: "#UIE-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "#UIE-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "#UIE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#UIE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "#UIE-T02s", name: "table", chartImage: table, v2: "Row", disabled: false},
        {id: "#UIE-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default countryNoneList;
