
//lines
import lineLineChart          from "assets/images/chart-images/line-chart.png";

import table      from "assets/images/chart-images/table.png";

const timePeriodList = [
        // Time period - None
        {id: "%RFTP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%RFTP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%RFTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RFTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%RFTP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false},
];

export default timePeriodList;
