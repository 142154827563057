import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import mapWorldMap from "assets/images/chart-images/map/map_world_map.png";
import chart4Items from "assets/images/chart-images/donut.png";

const countryNoneList = [
        // Time period - None
        { id: "#RCR-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-M01e", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-M02e", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-D01e", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-D02e", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-BV03e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-M03e", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-T03e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-T04e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • TOTAL" },

        { id: "#RCR-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-D01s", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-D02s", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-M01s", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-M02s", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCR-BV03s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-M03s", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-M04s", name: "map", chartImage: mapWorldMap, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-T03s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCR-T04s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • TOTAL" },

        { id: "#RCTR-BV01s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-D01s", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-T01s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-T02s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-BV03s", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCTR-D03s", name: "donut", chartImage: chart4Items, disabled: false, description: "INVITED • UNIQUE" },
        { id: "#RCTR-T03s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "INVITED • TOTAL" },
        { id: "#RCTR-T04s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "INVITED • TOTAL" },

];

export default countryNoneList;
