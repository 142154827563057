import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./style.scss";
import Icon from "components/elements/icon";
import Spinner from "../spinner/Spinner";

const Button = ({
  disabled,
  name,
  type,
  icon,
  iconColor,
  iconRotated,
  style,
  spinnerStyles,
  handleButtonClick,
  loading,
}) => {
  const buttonStyle = {
    ...style,
  };
  !iconColor && (iconColor = type !== 1 ? "#FFFFFF" : null);

  return (
    <button
      className={cx(`button`, `button-${type}`, disabled && "disabled")}
      style={buttonStyle}
      onClick={!disabled || !loading ? handleButtonClick : () => {}}>
      {loading && (
        <Spinner size="smallest" color="white"/>
      )}
      {!loading && (
        <>
          {icon && (
            <div className="button-icon">
              <Icon name={icon} color={iconColor} rotated={iconRotated} />
            </div>
          )}
          {name}
        </>
      )}
      {name === "GENERATE CHART" && (
        <div className="btn-hover">
          Edit and generate chart <div />
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  type: 1,
  iconRotated: "0",
  loading: false,
  handleButtonClick: () => {},
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.number,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconRotated: PropTypes.string,
  handleButtonClick: PropTypes.func,
};

export default Button;
