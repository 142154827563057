import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import DonutChart from "assets/images/chart-images/donut.png";
const decisionTypeList = [
    { id: "DIsDT-BV01p", name: "stacked-bar", chartImage: barSimpleBarChart, disabled: false },
    { id: "DIsDT-D01p", name: "stacked-bar", chartImage: DonutChart, disabled: false },
    { id: "DIsDT-T01p", name: "table", chartImage: table, v2: "Column", disabled: false },
    { id: "DIsDT-T02p", name: "table", chartImage: table, v2: "Row", disabled: false },
];

export default decisionTypeList;

