//bar
import barClusteredBarChart from "assets/images/chart-images/clustered-bar-chart.png";
import table from "assets/images/chart-images/table.png";

const initialDecisionsEditors = [
        { id: "TDILDIE-CC01s", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false },
        { id: "TDILDIE-CC01e", name: "clustered-bar", chartImage: barClusteredBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDILDIE-T01s", name: "table", chartImage: table, v2: "Column", disabled: false },
        { id: "TDILDIE-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
];

export default initialDecisionsEditors;