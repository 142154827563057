//bar
import boxChartImage from "assets/images/chart-images/box-whisker.png";

//lines
import lineLineChart from "assets/images/chart-images/line-chart.png";
import table from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import lineSimpleBarChart from "assets/images/chart-images/simple-bar-change-editor.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";

const timePeriodList = [
        { id: "TCRTP-BV01s", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-BV02s", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-BV03s", name: "bar", chartImage: lineSimpleBarChart, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-BV04s", name: "bar", chartImage: lineSimpleBarChart, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-L01s", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-T01s", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T02s", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T03s", name: "table", chartImage: table, disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TCRTP-T04s", name: "table", chartImage: table, disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TCRTP-BV05s", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BV06s", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BV07s", name: "bar", chartImage: lineSimpleBarChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BV08s", name: "bar", chartImage: lineSimpleBarChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-L05s", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BXV05s", name: "bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TCRTP-T05s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN" },
        { id: "TCRTP-T06s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN" },
        { id: "TCRTP-T07s", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TCRTP-T08s", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },

        // Time period - None
        { id: "TCRTP-L01e", name: "line", chartImage: lineLineChart, disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T03e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T02e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T04e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE" },
        { id: "TCRTP-T07e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TCRTP-L05e", name: "line", chartImage: lineLineChart, disabled: false, description: "MEDIAN" },
        { id: "TCRTP-BXV01e", name: "bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN" },
        { id: "TCRTP-T05e", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TCRTP-T06e", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },

        { id: "TCRTP-BV01r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE"  },
        // { id: "TCRTP-BV02r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE"  },
        // { id: "TCRTP-BV03r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-BV04r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-L01r", name: "bar", chartImage: lineLineChart, disabled: false, description: "AVERAGE"  },
        // { id: "TCRTP-T01r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE"  },
        // { id: "TCRTP-T02r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE"  },
        { id: "TCRTP-T03r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE * STD DEV"  },
        { id: "TCRTP-T04r", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE * STD DEV"  },
        { id: "TCRTP-L05r", name: "bar", chartImage: lineLineChart, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BV05r", name: "bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN"  },
        // { id: "TCRTP-BV07r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BV08r", name: "bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN"  },
        { id: "TCRTP-BXV05r", name: "bar", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
        { id: "TCRTP-T07r", name: "bar", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR"  },
        { id: "TCRTP-T08r", name: "bar", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR"  },
        { id: "TCRTP-T20r", name: "bar", chartImage: table, disabled: false, description: ""  },
];

export default timePeriodList;
