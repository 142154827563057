//bar
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import table from "assets/images/chart-images/table.png";
import lineSimpleBarWithCounts from "assets/images/chart-images/simple-bar-with-counts.png";
import boxChartImage from 'assets/images/chart-images/box-whisker.png';

const editors = [
        { id: "TDSLAE-BV01r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        { id: "TDSLAE-BV04r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        { id: "TDSLAE-T03r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TDSLAE-T04r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "AVERAGE • STD DEV" },
        { id: "TDSLAE-BV05r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        { id: "TDSLAE-BV08r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
        { id: "TDSLAE-BXV05r", name: "box", chartImage: boxChartImage, disabled: false, description: "MEDIAN • IQR" },
        { id: "TDSLAE-T07r", name: "table", chartImage: table, v2: "Column", disabled: false, description: "MEDIAN • IQR" },
        { id: "TDSLAE-T08r", name: "table", chartImage: table, v2: "Row", disabled: false, description: "MEDIAN • IQR" },
        { id: "TDSLAE-T20r", name: "table", chartImage: table, disabled: false, description: "" },
        // { id: "TDSLAE-BV02r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "AVERAGE" },
        // { id: "TDSLAE-BV03r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "AVERAGE" },
        // { id: "TDSLAE-T01r", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        // { id: "TDSLAE-T02r", name: "table", chartImage: table, disabled: false, description: "AVERAGE" },
        // { id: "TDSLAE-T05r", name: "table", chartImage: table, disabled: false, description: "MEDIAN" },
        // { id: "TDSLAE-T06r", name: "table", chartImage: table, disabled: false, description: "MEDIAN" },
        // { id: "TDSLAE-BV06r", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false, description: "MEDIAN" },
        // { id: "TDSLAE-BV07r", name: "simple-bar", chartImage: lineSimpleBarWithCounts, disabled: false, description: "MEDIAN" },
];

export default editors;